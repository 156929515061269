import React, { useState } from 'react';

import Header from './Components/Header';
import PatientProfileSection from './Components/PatientProfileSection';
import PatientsList from './Components/PatientsList';
import styles from './EMR.module.css';

const EMR = () => {

    const [selectedPatient, setSelectedPatient] = useState(null);
    
    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <div className={` ml-[-38px] ${styles.container}`}>
                <div className='w-full'>
                    <Header styles={styles} />
                    <div className='w-full flex items-center justify-center mt-6 mb-20'>
                        {!selectedPatient && <PatientsList setSelectedPatient={setSelectedPatient} searchValue={searchValue} setSearchValue={setSearchValue} />}
                        {selectedPatient && <PatientProfileSection selectedPatient={selectedPatient} setSelectedPatient={setSelectedPatient} />}
                    </div>
                </div>
            </div>
        </>


    )
}

export default EMR