import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Form, Row, Col, Select, Tooltip } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function UpdateTreatmentTemplate({ showToast }) {
  const [updateDispenseOpen, setUpdateDispenseOpen] = useState(false);
  const [confirmUpdateDispenseLoading, setConfirmUpdateDispenseLoading] =
    useState(false);
  const [templates, setTemplates] = useState([]);
  const fetchDispensedItems = async () => {
    try {
      const response = await axios.get(
        "/treatment-templates",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );
      setTemplates(response.data.allDispensed);
      
    } catch (error) {
      console.error("Error fetching templates items:", error);
    }
  };

  const showUpdateTreatmentTemplateModal = () => {
    fetchDispensedItems();
    setUpdateDispenseOpen(true);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showUpdateTreatmentTemplateModal}
      >
        Update Template
      </button>
      <Modal
        title="Update Treatment Template"
        visible={updateDispenseOpen}
        // onOk={handleUpdateDispenseOk}
        confirmLoading={confirmUpdateDispenseLoading}
        // onCancel={handleUpdateDispenseCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="updateTreatmentTemplateForm">
          <Form.Item
            name="itemSelection"
            rules={[{ required: true, message: "Please select an item" }]}
          >
            <Select
              placeholder="Select an Item"
              // onChange={handleItemChange}
              style={{ width: "100%" }}
            >
              {templates.map((item) => (
                <Select.Option key={item.category} value={item.category}>
                  {item.category}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateTreatmentTemplate;
