import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Segmented, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Segment } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';


const PatientsList = ({ setSelectedPatient, searchValue, setSearchValue }) => {
  const [patients, setPatients] = useState([])
  const [status, setStatus] = useState('Active')
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'First Name',
      key: 'First Name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div style={{ fontSize: '12px', color: 'gray' }}>{record['Customer ID']}</div>
        </div>
      ),
    },
    {
      title: 'Last Name',
      dataIndex: 'Last Name',
      key: 'lastName',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'DOB',
      key: 'dob',
      render: (DOB) => (
        <h1> {moment(DOB + ''.split('T')).format('MM-DD-YYYY')}</h1>
      )

    },
    {
      title: 'Contact Info',

      key: 'contactInfo',
      render: (record) => {
        return (
          <div className='flex gap-3'>
            <h1>{record?.Email[0]?.email}</h1>
            {record?.phoneNumbers[0]?.number &&
              <>
                <h1>|</h1>
                <h1>{record?.phoneNumbers[0]?.number}</h1>
              </>
            }
          </div>
        )
      }
    },
  ];

  const handleRowClick = (record) => {
    setSelectedPatient(record);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const fetchPatientProfiles = async (page, pageSize, search, status) => {
    setLoading(true);
    try {
      const { data } = await axios.get('/patient-profiles-with-pagination', {
        params: {
          page,
          pageSize,
          search,
          status
        },
      });
      setPatients(data.users);
      setTotal(data.total); 
    } catch (err) {
      console.error('Error fetching users', err);
    } finally {
      setLoading(false);
    }
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPagination({ current: 1, pageSize: pagination.pageSize }); 
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    fetchPatientProfiles(pagination.current, pagination.pageSize, searchTerm, status);
  }, [pagination.current, pagination.pageSize, searchTerm, status]);
  
  return (
    <>
      <div className='flex flex-col gap-4 w-[95%]'>
        <div className='flex items-center gap-2'>

          <Input suffix={<SearchOutlined />} placeholder='Search Patient' className='w-[30%]' value={searchTerm} onChange={handleSearch} />
          <Checkbox checked={status === 'Inactive'} onChange={(event) => event.target.checked ? setStatus('Inactive') : setStatus('Active')}>Show Inactive</Checkbox>
 
        </div>

        <Table
          dataSource={patients}
          columns={columns}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: 'cursor-pointer',
          })}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total,
            onChange: (page, pageSize) => {
              handleTableChange({ current: page, pageSize });
            },
          }}
        />
      </div >
    </>)
};

export default PatientsList;
