import { Person } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { Button, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { BarLoader } from "react-spinners";
import UpdatePatient from "../Messages/UpdatePatient";
import ConversationSection from "../ConversationSection";

const DraggableFloatingButtonPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showChats, setShowChats] = useState(true);
  const [showEditPatientModal, setShowEditPatientModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [patients, setPatients] = useState([]);
  const [popupCloseCompletely, setPopupClosedCompletely] = useState(false);
  const [updatePatientModalVisible, setUpdatePatientModalVisible] =
    useState(false);

  const tooltipRef = useRef(null);
  const fabRef = useRef(null);
  const updatePatientRef = useRef(null);

  useEffect(() => {
    if (showPopup) {
      setPatients([]);
      setPopupClosedCompletely(false);
    } else {
      setShowChats(true);
      setShowEditPatientModal(false);
      setLoader(false);
    }
  }, [showPopup]);

  useEffect(() => {
    async function fetchPatients() {
      setLoader(true);
      const activePatients = await fetch(
        "/patient-profiles/active",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      ).then((res) => res.json());

      const inactivePatients = await fetch(
        "/patient-profiles/inActive-list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      ).then((res) => res.json());

      setLoader(false);
      setPatients([...activePatients, ...inactivePatients]);
      setShowPopup(false);
    }

    if (showEditPatientModal) {
      fetchPatients();
    }
  }, [showEditPatientModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        fabRef.current &&
        !fabRef.current.contains(event.target) &&
        !updatePatientModalVisible
      ) {
        setShowPopup(false);
        setPopupClosedCompletely(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [updatePatientModalVisible]);

  return (
    <Draggable>
      <div
        style={{
          position: "fixed",
          zIndex: 50,
          bottom: 10,
          right: 20,
        }}
      >
        <Tooltip
          title={
            <div ref={tooltipRef} className="flex items-start flex-col">
              <div className="flex  items-start justify-center">
                <Button
                  className={`border-r-0 rounded-r-none w-[120px]  hover:border-none hover:!bg-blue-200 hover:!text-white ${
                    showChats ? "bg-blue-400 text-white" : ""
                  }`}
                  onClick={() => {
                    setShowChats(true);
                    setShowEditPatientModal(false);
                  }}
                >
                  Chats
                </Button>
                <Button
                  className={`border-l-0 rounded-l-none w-[120px]  hover:border-none hover:!bg-blue-200 hover:!text-white ${
                    showEditPatientModal ? "bg-blue-400 text-white" : ""
                  }`}
                  onClick={() => {
                    setShowEditPatientModal(true);
                    setShowChats(false);
                  }}
                >
                  Edit Patients
                </Button>
              </div>
              {showChats && <ConversationSection showUnreadMessages={false} />}
              {!showChats && showEditPatientModal && patients.length === 0 && (
                <div className="flex items-center justify-center h-[300px] w-[800px] overflow-hidden rounded-lg border bg-background shadow-xl">
                  <div className="flex items-center justify-center">
                    <p>... Loading Patients</p>
                    {loader && <BarLoader color="#3698d6" />}
                  </div>
                </div>
              )}
            </div>
          }
          open={showPopup}
          overlayStyle={{
            minHeight: "320px",
            height: "fit-content",
            width: "fit-content",
            maxWidth: "100%",
          }}
          overlayInnerStyle={{
            backgroundColor: "white",
            color: "black",
            padding: 10,
          }}
        >
          <Fab
            ref={fabRef}
            type="primary"
            shape="circle"
            size="large"
            onClick={() => {
              setShowPopup(!showPopup);
              if (showPopup) {
                setPatients([]);
                setPopupClosedCompletely(true);
              }
            }}
          >
            <Person sx={{ fontSize: 30 }} />
          </Fab>
        </Tooltip>
        {patients.length > 0 && !popupCloseCompletely && (
          <UpdatePatient
            ref={updatePatientRef}
            patients={patients}
            from="floatButton"
            onModalOpen={() => setUpdatePatientModalVisible(true)}
            onModalClose={() => setUpdatePatientModalVisible(false)}
          />
        )}
      </div>
    </Draggable>
  );
};

export default DraggableFloatingButtonPopup;
