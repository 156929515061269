import { DeleteOutlined, LockOutlined } from "@ant-design/icons";
import {
  ChevronLeft,
  ChevronRight,
  ContentCopyRounded,
} from "@mui/icons-material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Box,
  Checkbox,
  Dialog,
  ListItem,
  ListItemText,
  Modal,
  Tooltip as MuiTooltip,
  TextField,
  Typography,
} from "@mui/material";
import {
  Modal as AntdModal,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Popover,
  Select,
  Table,
  TimePicker,
  Tooltip,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { createTransition } from "dnd-multi-backend";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { TouchBackend } from "react-dnd-touch-backend";
import { useNavigate } from "react-router-dom";
import "./labsModal.css";
import SupplementsModalPopup from "../../Components/SupplementsModal";
import TreatmentNotes from "../../Components/TreatmentNotes";
import "../../antTable.css";
import "../../customeCalender.css";

import IconButton from "@mui/material/IconButton";
import moment from "moment-timezone";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BounceLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import Round from "../../Assets/Round";
import search from "../../Assets/search.png";
import CustomToast from "../../Components/CustomToast";
import DispenseModal from "../../Components/DispenseModal";
import EventsModal from "../../Components/EventsModal";
import ProcedureModal from "../../Components/ProcedureModal";
import ManageTreatmentTemplates from "../../Components/Updations/ManageTreatmentTemplates";
import VitalsModal from "../../Components/VitalsModal";
import TreatmentTemplates from "../../Pages/TreatmentViewDisplay/TreatmentTemplates";
import { DataContext } from "../../context/DataContext";
import {
  dispensesSortingFunction,
  proceduresSortingFunction,
} from "../../utils";
import styles from "./styles.module.css"; // Make sure this path   is correct
const { Option } = Select;
const opts = {
  enableTouchEvents: true,
  enableMouseEvents: true,
};
// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750, // You can adjust the width as needed
  maxHeight: "80vh", // Adjust the max height as needed
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "& .MuiTypography-h6": {
    // Targeting Typography with variant h6
    fontSize: "1rem", // Maintained decreased font size
  },
  "& .MuiListItemText-primary": {
    fontSize: "0.9rem", // Setting font size for primary text
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflowed text
  },
  "& .MuiCheckbox-root": {
    // Targeting Checkbox
    padding: "2px", // Reduced padding for a smaller checkbox
    "& svg": {
      // Targeting the SVG icon in checkbox
      fontSize: "1rem", // Reduced icon size
    },
  },
  "& .MuiGrid-container": {
    rowGap: 1, // Adjust row spacing if needed
  },
  "& .MuiGrid-item": {
    display: "flex", // Ensure the items in grid are flex containers
    alignItems: "center", // Align items vertically in the center
  },
};

function SearchIcon() {
  return <img src={search} alt="search" />;
}

const MultipleTreatmentsView = ({
  allTreatments,
  setAllTreatments,
  handleRefresh,
  toastifyToast,
  treatmentId,
  selectedDateFromView,
  setRoomsData,
  selectedPatient,
  showOrdersOnly
}) => {
  const {
    data: {
      labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
    isTyping,
    setIsTyping,
  } = useContext(DataContext);
  const [template, setTemplate] = useState(null);
  const [undoOrderId, setUndoOrderId] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [duplicateGoogleEvent, setDuplicateGoogleEvent] = useState(false);
  const [undoToken, setUndoToken] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [ivPrepByTreatment, setIvPrepByTreatment] = useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => { }, [isTyping]);
  const [patientLoader, setPatientLoader] = useState(false);
  const [inactivePatients, setInactivePatients] = useState([]);
  const iconRef = useRef(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const [form] = Form.useForm();
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
  const [patientId, setPatientId] = useState();
  const [labsModalLoader, setLabsModalLoader] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [customNote, setCustomNote] = useState();
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [activePatient, setActivePatient] = useState([]);
  const [todayPatients, setTodayPatients] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPatientId, setModalPatientId] = useState(null);
  const [roomOptions, setRoomOptions] = useState([]);
  const [newLabsMap, setNewLabsMap] = useState({});
  const calendarRef = useRef(null);
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [CalendarEvents, setCalendarEvents] = useState([]);
  const [formattedDate, setFormattedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [labsModalOpen, setLabsModalOpen] = useState(false);
  const [procedureModalOpen, setProcedureModalOpen] = useState(false);
  const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedCopyAction, setSelectedCopyAction] = useState(null);
  const [showDatePickerForAction, setShowDatePickerForAction] = useState(false);
  const [
    showDatePickerForActionDuplicate,
    setShowDatePickerForActionDuplicate,
  ] = useState(false);
  const [eventNote, setEventNote] = useState("");
  const [currentPatientLabs, setCurrentPatientLabs] = useState({
    labs: [],
    orderId: null,
  });
  const [currentProcedures, setCurrentProcedures] = useState([]);
  const [currentSupplements, setCurrentSupplements] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);
  const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [currentTreatment, setCurrentTreatment] = useState();

  const isTreatmentNoteEditing = useRef(false);
  const isNoteEditing = useRef(false);

  const [currentPatientIdForModal, setCurrentPatientIdForModal] =
    useState(null);
  const [isRoomsListOpen, setIsRoomsListOpen] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [bulkAction, setBulkAction] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [bulkActionDate, setBulkActionDate] = useState(null);

  const [showDuplicateOptions, setShowDuplicateOptions] = useState(false);
  const [duplicateActionDate, setDuplicateActionDate] = useState(null);

  const [hoveredColumn, setHoveredColumn] = useState(null);

  const [searchFilter, setSearchFilter] = useState("");
  const [roomFilter, setRoomFilter] = useState("");
  const [assigneeFilter, setAssigneeFilter] = useState("");
  const interactionTimeoutRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [newContextMenu, setNewContextMenu] = useState(null);
  const [visibleCalendarId, setVisibleCalendarId] = useState(null);
  const [isModalVisibleNew, setIsModalVisibleNew] = useState(false);
  const [ignoredEvents, setIgnoredEvents] = useState([]);
  const [eventsNew, setEventsNew] = useState([]);
  const [status, setStatus] = useState();
  const [currentCalendar, setCurrentCalendar] = useState();
  const [pastedText, setPastedText] = useState("");
  const [toast, setToast] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({
    labs: false,
    dispensed: false,
    procedures: false,
    supplements: false,
  });
  const [copiedData, setCopiedDate] = useState(false);
  const [allSupplements, setAllSupplements] = useState([]);
  const [clickedTreatmentData, setClickedTreatmentData] = useState();

  const [inputWidth, setInputWidth] = useState("100px");
  const containerRef = useRef(null);

  useEffect(() => {
    console.log("Updated all treatments",allTreatments)

  },[allTreatments])

 useEffect(() => {
  const interval = setInterval(() => {
    handleRefresh();
  }, 15000);

  return () => clearInterval(interval);
}, [selectedPatient,showOrdersOnly]); 

  const handleFocus = () => {
    setInputWidth("100px");
    setIsTyping(true);
    isNoteEditing.current = true;
  };

  const handleBlur = (e, recordKey) => {
    setInputWidth("100px");
    setIsTyping(false);
    handleNoteUpdate(recordKey, e.target.value);
  };

  const onCheckboxChange = (changes) => {
    setCheckboxValues({ ...checkboxValues, ...changes });
  };

  useEffect(() => { }, [checkboxValues]);

  const onCopyClick = (oldTreatment) => {
    setCopiedDate(true)
    let resultString = `Treatment: ${oldTreatment}\n`;
    // Check and append labs if selected
    if (checkboxValues.labs) {
      resultString += `Labs: ${contextMenu?.data.labs.join(", ")}\n`;
    }

    // Check and append procedures if selected
    if (checkboxValues.procedures) {
      resultString += `Procedures: ${contextMenu?.data.procedures.join(
        ", "
      )}\n`;
    }

    // Check and append dispensed if selected
    if (checkboxValues.dispensed) {
      const dispensedItems = contextMenu?.data.dispensed.filter(
        (item) => !item.includes("SUP")
      );
      resultString += `Dispensed: ${dispensedItems.join(", ")}\n`;
    }

    // Check and append supplements if selected
    if (checkboxValues.supplements) {
      const supplements = contextMenu?.data.dispensed
        .filter((item) => item.includes("SUP"))
        .map((item) => item.replace("SUP", "").trim());
      resultString += `Supplements: ${supplements.join(", ")}\n`;
    }

    // Output or handle the constructed string

    handleCopy(resultString);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        handleClose();
      })
      .catch((err) => { });
  };

  const handlePaste = (action) => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setPastedText(text);

        handlePasteUpdate(contextMenu?.data.key, text, action);
      })
      .catch((err) => { });
  };

  useEffect(() => { }, [isModalVisibleNew]);
  const fetchEventsNew = async (calendar, status, eventDate) => {
    setCurrentCalendar(calendar._id);
    try {
      const response = await axios.get(
        `/calendar-events-all/${calendar._id}/${eventDate ?? formattedDate
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const ignoredEvents = await axios.get(
        `/ignored-calendar-events`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setIgnoredEvents(ignoredEvents.data);

      setEventsNew(response.data.events || []);
      fetchAllEvents();
      setIsModalVisibleNew(true);
      setStatus(status);
    } catch (error) {
      console.error("Failed to fetch events:", error);
      // Handle the error appropriately
    }
  };

  const fetchAllEvents = async () => {
    try {
      const response = await axios.get(
        `/all-calendar-events/${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setAllEvents(response.data.events);
    } catch (error) {
      console.error("Failed to fetch events:", error);
      // Handle the error appropriately
    }
  };

  const fetchIgnoredEvents = async () => {
    try {
      const ignoredEvents = await axios.get(
        `/ignored-calendar-events`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setIgnoredEvents(ignoredEvents.data);
    } catch (error) {
      console.error("Failed to fetch ignored events:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const toggleCalendarVisibility = (treatmentId) => {
    setVisibleCalendarId((prevId) =>
      prevId === treatmentId ? null : treatmentId
    );
  };

  const handleOpenChange = (open, treatment) => {
    if (!open || (open && visibleCalendarId !== treatment._id)) {
      toggleCalendarVisibility(treatment._id);
    }
  };

  const getCalendarCidByType = (type) => {
    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar.cid : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar.cid : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar.cid : null;
      case "complete":
        return completedCalendar ? completedCalendar.cid : null;
      default:
        return null;
    }
  };

  const getCalendarIdByType = (type) => {
    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar._id : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar._id : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar._id : null;
      case "complete":
        return completedCalendar ? completedCalendar._id : null;
      default:
        return null;
    }
  };

  async function changeEventTime(
    eventId,
    status,
    time,
    note,
    timezone,
    patientName,
    selectedDate
  ) {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const cid = getCalendarCidByType(status);

    try {
      const response = await axios.post(
        `/change-event-time/${eventId}`,
        { newTime: time, patientName, note, timezone, cid, selectedDate: formattedDate }
      );
      toastifyToast.success("Calendar event time changed");
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  function extractDetails(resultString) {
    const details = {
      oldTreatment: null,
      hasProcedures: false,
      hasLabs: false,
      hasDispensed: false,
      hasSupplements: false,
    };

    // Extract Treatment
    const oldTreatmentMatch = resultString.match(/Treatment:\s*(.*)/);
    if (oldTreatmentMatch && oldTreatmentMatch[1]) {
      details.oldTreatment = oldTreatmentMatch[1];
    }

    // Check for Procedures
    details.hasProcedures = /Procedures:\s*(.*)/.test(resultString);

    // Check for Labs
    details.hasLabs = /Labs:\s*(.*)/.test(resultString);

    // Check for Dispensed
    details.hasDispensed = /Dispensed:\s*(.*)/.test(resultString);

    // Check for Supplements
    details.hasSupplements = /Supplements:\s*(.*)/.test(resultString);

    return details;
  }

  async function handlePasteUpdate(newTreatmentId, text, action) {
    //
    const normalizedText = text.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n");

    const details = extractDetails(text);

    try {
      const response = await axios.put(
        `/paste-in-treatment`,
        {
          newTreatmentId,
          action,
          oldTreatmentId: details.oldTreatment,
          dataToUpdate: details,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      toastifyToast.success("Paste Successful");
      const oldTreatment = allTreatments.filter(item => item._id === details.oldTreatment)
      const updatedTreatments = allTreatments.map((treatment) => {
        if (treatment._id === newTreatmentId) {
          if (details.hasLabs) {
            return {
              ...treatment, userLabs: oldTreatment[0].userLabs,
            }
          }
          if (details.hasProcedures) {
            return {
              ...treatment, userProcedures: oldTreatment[0].userProcedures
            }
          }
          if (details.hasSupplements) {
            return {
              ...treatment, userSupplements: oldTreatment[0].userSupplements,

            }
          }
          if (details.hasDispensed) {
            return {
              ...treatment, userdispensed: oldTreatment[0].userdispensed
            }
          }
        }
        else {
          return treatment
        }
      })
      setAllTreatments(updatedTreatments)
      const undoToken = response.data.undoToken;
      const undoOrderId = response.data.orderId;
      handleRefresh();
      setUndoOrderId(undoOrderId);
      setUndoToken(undoToken);
      handleClose();
      handleDataReset(selectedDate);
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  async function handleUndo(undoOrderId, undoToken) {
    try {
      await axios.put(
        `/undo-paste/${undoOrderId}`,
        { undoToken },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      toastifyToast.success("Undo Successful");
      handleClose();
      setUndoOrderId(null);
      setUndoToken(null);
      handleDataReset(selectedDate);
    } catch (error) {
      toastifyToast.error(error.message || "Error undoing changes");
      console.error(error);
    }
  }

  async function changeEventDate(eventId, status, date) {
    const cid = getCalendarCidByType(status);

    try {
      const response = await axios.post(
        `/change-event-date/${eventId}`,
        { newDate: date, cid }
      );
      // toast.success("Calendar event date changed");

      return response.data;
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  async function createEventWithTime(
    time,
    status,
    treatmentId,
    timezone,
    note,
    patientName,
    selectedDate
  ) {
    try {
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      const cid = getCalendarCidByType(status);

      const response = await axios.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate: formattedDate }
      );
      const idc = getCalendarIdByType(status);
      createWatch(response.data.id, treatmentId, idc, status);
      toastifyToast.success("New event created for treatment");

      handleCellChange(treatmentId, "event", response.data.id, "patientInfo");
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  const handleTimeChange = (
    time,
    treatmentId,
    eventId,
    status,
    patientName,
    timezone,
    note
  ) => {
    const timeString = time ? time.format("hh:mm a") : "";
    if (eventId && eventId !== "unassigned") {
      changeEventTime(eventId, status, timeString, note, timezone, patientName, selectedDate);
    }
    if (eventId === "unassigned") {
      createEventWithTime(
        timeString,
        status,
        treatmentId,
        timezone,
        note,
        patientName,
        selectedDate
      );
    }

    handleCellChange(treatmentId, "time", timeString, "patientInfo");

    toggleCalendarVisibility(treatmentId);
  };

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  // Function to disable minutes for every quarter past the hour
  const disabledMinutes = (selectedHour) => {
    if (selectedHour === 8) {
      // Disable minutes before 15
      return [0, 15, 30, 45];
    } else if (selectedHour === 18) {
      // Disable minutes after 45
      return [0, 15, 30, 45];
    } else {
      // Disable all minutes
      return [0, 15, 30, 45];
    }
  };

  const handleIconClick = () => {
    if (iconRef.current) {
      const iconBounds = iconRef.current.getBoundingClientRect();

      setCalendarPosition({
        top: iconBounds.bottom + window.scrollY - 20,
        left: iconBounds.left + window.scrollX - 50,
      });
    }
    setShowCalendar(!showCalendar);
  };

  const handleContextMenu = (event, record) => {
    event.preventDefault();

    const contextMenuWidth = 300;
    const contextMenuHeight = 150;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    let mouseX = event.clientX;
    let mouseY = event.clientY;
    let totalWidth = mouseX + contextMenuWidth;
    if (mouseX + contextMenuWidth > viewportWidth) {
      mouseX = viewportWidth - contextMenuWidth;
    }
    if (mouseY + contextMenuHeight > viewportHeight) {
      mouseY = viewportHeight - contextMenuHeight;
    }
    setContextMenu({
      mouseX,
      mouseY,
      totalWidth,
      viewportWidth,
      data: record,
    });
  };
  const handleClose = () => {
    setShowDatePickerForAction(false);
    setShowDatePickerForActionDuplicate(false);
    setShowSelectTodayPatient(false);
    setShowSelectNewPatient(false);
    setShowSelectPatient(false);
    setNewContextMenu(null);
    setContextMenu(null);
    form.resetFields();
    setDuplicateGoogleEvent(false);
    setCheckboxValues({
      labs: false,
      dispensed: false,
      procedures: false,
      supplements: false,
    });
  };

  const handleOutsideClick = (event) => {
    if (!selectOpen) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectOpen]);

  const resetInteractionTimeout = () => {
    if (interactionTimeoutRef.current) {
      clearTimeout(interactionTimeoutRef.current);
    }
    const resetTimer = isLocked ? 7200000 : 180000;
    interactionTimeoutRef.current = setTimeout(() => {
      setSelectedStatuses([]);
    }, resetTimer);
  };

  useEffect(() => {
    // Function to reset timeout on interaction
    const handleUserInteraction = () => {
      resetInteractionTimeout();
    };

    // Add event listeners for various user interactions
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keypress", handleUserInteraction);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keypress", handleUserInteraction);
      if (interactionTimeoutRef.current) {
        clearTimeout(interactionTimeoutRef.current); // Ensure to clear the timeout on component unmount
      }
    };
  }, [isLocked]);

  const filterTreatmentsByStatus = (treatments, status) => {
    return treatments.filter((treatment) => treatment.status === status);
  };

  const toggleSearchBar = () => {
    setSearchBarVisible(!searchBarVisible);
  };

  const navigate = useNavigate();
  // fucntion to determine styles of parent container of cards
  const getParentContainerStyle = (status) => {
    const count = treatments.filter((t) => t.status === status).length;
    let maxWidth = null;
    let minWidth = null;
    // let maxWidth = null;
    if (count === 0) {
      minWidth = "12%";
    } else if (count <= 7 && count > 0) {
      // width = "250px";
      // width = "auto";
      minWidth = "20%";
      maxWidth = "90%";
    } else if (count > 7) {
      // width = "620px";
      // width = "auto";
      minWidth = "20%";
      maxWidth = "70%";
    }
    return {
      width: "auto",
      minWidth: minWidth,
      maxWidth: maxWidth,
    };
  };

  // Function to determine the style of the containers
  const getContainerStyle = (status) => {
    const count = treatments.filter((t) => t.status === status).length;
    let width = "0px";
    // let maxWidth = null;

    if (count === 0) {
      width = "auto";
      // maxWidth = "18%";
      // width = "200px";
    } else if (count <= 7 && count > 0) {
      // width = "250px";
      width = "auto";
    } else if (count > 7) {
      // width = "620px";
      width = "auto";
    }
    return {
      display: "flex",
      flexDirection: "column", // stack items vertically
      alignItems: "flex-start", // align items to the start of the container
      justifyContent: "flex-start", // start items from the top
      flexWrap: "wrap",
      gap: "10px",
      height: "100%",
      overflowY: "auto",
      width: width,
      // maxWidth: maxWidth,
      fontSize: "11px",
    };
  };

  // Function to determine the style of the containers
  // const getContainerStyle = (status) => {
  //   const count = treatments.filter((t) => t.status === status).length;
  //   let width = "0px";
  //   if (count === 0) {
  //     width = "200px";
  //   } else if (count <= 7 && count > 0) {
  //     width = "300px";
  //   } else if (count > 7) {
  //     width = "375px";
  //   }
  //   return {
  //     display: "flex",
  //     flexDirection: "column", // stack items vertically
  //     alignItems: "flex-start", // align items to the start of the container
  //     justifyContent: "flex-start", // start items from the top
  //     flexWrap: "wrap",
  //     gap: "10px",
  //     height: "100%",
  //     overflowY: "auto",
  //     width: width,
  //     fontSize: "11px",
  //   };
  // };

  const [assigneeOptions, setassigneeOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setassigneeOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  }, []);
  const fetchEvents = async () => {
    const date = new Date().toISOString().split("T")[0]; // Get today's date
    const response = await axios.get(
      `/calendar-events/${date}`
    );
    setEvents(response.data);
  };

  useEffect(() => {
    setFormattedDate(moment().format("YYYY-MM-DD"));
    GetActivePatientProfiles();
    fetchallLabs();
    fetchIgnoredEvents();

    //fetchEvents();
  }, []);
  const getCheckedLabsSummary = () => {
    const checkedLabs = currentPatientLabs.labs
      .filter((lab) => lab.checked)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedLabs;
  };

  // Assuming treatments is your array of treatment objects

  const fetchCalendarEvents = async () => {
    const treatmentCalendarIds = treatments
      .filter((treatment) => treatment._id != null)
      .map((treatment) => treatment._id);

    if (treatmentCalendarIds.length > 0) {
      try {
        const response = await axios.post(
          "/calendar-events-treatments",
          { treatmentCalendarIds }
        );

        setCalendarEvents(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Call the function

  useEffect(() => {
    fetchRoomOptions();
    fetchTreatments(formattedDate);

    //fetchEvents();
  }, [formattedDate]);
  useEffect(() => {
    // Assuming formattedDate is defined and available in this scope

    // Setting up the interval
    const interval = setInterval(() => {
      if (!isTyping) {
        fetchTreatments(formattedDate);
        fetchCalendars(formattedDate);
        fetchIgnoredEvents();
      }
    }, 15000);

    // Clearing interval on component unmount or when formattedDate changes
    return () => clearInterval(interval);
  }, [formattedDate, isTyping]); // Include formattedDate in the dependency array

  const RoomsTooltipContent = ({ key, record }) => {
    return (
      <div
        key={key}
        onClick={(e) => {
          setIsTyping(true);
          e.stopPropagation();
        }}
      >
        <Select
          defaultValue={record.room} // Set the default value to the current room value
          style={{ height: 25 }} // Adjust width as needed
          onChange={(value) => {
            handleCellChange(record.key, "room", value, "patientInfo");
            const updatedRecord = { ...record, room: value };
            setIsTyping(true);
            setContextMenu((currentContextMenu) => ({
              ...currentContextMenu,
              data: updatedRecord,
            }));
          }}
        >
          <Option key="empty-option" value="">
            Empty
          </Option>
          {roomOptions.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
      },
      {
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: createTransition("touchstart", (event) => {
          return !!event.touches;
        }),
      },
    ],
  };

  const handleDataReset = async (date) => {
    const scrollTop = containerRef.current.scrollTop;
    if (date) {
      // Check if 'date' is a moment object and format it
      const formatted = moment.isMoment(date)
        ? date.format("YYYY-MM-DD")
        : moment(date).format("YYYY-MM-DD");

      // Directly call the fetch functions
      await fetchTreatments(formatted);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    }
  };

  const formatDateToUTC = (date) => {
    return date.toISOString().split("T")[0];
  };

  const showConfirmationModal = (patientId, orderId) => {
    setModalPatientId(patientId);
    setIsModalVisible(true);
  };

  // This function will be called when the "Yes" button on the modal is clicked
  const handleCreateTreatment = async () => {
    if (!modalPatientId) {
      toastifyToast.error("Patient ID not found");
      return;
    }

    try {
      const response = await axios.post(
        `/patient/order/new/${modalPatientId}/Treatment`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Treatment created successfully");

        handleDataReset(selectedDate);
        navigate(`/treatmentviewdisplay/${modalPatientId}`);
      } else {
        toastifyToast.error("Failed to create treatment");
      }
    } catch (error) {
      toastifyToast.error(
        error.response?.data?.message ||
        "An error occurred while creating the treatment"
      );
      console.error("Error creating treatment:", error);
    }

    setIsModalVisible(false); // Close the modal after handling the action
  };
  // New handler for the room selection
  const handleRoomSelect = (value) => {
    setSelectedRoom(value);
  };

  const handleCustomNoteSelect = (value) => {
    setCustomNote(value);
  };

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  const createSchedule = async () => {
    if (!selectedPatientId || !formattedDate) {
      toastifyToast.error("Please select all fields");
      return;
    }

    try {
      const response = await axios.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatientId,
          Scheduled_Date: formattedDate,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Schedule created successfully");

        handleDataReset(selectedDate);
        setSelectedPatientId(undefined);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message ||
        "An error occurred while creating the schedule"
      );
      console.error("Error creating schedule:", error);
    }
  };

  const fetchRoomOptions = async () => {
    //Get available rooms
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setRoomOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  };
  const handlePatientSelect = (selectedValue) => {
    if (!selectedValue) {
      toastifyToast.error("Please select a patient");
    } else {
      setSelectedPatientId(selectedValue);
    }
  };

  const handlePatientSelectNew = (selectedValue, treatmentId) => {
    if (!selectedValue) {
      toastifyToast.error("Please select a patient");
    } else {
      const selectedPatient = activePatient.find(
        (patient) => patient["Customer ID"] === selectedValue
      );

      if (selectedPatient) {
        handleCellChange(
          treatmentId,
          "PatientId",
          selectedPatient["Customer ID"],
          "patientInfo"
        );
        handleCellChange(
          treatmentId,
          "patientName",
          selectedPatient["Full Name"],
          "patientInfo"
        );
        setShowSelectPatient(false);
      }
    }
  };

  const copyTreatmentToPatient = async (patientId, treatment) => {
    setShowSelectPatient(false);
    try {
      const response = await axios.post(
        "/patient/copy",
        {
          patientId,
          treatmentId: treatment?.key,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");

        handleClose();
        handleDataReset(selectedDate);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  const copyTreatmentToPatientNew = async (
    patientId,
    treatmentId,
    treatment
  ) => {
    setShowSelectPatient(false);
    try {
      const response = await axios.post(
        "/todaypatientcopy",
        {
          patientId,
          treatmentId: treatment?.key,
          treatmentIdToModify: treatmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");

        handleClose();
        handleDataReset(selectedDate);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  const getInactivePatientProfiles = async () => {
    try {
      const response = await axios.get(
        `/patient-profiles/inActive-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setInactivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
    }
  };
  const GetActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setActivePatient(response.data);

        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      }
      if (error.response && error.response.status === 409) {
      }
      console.error("Signup Error:", error);
    }
  };

  const [calendar, setCalendar] = useState([]);
  const [scheduledCalendar, setScheduledCalendar] = useState();
  const [arrivedCalendar, setArrivedCalendar] = useState();
  const [cancelledCalendar, setCancelledCalendar] = useState();
  const [noShowCalendar, setNoShowCalendar] = useState();
  const [showSelectPatient, setShowSelectPatient] = useState(false);
  const [showSelectNewPatient, setShowSelectNewPatient] = useState(false);
  const [showSelectTodayPatient, setShowSelectTodayPatient] = useState(false);
  const [pickupCalendar, setPickupCalendar] = useState();
  const [completedCalendar, setCompletedCalendar] = useState();

  const handleVisibleChange = (visible) => {
    if (visible) {
      setHideContextMenu(true)
    } else {
      setHideContextMenu(false)
    }
  };

  const fetchCalendars = async (date) => {
    try {
      const response = await axios.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const requestPromises = response.data.map((item) =>
          axios.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const calendarsWithCounts = response.data.map((item, index) => ({
          ...item,
          events: results[index]?.data?.events,
          eventsCount: results[index]?.data?.events?.length
            ? results[index]?.data?.events?.length
            : 0, // Add the events count to each calendar
        }));

        // Set state for each calendar based on its name
        calendarsWithCounts.forEach((calendar) => {
          if (calendar.name === "Scheduled") {
            setScheduledCalendar(calendar);
          } else if (calendar.name === "Complete") {
            setCompletedCalendar(calendar);
          } else if (calendar.name === "Pickup") {
            setPickupCalendar(calendar);
          } else if (calendar.name === "Arrived") {
            setArrivedCalendar(calendar);
          } else if (calendar.name === "noshow") {
            setNoShowCalendar(calendar);
          } else if (calendar.name === "cancelled") {
            setCancelledCalendar(calendar);
          }
        });
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };

  useEffect(() => {
    fetchCalendars(formattedDate);
  }, [formattedDate]);

  const fetchTreatments = async (dateString) => {
    // if (isNoteEditing.current === false) {
    try {
      setLoader(true);
      const formattedDateString = moment
        .tz(dateString, "America/Phoenix")
        .format("YYYY-MM-DD");

      const response = await axios.get(
        `/ordered/${formattedDateString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const treatmentsWithIvPrep = response.data?.map((treatment) => ({
        ...treatment,
        ivPrepComplete: treatment.iv_prep === "complete", // Add iv_prep status
      }));
      setTreatments(treatmentsWithIvPrep);
      fetchCalendarEvents();
      setLoader(false);
    } catch (error) {
      setTreatments([]);
      setLoader(false);
      console.error("Error fetching treatments:", error);
    }
    // }
  };

  // Function to handle date changes
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      const specificDate = dayjs(date);

      const startOfDay = specificDate.startOf("day");
      // setFormattedDate(date.toISOString().split('T')[0]);
      setFormattedDate(startOfDay.format("YYYY-MM-DD"));
      setTreatments([]);
      setLoader(true);
      fetchCalendars(startOfDay.format("YYYY-MM-DD"));
      fetchTreatments(startOfDay.format("YYYY-MM-DD"));
    }
  };

  const setDateToToday = () => {
    const startOfDay = dayjs().startOf("day");

    // Update the state variables with the start of today
    setSelectedDate(startOfDay.toDate());
    setFormattedDate(startOfDay.format("YYYY-MM-DD"));

    fetchTreatments(startOfDay.format("YYYY-MM-DD"));
  };

  // Function to navigate dates
  const navigateDate = (days) => {
    const newDate = moment(selectedDate).add(days, "days");
    setSelectedDate(newDate.toDate());
    setFormattedDate(newDate.format("YYYY-MM-DD"));

    // Clear the data
    setTreatments([]);
    setLoader(true);

    // Fetch new data

    fetchTreatments(newDate.format("YYYY-MM-DD"));
  };

  const formatDateToYYYYMMDD = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const handleNoteUpdate = async (key, newNote) => {
    // Construct the payload
    const payload = {
      note: newNote,
    };

    // API call to update the note
    try {
      const response = await axios.put(
        `/patient/order/update-note/${key}?fromPrepView=true`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      fetchTreatments(formattedDate);
      isNoteEditing.current = false;
      toastifyToast.success("Note Updated Successfully")
    } catch (error) {
      console.error("Error updating note:", error);
      toastifyToast.error("Error updating note ")
    }
  };

  const handleCustomNameUpdate = async (orderId, customName) => {
    //  setAllTreatments((prevRecords) =>
    //     prevRecords.map((record) =>
    //     record._id === orderId ? { ...record, custom: customName } : record
    //   ));
    const payload = {
      type: "common",
      valueType:'customName',
      value:customName
    };

    try {
      const response = await axios.put(
        `/api/patient-orders/${orderId}?fromPrepView=true`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      fetchTreatments(formattedDate);
      setAllTreatments((prevRecords) =>
        prevRecords.map((record) =>
        record._id === orderId ? { ...record, custom: response.data.order.custom } : record
      ));
      toastifyToast.success("Custom Name Updated Successfully");
    } catch (error) {
      console.error("Error updating note:", error);
      toastifyToast.error("Error updating custom name");
    }
  };

  const onSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRowKeys = treatments.map((t) => t._id); // Ensure 'treatments' is the correctly formatted data

      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const statusColors = {
    scheduled: "#89CFF0",
    arrived: "#9FE2BF",
    complete: "#00A36C",
    pickup: "#F6C4F8",
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipVisibleChange = (visible) => {
    setTooltipVisible(visible);
    if (!visible) {
      setContextMenu(null)
    }
  };
  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       indeterminate={
    //         selectedRowKeys.length > 0 &&
    //         selectedRowKeys.length < treatments.length
    //       }
    //       onChange={onSelectAllChange}
    //       checked={
    //         selectedRowKeys.length === treatments.length &&
    //         treatments.length > 0
    //       }
    //     />
    //   ),
    //   dataIndex: "key",
    //   key: "select",
    //   render: (text, record) => (
    //     <Checkbox
    //       style={{ color: statusColors[record.status] }}
    //       checked={selectedRowKeys.includes(record.key)}
    //       onChange={(e) => {
    //         const checked = e.target.checked;
    //         setSelectedRowKeys((prev) =>
    //           checked
    //             ? [...prev, record.key]
    //             : prev.filter((key) => key !== record.key)
    //         );
    //       }}
    //     />
    //   ),
    //   onCell: (record) => ({
    //     style: {
    //       backgroundColor: selectedRowKeys.includes(record.key)
    //         ? "#f7f7f7"
    //         : "inherit",
    //     },
    //   }),
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Custom Name",
      dataIndex: "custom",
      render: (custom, record) => (
        <Input
          key={record.key}
          value={record.custom}
          style={{ minWidth: "100px", width: inputWidth, background:'white',color:"black"}}
          onBlur={(e) => handleCustomNameUpdate(record.key,e.target.value)}
          onChange={(e) => {
            setAllTreatments((prevRecords) =>
            prevRecords.map((treatment) =>
            treatment._id === record.key ? { ...treatment, custom: e.target.value } : treatment
          ));
          }}
       />
      ),
    },
    {
      title: "LPDV",
      key: "lpdv",
      render: (record) => {
        return (
          <div className="buttonsarray">
            <IconButton
              size="small"
              onClick={() => handleOpenLabsModal(record.key, record)}
              aria-label="labs"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: record.labs.length > 0 ? "#b9f6ca" : "#e3f2fd",
                margin: "2px",
                color: "black",
              }}
            >
              L
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleOpenProcedureModal(record.key, record)}
              aria-label="procedures"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  record.procedures.length > 0 ? "#b9f6ca" : "#90caf9",
                margin: "2px",
                color: "black", // Text color
              }}
            >
              P
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleOpenDispenseModal(record.key, record)}
              aria-label="dispense"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  record.dispensed.filter((item) => !item.endsWith("SUP "))
                    .length > 0
                    ? "#b9f6ca"
                    : "#42a5f5",
                margin: "2px",
                color: "black", // Text color
              }}
            >
              D
            </IconButton>

            <IconButton
              size="small"
              onClick={() => handleOpenTreatmentNoteModal(record)}
              aria-label="TreatmentNote"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: record.treatmentNotes.includes("Notes by:")
                  ? "#81e984"
                  : "#0e75e5",
                margin: "2px",
                color: "black", // Text color
              }}
            >
              N
            </IconButton>

            {record.status == "pickup" ? (
              <IconButton
                size="small"
                aria-label="pickup"
                onClick={() => handleOpenSupplementsModal(record.key, record)}
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    record?.dispensed?.length > 0 &&
                      record?.dispensed?.some((item) => item.endsWith("SUP "))
                      ? "#00cc00"
                      : "#f0ad4e",
                  margin: "2px",
                  color: "black", // Text color
                }}
              >
                S
              </IconButton>
            ) : (
              <IconButton
                size="small"
                aria-label="treatmentNote"
                onClick={() => handleOpenVitalsModal(record.key, record)}
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    record?.vitals?.length > 0 ? "#81e984" : "#1e88e5",
                  margin: "2px",
                  color: "black", // Text color
                }}
              >
                V
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      title: "Final",
      dataIndex: "review",
      key: "review",
      render: (review, record) => (
        <Checkbox
          checked={record.review}
          onChange={(e) => {
            handleReviewChange(record.key, e.target.checked);
          }}
        />
      ),
    },
    {
      title: "RM/AS",
      dataIndex: "RM/AS",
      width: 200,
      key: "RM/AS",
      render: (room, record) => (
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center flex-col">
            <p className=" text-[17px] mb-0">{record.room}</p>
            {record.assignees.length > 0 && <p className="mb-0">{record.assignees.join(", ")}</p>}
          </div>
          <Tooltip
            placement="right"
            color="white"
            onVisibleChange={handleVisibleChange}

            // onVisibleChange={() => setHideContextMenu(true)}
            title={
              <div className="flex justify-center items-center flex-col">
                <RoomsTooltipContent key="menu" record={record} />
                <div className="mt-[10px]">
                  <Select
                    mode="multiple"
                    placeholder="Assignees"
                    style={{ minWidth: "100px" }}
                    onChange={(e) => {
                      handleCellChange(
                        record.key,
                        "assignees",
                        e,
                        "patientInfo"
                      );
                    }}
                    value={record.assignees || []}
                  >
                    {assigneeOptions.map((assignee) => (
                      <Select.Option key={assignee} value={assignee}>
                        {assignee}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            }
          >
            <IconButton size="small" aria-label="labs">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Procedures",
      dataIndex: "procedures",
      key: "procedures",
      render: (procedures, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };

        if (procedures.length === 0) return null;

        // Generate the list of procedure elements, with custom styling for comped or custom procedures
        const procedureElements = procedures.map((procedure, index) => {
          const isComped = ivPrepData.procedures.some(
            (p) => procedure.includes(p.name) && (p.comped && !["Red Light Bed", "Hydrogen In"].includes(procedure))
          );
          const isCustom = procedure.includes("(Custom)");
          return (
            <span
              key={index}
              style={{
                color: isComped ? "fuchsia" : isCustom ? "blue" : "inherit",
              }}
            >
              {index > 0 ? ", " : ""}
              {procedure.replace(" (Custom)", "")}
            </span>
          );
        });

        return (
          <div className="flex justify-between items-center w-full">
            <div>{procedureElements}</div>
            <IconButton
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);
                onCheckboxChange({
                  procedures: true,
                });
                handleContextMenu(event, record);
              }}
              onClick={() => handleOpenProcedureModal(record.key, record)}
              size="small"
              aria-label="edit-procedure"
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      title: "Dispensed",
      dataIndex: "dispensed",
      key: "dispensed",
      render: (dispensed, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };
        const extractName = (str) => {
          const match = str.match(/^[^(]+/);
          return match ? match[0].trim() : str.trim();
        };
        if (dispensed.length === 0) return null;

        // Generate dispensed items with conditional styling
        const dispensedElements = dispensed.map((item, index) => {
          const isComped = ivPrepData.dispensed.some(
            (d) => extractName(d.name) === extractName(item) && d.comped
          );
          item = item.replace("SUP", "");
          const isCustom = item.includes("(Custom)");
          return (
            <span
              key={index}
              style={{
                color: isComped ? "fuchsia" : isCustom ? "blue" : "inherit",
              }}
            >
              {index > 0 ? ", " : ""}
              {item.replace(" (Custom)", "")}
            </span>
          );
        });

        return (
          <div className="flex justify-between items-center w-full">
            <div>{dispensedElements}</div>
            <IconButton
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);

                onCheckboxChange({
                  dispensed: true,
                });
                handleContextMenu(event, record);
              }}
              onClick={() => handleOpenDispenseModal(record.key, record)}
              size="small"
              aria-label="edit-dispensed"
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      title: "Labs",
      className: "labs-column",
      onCell: () => ({
        className: "labs-column-cell",
      }),
      dataIndex: "labs",
      key: "labs",
      render: (labs, record) => {
        if (labs.length === 0) return null;

        // Generate lab tests elements with conditional styling
        const labTestsElements = labs.map((lab, index) => {
          const isCustom = lab.includes("(Custom)");
          return (
            <span key={index} style={{ color: isCustom ? "blue" : "inherit" }}>
              {lab.replace(" (Custom)", "")}
              {index < labs.length - 1 ? ", " : ""}
            </span>
          );
        });

        return (
          <div className="flex justify-between items-center w-full">
            <div>{labTestsElements}</div>
            <IconButton
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);
                onCheckboxChange({
                  labs: true,
                });
                handleContextMenu(event, record);
              }}
              onClick={() => handleOpenLabsModal(record.key, record)}
              size="small"
              aria-label="edit-labs"
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (note, record) => (
        <Input
          key={record.key}
          value={note}
          style={{ minWidth: "100px", width: inputWidth,background:'white',color:"black" }}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e, record.key)}
          onChange={(e) => {
            setAllTreatments((prevRecords) =>
            prevRecords.map((treatment) =>
            treatment._id === record.key ? { ...treatment, note: e.target.value } : treatment
          ));
          }}
        />
      ),
    },
  ];

  const handleReviewChange = async (orderId, isReviewed) => {
    try {
      await axios.put(
        `/patient/order/update-review/${orderId}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast.success("Review status updated successfully");
      const updatedTreatments = allTreatments.map((treatment) => {
        if (treatment._id === orderId) {
          return { ...treatment, review: isReviewed }
        }
        else {
          return treatment
        }
      })
      setAllTreatments(updatedTreatments)
      handleRefresh();
      // Refetch data or update state as needed
      handleDataReset(formattedDate);
    } catch (error) {
      toastifyToast.error("Error updating review status");
      console.error("Error:", error);
    }
  };

  const [allLabs, setAllLabs] = useState([]);
  const [hideContextMenu, setHideContextMenu] = useState(false);
  const fetchallLabs = async () => {
    try {
      const response = await axios.get("/allLabs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json",
        },
      });
      setAllLabs(response.data.allLabs);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const fetchDataForAllLabs = async (labs, userLabs) => {
    try {
      // Map each testName to its display name in the allLabs array
      const displayNames = labs.map((testName) => {
        // Find the lab with the matching test_name in the allLabs array
        const lab = allLabs.find((lab) => lab.test_name === testName);

        const displayName =
          lab && lab.display_name ? lab.display_name : testName;
        const userLab = userLabs.find((lab) => lab.test_name === testName);
        const customIndicator = userLab && userLab.custom ? " (Custom)" : "";

        return displayName + customIndicator;
      });

      // Filter out any null values
      const validDisplayNames = displayNames.filter((name) => name !== null);

      return validDisplayNames;
    } catch (error) {
      console.error("Error fetching lab data:", error);
      return [];
    }
  };

  const setNewLabsForSet = (setId, labs) => {
    setNewLabsMap((prevState) => ({
      ...prevState,
      [setId]: labs,
    }));
  };

  // Retrieve the state for a specific set of data
  const getNewLabsForSet = (setId) => {
    return newLabsMap[setId] || [];
  };

  const formatDataForTable = (data) => {
    console.warn("Treatments before formatting", data)
    return data?.map((item) => {
      // Include userLabs where checked is true
      const labs = item.userLabs
        .filter((lab) => lab.checked)
        ?.map((lab) => lab.test_name);
      if (labs.length > 0) {
        const setId = item._id; // Assuming _id is unique for each set of data
        const currentLabs = getNewLabsForSet(setId);

        if (labs.length !== currentLabs.length) {
          fetchDataForAllLabs(labs, item.userLabs).then((labsResp) => {
            if (JSON.stringify(labsResp) !== JSON.stringify(currentLabs)) {
              // Update the state only if there's a change
              if (labsResp.length > 0) {
                setNewLabsForSet(setId, labsResp);
              }
            }
          });
        }
      }
      // Include userdispensed items with non-empty selectedField
      const dispensed2 = item.userdispensed
        .filter((d) => d.selectedField !== "")
        ?.map((d) => {
          let dispensedName = d.name;
          if (d.custom) {
            dispensedName += " (Custom)"; // Append "(Custom)" if the dispensed item is custom
          }
          return `${dispensedName} (${d.selectedField}) x${d.quantity}`;
        });

      const supplements = item.userSupplements
        .filter((s) => s.quantity > 0)
        .map(
          (s) =>
            `${s.name} ${s?.selectedField ? `(${s?.selectedField})` : ""} x${s.quantity
            } SUP ${s.custom ? "(Custom)" : ""}`
        );

      const dispensed = [...dispensed2, ...supplements];

      // Include userProcedures where checked is true or selectedField is not an empty string
      const procedures = item.userProcedures
        .filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "")
        )
        ?.map((procedure) => {
          // Check if the procedure has a custom flag
          const isCustom = procedure.custom === true;
          let procedureName = procedure.procedure_name;

          if (procedure.selectedField && procedure.selectedField !== "") {
            procedureName += ` (${procedure.selectedField})`;
          }

          if (isCustom) {
            procedureName += " (Custom)";
          }

          return procedureName;
        });

      // Filter vitals
      const vitals = item?.vitals?.some(
        (vital) =>
          vital.HR !== 0 ||
          vital.BP !== "" ||
          vital.T !== 0 ||
          vital.W !== 0 ||
          vital.RR !== 0 ||
          vital.SPO2 !== 0
      )
        ? item.vitals
        : [];

      const supplementsNew = item.userSupplements.map((s) => `${s.name}`);

      const formattedDate =moment(selectedDateFromView).format("MM-DD-YYYY")
         === moment.utc(item.date.split("T")[0]).format("MM-DD-YYYY")
          ? "Today"
          : moment.utc(item.date.split("T")[0]).format("MM-DD-YYYY");

      return {
        key: item["_id"],
        // patientName: item.patientName, // Assuming patient name isn't provided, so using PatientId
        date: formattedDate,
        room: item.room || "unassigned", // Provide a fallback if room is undefined
        procedures,
        time: item.time,
        event: item.event,
        dispensed,
        usersupplements: supplementsNew,
        labs: labs.length === 0 ? [] : getNewLabsForSet(item._id),
        note: item.note || "",
        assignees: item.assignees || "",
        status: item.status,
        type: item.type,
        review: item.review,
        treatmentNotes: item.treatmentNotes,
        custom: item.custom,
        vitals,
      };
    });
  };

  const statusPriorities = {
    scheduled: 1,
    arrived: 2,
    pickup: 3,
    complete: 4,
  };

  const timeToMinutes = (time) => {
    if (!time) return 0;
    const [hours, minutes, meridian] = time
      .match(/(\d+):(\d+)\s*(am|pm)/i)
      .slice(1);
    return (
      ((hours % 12) + (meridian.toLowerCase() === "pm" ? 12 : 0)) * 60 +
      parseInt(minutes)
    );
  };

  function sortTreatmentsByStatus(treatments) {
    return treatments.sort((a, b) => {
      // Compare by status first
      const statusComparison =
        statusPriorities[a.status] - statusPriorities[b.status];
      if (statusComparison !== 0) return statusComparison;

      // If statuses are the same, then compare by time
      return timeToMinutes(a.time) - timeToMinutes(b.time);
    });
  }
  // Custom sorting function to move "pickup" items to the bottom
  const sortTreatmentsWithPickupLast = (data) => {
    const pickupItems = data.filter(
      (treatment) => treatment.status === "pickup"
    );
    const otherItems = data.filter(
      (treatment) => treatment.status !== "pickup"
    );
    return [...otherItems, ...pickupItems];
  };

  let sortedTreatments = formatDataForTable(allTreatments);
  useEffect(() => {
    const todayPatientsData = activePatient
      .map((patient) => {
        const patientId = patient["Customer ID"];
        const treatment = treatments.find(
          (treatment) => treatment.PatientId === patientId
        );
        if (treatment) {
          return {
            ...patient,
            treatmentId: treatment._id, // Assuming the treatment object has an 'id' property for treatment ID
          };
        }
        return null;
      })
      .filter((patient) => patient !== null);

    setTodayPatients(todayPatientsData);
  }, [activePatient, treatments]);

  useEffect(() => {
    sortedTreatments = sortTreatmentsByStatus(formatDataForTable(treatments));

    treatments.forEach(async (treatment) => {
      const ivPrepData = await fetchIV_Prep(treatment._id);
      setIvPrepByTreatment((prev) => ({
        ...prev,
        [treatment._id]: ivPrepData,
      }));
    });
  }, [treatments]);

  const handlePatientClickTreatmentView = (e, patientId2) => {
    e.preventDefault(); // Prevent default link behavior
    navigate(`/treatmentviewdisplay/${patientId2}`);
  };

  const handlePatientClickAuditView = (e, patientId2, orderid) => {
    e.preventDefault(); // Prevent default link behavior

    navigate(`/auditview/${patientId2}/${orderid}`);
  };

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setShowInactivePatients(isChecked);

    if (isChecked && inactivePatients.length === 0) {
      await getInactivePatientProfiles();
    }
  };

  const deleteTreatment = async (treatmentId) => {
    try {
      const response = await axios.delete(
        `/patient/order/delete/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatment deleted successfully");
        // Refresh the treatments list

        handleDataReset(selectedDate);
        handleClose();
      } else {
        toastifyToast.error("Failed to delete treatment");
      }
    } catch (error) {
      console.error("Error deleting treatment:", error);
      toastifyToast.error("Error deleting treatment");
    }
  };
  //Table functionalities

  const handleBulkActionChange = (value) => {
    setBulkAction(value || null);
    setShowDatePicker(value === "Date");
    setShowDuplicateOptions(value === "Duplicate");
  };

  // Function to execute the selected bulk action
  const executeBulkAction = async () => {
    if (bulkAction === "Date" && bulkActionDate) {
      try {
        const response = await axios.put(
          `/patient/order/bulk-update-date`,
          { treatmentIds: selectedRowKeys, newDate: bulkActionDate },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toastifyToast.success("Treatments date updated successfully");
          handleDataReset(selectedDate);
          // Refresh your data here
        } else {
          toastifyToast.error("Failed to update treatments date");
        }
      } catch (error) {
        toastifyToast.error("Error updating treatments date");
        console.error("Error:", error);
      }
    } else if (bulkAction === "Duplicate" && duplicateActionDate) {
      try {
        const response = await axios.post(
          "/patient/order/bulk-duplicate",
          {
            treatmentIds: selectedRowKeys,
            newDate: duplicateActionDate,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toastifyToast.success("Treatments duplicated successfully");
          handleDataReset(selectedDate);
          setSelectedRowKeys([]);
        } else {
          toastifyToast.error("Failed to duplicate treatments");
        }
      } catch (error) {
        toastifyToast.error("Error duplicating treatments");
        console.error("Error:", error);
      }
    } else if (bulkAction === "delete") {
      // Handle bulk delete action
      await handleBulkDelete();
    } else if (bulkAction === "review") {
      if (selectedRowKeys.length === 0) {
        toastifyToast.error("No treatments selected for review");
        return;
      }
      try {
        const response = await axios.put(
          "/patient/order/bulk-review",
          {
            treatmentIds: selectedRowKeys,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toastifyToast.success("Treatments set to review successfully");
          handleDataReset(selectedDate);
          setSelectedRowKeys([]);
        } else {
          toastifyToast.error("Failed to review treatments");
        }
      } catch (error) {
        toastifyToast.error("Error reviewing treatments");
        console.error("Error reviewing treatments:", error);
      }
    } else {
      handleBulkUpdate(bulkAction);
    }
  };

  const calculateTopPosition = (mouseY) => {
    const menuHeight = contextMenu ? 20 : 500;
    const windowHeight = window.innerHeight;
    const spaceBelowCursor = windowHeight - mouseY;

    if (spaceBelowCursor >= menuHeight) {
      return mouseY;
    } else {
      return mouseY - menuHeight < 0 ? 0 : mouseY - menuHeight;
    }
  };

  function calculateTopPositionNCM(mouseY) {
    const menuHeight = newContextMenu ? 20 : 400;
    const windowHeight = window.innerHeight;
    const spaceBelowCursor = windowHeight - mouseY;

    if (spaceBelowCursor >= menuHeight) {
      return mouseY;
    } else {
      return mouseY - menuHeight;
    }
  }

  const calculateLeftPosition = (mouseX) => {
    const menuWidth = 200;
    const windowWidth = window.innerWidth;
    const spaceRightOfCursor = windowWidth - mouseX;

    if (spaceRightOfCursor >= menuWidth) {
      return mouseX;
    } else {
      return mouseX - menuWidth < 0 ? 0 : mouseX - menuWidth;
    }
  };

  async function handleTreatmentUpdateInContext(action, additionalData = {}) {
    const treatmentId = contextMenu?.data?.key;
    if (!treatmentId) {
      toastifyToast.error("No treatment selected");
      return;
    }

    switch (action) {
      case "scheduled":
      case "arrived":
      case "complete":
      case "review":
        try {
          const response = await axios.put(
            `/patient/order/bulk-update-status`,
            {
              treatmentIds: [treatmentId],
              newStatus: action,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
          if (response.status === 200) {
            toastifyToast.success(`Treatment set to ${action} successfully`);
            handleDataReset(selectedDate);
            handleClose();
          } else {
            toastifyToast.error(`Failed to set treatment to ${action}`);
          }
        } catch (error) {
          toastifyToast.error(`Error setting treatment to ${action}`);
          console.error("Error:", error);
        }
        break;

      case "Date":
        try {
          const response = await axios.put(
            `/patient/order/bulk-update-date`,
            {
              treatmentIds: [treatmentId],
              newDate: additionalData.date,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );

          if (response.status === 200) {
            toastifyToast.success("Treatment date updated successfully");
            if (
              additionalData?.treatment?.event &&
              additionalData?.treatment?.event !== "unassigned"
            ) {
              const idc = getCalendarIdByType(
                additionalData?.treatment?.status
              );
              const ids = getCalendarIdByType("scheduled");
              changeEventDate(
                additionalData?.treatment?.event,
                additionalData?.treatment?.status,
                additionalData.date
              ).then((response) => {
                createWatch(response.id, treatmentId, ids, "scheduled");
              });
            }

            handleDataReset(selectedDate);
            handleClose();
          } else {
            toastifyToast.error("Failed to update treatment date");
          }
        } catch (error) {
          toastifyToast.error("Error updating treatment date");
          console.error("Error:", error);
        }
        break;
      case "Duplicate":
        //
        try {
          const response = await axios.post(
            `/patient/order/bulk-duplicate`,
            {
              treatmentIds: [treatmentId],
              newDate: additionalData.date,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
          if (response.status === 200) {
            toastifyToast.success("Treatment duplicated successfully");
            const treatment = response.data.treatment?.[0];

            const cid = getCalendarIdByType(treatment.status);

            const rcid = getCalendarCidByType(treatment.status);

            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (
              treatment?.event !== "unassigned" &&
              treatment?.event &&
              duplicateGoogleEvent
            ) {
              const responseEvent = await axios.post(
                `/new-create-event`,
                {
                  time: treatment?.time,
                  date: treatment?.date,
                  patientName:
                    additionalData?.treatment?.patientName ||
                    treatment?.patientName,
                  timezone,
                  cid: rcid,
                }
              );
              handleCellChange(
                treatment._id,
                "event",
                responseEvent.data.id,
                "patientInfo"
              );
              const responseWatch = await axios.post(
                `/create-watch`,
                {
                  eventId: responseEvent.data.id,
                  treatmentId: treatment?._id,
                  calendarId: cid,
                  status: treatment.status,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );
            }

            handleDataReset(selectedDate);
            handleClose();
          } else {
            toastifyToast.error("Failed to duplicate treatment");
          }
        } catch (error) {
          toastifyToast.error("Error duplicating treatment");
          console.error("Error:", error);
        }
        break;
      default:
        console.error("Unhandled action:", action);
    }
  }

  // Function to handle date selection for bulk actions
  const handleBulkActionDateChange = (date) => {
    setBulkActionDate(date ? date.toISOString().split("T")[0] : null);
  };

  const handleBulkActionDuplicateChange = (date) => {
    setDuplicateActionDate(date ? date.toISOString().split("T")[0] : null);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleBulkUpdate = async (newStatus) => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    // Call the new backend API for bulk update
    try {
      const response = await axios.put(
        "/patient/order/bulk-update-status",
        {
          treatmentIds: selectedRowKeys,
          newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success(`Treatments updated to ${newStatus}`);
        handleDataReset(selectedDate);
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to update treatments");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatments");
      console.error("Error updating treatments:", error);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    // Call the new backend API for bulk delete
    try {
      const response = await axios.delete(
        "/patient/order/bulk-delete",
        {
          data: { treatmentIds: selectedRowKeys },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatments deleted successfully");
        handleDataReset(selectedDate);
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to delete treatments");
      }
    } catch (error) {
      toastifyToast.error("Error deleting treatments");
      console.error("Error deleting treatments:", error);
    }
  };

  //REACT DRAG AND DROP FUNCTIONALITY

  const changeTreatmentStatus = async (treatmentId, newStatus) => {
    try {
      const response = await axios.put(
        `/treatments/updateStatus/${treatmentId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatment status updated successfully");
        // You might want to fetch the updated list of treatments here
      } else {
        toastifyToast.error("Failed to update treatment status");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  async function transferCalendar(newStatus, oldStatus, eventId) {
    let nStatus;
    let oStatus;
    if (newStatus === "complete") {
      nStatus = "complete";
    } else {
      nStatus = newStatus;
    }
    if (oldStatus === "complete") {
      oStatus = "complete";
    } else {
      oStatus = oldStatus;
    }
    try {
      const scid = getCalendarCidByType(oStatus);
      const tid = getCalendarCidByType(nStatus);
      const tcid = getCalendarCidByType(nStatus);

      const response = await axios.post(
        `/transfer-event`,
        {
          sourceCalendarId: scid,
          targetCalendarId: tcid,
          targetId: tid,
          eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error transfer treatment:", error);
    }
  }

  async function transferEventNoShowCancel(
    treatmentId,
    oldStatus,
    eventId,
    type
  ) {
    let oStatus;
    if (oldStatus === "complete") {
      oStatus = "complete";
    } else {
      oStatus = oldStatus;
    }
    try {
      const scid = getCalendarCidByType(oStatus);
      const response = await axios.post(
        `/transfer-event`,
        {
          sourceCalendarId: scid,
          targetCalendarId:
            type === "noshow" ? noShowCalendar.cid : cancelledCalendar.cid,
          targetId: "",
          eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      deleteTreatment(treatmentId);
      return response.data;
    } catch (error) {
      console.error("Error transfer treatment:", error);
    }
  }

  async function createWatch(eventId, treatmentId, calendarId, status) {
    const responseWatch = await axios.post(
      `/create-watch`,
      {
        eventId: eventId,
        treatmentId: treatmentId,
        calendarId: calendarId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
  }

  const moveCard = (cardId, newStatus, treatment) => {
    // Change status of the treatment with matching cardId
    const updatedTreatments = treatments?.map((treatment) => {
      if (treatment._id === cardId) {
        return { ...treatment, status: newStatus };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);

    // Persist the status change to the server
    changeTreatmentStatus(cardId, newStatus);

    if (treatment.event !== "unassigned") {
      transferCalendar(newStatus, treatment.status, treatment.event).then(
        (event) => {
          // Change status of the treatment with matching cardId
          const updatedTreatments = treatments?.map((treatment) => {
            if (treatment._id === cardId) {
              handleCellChange(
                treatment._id,
                "event",
                event.newEventId,
                "patientInfo"
              );
              createWatch(
                event.newEventId,
                treatment._id,
                event.calendarId,
                newStatus
              );
              return {
                ...treatment,
                event: event.newEventId,
                status: newStatus,
              };
            }
            return treatment;
          });
          fetchTreatments(formattedDate);

          setTreatments(updatedTreatments);
          fetchCalendars(formattedDate);

          // Persist the status change to the server
          changeTreatmentStatus(cardId, newStatus);
        }
      );
    }
  };

  const moveCardRoom = (room, treatment) => {
    handleCellChange(treatment?._id, "room", room.room, "patientInfo");
  };

  const ItemTypes = {
    CARD: "card",
    ROOM: "room",
  };

  const lightColors = [
    "#d1ecf1",
    "#d4edda",
    "#fff3cd",
    "#fce8b2",
    "#dcd3ff",
    "#f8d7da",
  ];

  const getRoomColor = (() => {
    const colorMap = {};
    let colorIndex = 0;

    return (roomName) => {
      if (!(roomName in colorMap)) {
        colorMap[roomName] = lightColors[colorIndex % lightColors.length];
        colorIndex++;
      }
      return colorMap[roomName];
    };
  })();

  const darkColors = [
    "#3cafc3",
    "#4bb464",
    "#e6b000",
    "#c59207",
    "#2e00e6",
    "#ad1f2a",
  ];

  const getRoomColorDark = (() => {
    const colorMap = {};
    let colorIndex = 0;

    return (roomName) => {
      if (!(roomName in colorMap)) {
        colorMap[roomName] = darkColors[colorIndex % darkColors.length];
        colorIndex++;
      }
      return colorMap[roomName];
    };
  })();

  const draggableCardStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    margin: "5px 5px 5px 0",
    borderRadius: "20px",
    boxSizing: "border-box",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  function calculateMarginTop(patientsGroupedByRoom) {
    const nonEmptyGroupCount = Object.values(patientsGroupedByRoom).filter(
      (patients) => patients.length > 0
    ).length;

    const totalMarginTop =
      nonEmptyGroupCount > 0 ? nonEmptyGroupCount * 30 : 30;

    return `${totalMarginTop}px`;
  }

  const droppableAreaStyle = {
    // border: "2px solid #d3d3d3", // Light border
    borderRadius: "6px",
    padding: "10px 0px 0px 10px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%", // Take 100% width of the parent container
    minHeight: "50px", // Set to 50px more than the card height
    marginBottom: "20px", // Margin bottom for spacing between areas
    justifyContent: "flex-start", // Align items to the start
    alignItems: "flex-start",
    alignSelf: "flex-start",
  };

  const fetchIV_Prep = async (treatmentID) => {
    try {
      const response = await axios.get(
        `/iv_prep/treatment/${treatmentID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IV preparation data:", error);
    }
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const TooltipContent = ({ treatment }) => {
    const ivPrepData = ivPrepByTreatment[treatment._id] || {
      procedures: [],
      dispensed: [],
    };

    const getProcedureStyle = (name) => {
      const found = ivPrepData.procedures.find(
        (p) => p.name === name && p.comped
      );
      return found ? { color: "orange" } : {};
    };
    const getDispensedStyle = (name) => {
      const found = ivPrepData.dispensed.find(
        (p) => p.name === name && p.comped
      );
      return found ? { color: "orange" } : {};
    };

    const checkedLabs = getCheckedLabs(treatment.userLabs);
    const dispensedItems = getDispensedItemsWithSelectedField(
      treatment.userdispensed
    );
    const relevantProcedures = getRelevantProcedures(treatment.userProcedures);

    return (
      <div onClick={() => setShowCalendar(false)}>
        {checkedLabs.length > 0 && (
          <div className="tooltipSection">
            <strong>Labs:</strong>
            {checkedLabs?.map((lab) => (
              <div key={lab._id} className="tooltipItem">
                {lab.test_name}
              </div>
            ))}
          </div>
        )}
        <br />
        {dispensedItems.length > 0 && (
          <div className="tooltipSection">
            <strong>Dispensed:</strong>
            {dispensedItems?.map((item) => (
              <div
                key={item._id}
                className="tooltipItem"
                style={getDispensedStyle(item.name)}
              >
                {item.name} - Quantity: {item.quantity} - Dosage:{" "}
                {item.selectedField}
              </div>
            ))}
          </div>
        )}
        <br />
        {relevantProcedures.length > 0 && (
          <div className="tooltipSection">
            <strong>Procedures:</strong>
            {relevantProcedures?.map((procedure) => (
              <div
                key={procedure._id}
                className="tooltipItem"
                style={getProcedureStyle(procedure.procedure_name)}
              >
                {procedure.procedure_name}
                {procedure.selectedField &&
                  ` - Selected: ${procedure.selectedField}`}
                {procedure.note && ` - Note: ${procedure.note}`}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const DraggableCard = ({
    treatment,
    moveCard,
    maxTabsPerRow,
    fetchEventsNew,
  }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.CARD,
      item: { id: treatment._id, treatment },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    // Adjust the opacity when dragging
    const opacity = isDragging ? 0.5 : 1;
    const backgroundColor = getRoomColor(treatment.room);

    // Adjust the width dynamically based on maxTabsPerRow
    const cardStyle = {
      ...draggableCardStyle,
      width: `calc(${100 / maxTabsPerRow}% - 10px)`, // Adjust the calculation as needed
    };
    const event = CalendarEvents.find(
      (event) => event.treatmentId == treatment["_id"]
    );

    const startTime = event ? moment(event.startTime).format("HH:mm") : "";

    return (
      <div
        ref={drag}
        className={`${styles.draggableCardDisplay} md:w-1/5 lg:w-1/5`}
        style={{
          ...cardStyle,
          opacity,
          color: treatment.status === "complete" ? "white" : "",
          backgroundColor:
            treatment.status === "complete" ? "#228B22" : backgroundColor,
        }}
      >
        <Tooltip
          title={<TooltipContent treatment={treatment} />}
          placement="top"
        >
          <span
            className={`justify-self-center ${treatment.status === "pickup" ? "text-sm" : ""
              }`}
            onClick={(e) => {
              handlePatientClickAuditView(
                e,
                treatment.PatientId,
                treatment._id
              );
            }}
          >
            <DroppableAreaRoom
              treatment={treatment}
              startTime={startTime}
              moveCardRoom={moveCardRoom}
            />
          </span>
        </Tooltip>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ zIndex: 1000, marginRight: "5px" }}
            key={treatment._id}
            onClick={(e) => {
              e.stopPropagation();
              setIsTyping(true);
            }}
          >
            <style jsx>{`
              .ant-picker-ok button {
                color: white;
                background-color: #3b82f6;
              }
            `}</style>
            <Popover
              content={
                <div>
                  <Form
                    form={form}
                    initialValues={{
                      note: "",
                    }}
                    onFinish={(values) => {
                      // Handle form submission here

                      const timezone =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;

                      handleTimeChange(
                        values.time,
                        treatment._id,
                        treatment?.event,
                        treatment?.status,
                        treatment?.patientName,
                        timezone,
                        values.note
                      );
                      toggleCalendarVisibility(null);
                    }}
                  >
                    <div className="flex">
                      <Form.Item
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: "Please select a time!",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          minuteStep={15}
                          allowClear={true}
                          onChange={(time) => {
                            form.setFieldsValue({ time });
                          }}
                          showNow={false}
                          disabledHours={disabledHours}
                        // disabledMinutes={disabledMinutes}
                        />
                      </Form.Item>

                      <Form.Item className="ml-[5px]">
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#3B82F6",
                          }}
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                    <Form.Item name="note">
                      <Input placeholder="Enter note here" />
                    </Form.Item>
                  </Form>
                </div>
              }
              trigger="click"
              open={visibleCalendarId === treatment._id}
              onOpenChange={(open) => handleOpenChange(open, treatment)}
            >
              <ScheduleIcon
                onClick={() => setIsTyping(true)}
                style={{ fontSize: "18px", marginLeft: "8px" }}
              />
            </Popover>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Are you sure to delete this treatment?"
              onConfirm={() => deleteTreatment(treatment._id)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                className:
                  "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
              }}
              cancelButtonProps={{
                className:
                  "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
              }}
            >
              <DeleteOutlined style={{ fontSize: "16px" }} />
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  };

  const DraggableCardRoom = ({
    room,
    index,
    maxTabsPerRow,
    patientsGroupedByRoom,
  }) => {
    const hasPatients = Boolean(patientsGroupedByRoom[room]);
    const backgroundColor = hasPatients ? getRoomColor(room) : "transparent";
    const roundColor = hasPatients ? getRoomColorDark(room) : "#949494";
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.ROOM,
      item: { room },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <div
        ref={drag}
        key={index}
        style={{ backgroundColor }}
        className="m-[5px] border-gray-300 shadow-md p-1 rounded-[5px] flex items-start"
      >
        <div className="flex items-center mr-2 mt-[6px]">
          <Round color={roundColor} />
        </div>
        <div className="flex-1 min-w-0">
          {room}
          {patientsGroupedByRoom[room] && " - "}
          {patientsGroupedByRoom[room] && (
            <>{patientsGroupedByRoom[room].join(", ")}</>
          )}
        </div>
      </div>
    );
  };

  const DroppableAreaRoom = ({ treatment, startTime, moveCardRoom }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.ROOM,
      hover: () => {
        // if (hoveredColumn !== status) {
        //   setHoveredColumn(status);
        // }
      },
      drop: (item, monitor) => {
        moveCardRoom(item, treatment);
        setHoveredColumn(null);
      },
    });

    return (
      <span ref={drop} className="flex justify-center items-center">
        {treatment.patientName}
        {treatment.status !== "pickup" && treatment.room
          ? ` - ${treatment.room}`
          : ""}
        {treatment.time ? ` - ${treatment.time}` : ""}
        {treatment.custom ? ` - ${treatment.custom}` : ""}
        {startTime ? ` - ${startTime}` : ""}
        {treatment.type === "pickup" && (
          <IconButton>
            <AddShoppingCartIcon
              style={{
                fontSize: "18px",
                color: treatment.status === "complete" ? "white" : "",
              }}
            />
          </IconButton>
        )}
      </span>
    );
  };

  //LPDV FUNCTIONALITY
  const [tubesSummary, setTubesSummary] = useState({});
  const tubeColors = {
    "Gel-Barrier": "#C25C22",
    "Lavender Top (EDTA) Whole Blood": "#9900FF", // Assuming 'dark lavender' as a hex color
    "Yellow-top (ACD-A)": "#F1C232", // Assuming 'darker yellow' as a hex color
    "Futhan Collection Kit": "#C25C22",
    "Blue-top (sodium citrate)": "#68BDBD",
    "Lavender Top (EDTA) Serum Separator": "#674EA7", // Assuming 'dark lavender' as a hex color
    // Add other tube types and their colors here
  };

  const handleOpenLabsModal = async (orderId, record) => {
    setRecordForNotes(record);
    setCurrentPatientIdForModal(orderId);
    try {
      const response = await axios.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setClickedTreatmentData(response.data);
      setPatientId(response.data.PatientId);
      // Separate checked and unchecked labs
      const checkedLabs = response.data.userLabs.filter((lab) => lab.checked);
      const uncheckedLabs = response.data.userLabs.filter(
        (lab) => !lab.checked
      );

      const responseTubes = await axios.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (responseTubes?.data?.TubesSummary) {
        setTubesSummary(responseTubes?.data?.TubesSummary);
      }

      // Combine them with checked labs first
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];
      setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId, patientName: response.data.patientName });
      setLabsModalOpen(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const [loading, setLoading] = useState(false);
  const handleRefreshLabsAndTubes = async () => {
    const scrollTop = containerRef.current.scrollTop;

    setLoading(true);
    try {
      // Assuming orderId is available in the component's state or props
      const orderId = currentPatientLabs.orderId;

      // Fetch new labs data
      const labsResponse = await axios.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      // Function to sort labs if needed
      const sortLabs = (labs) => {
        // Your sorting logic here. Example:
        return labs.sort((a, b) =>
          a.checked === b.checked ? 0 : a.checked ? -1 : 1
        );
      };
      // Sort labs here if necessary
      const sortedLabs = sortLabs(labsResponse.data.userLabs);

      // Fetch new tubes summary
      const tubesResponse = await axios.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      // Update the state with the new data
      setCurrentPatientLabs((prevState) => {
        return ({
          ...prevState,
          labs: sortedLabs,
          orderId: orderId
        })
      })

      if (tubesResponse?.data?.TubesSummary) {
        setTubesSummary(tubesResponse?.data?.TubesSummary);
      }

      setLoading(false);
      setLabsModalLoader(false);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    } catch (error) {
      setLoading(false);
      console.error("Error refreshing labs and tubes:", error);
    }
  };

  const handleOpenProcedureModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userProcedures = response.data.userProcedures;
      userProcedures.patientName = response.data.patientName;
      proceduresSortingFunction(contextProcedures, userProcedures);
      setCurrentProcedures(userProcedures);
      setClickedTreatmentData(response.data);
      setProcedureModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenSupplementsModal = async (patientId, record) => {
    setRecordForNotes(record);

    try {
      const treatmentDataResponse = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const userSupplementsResponse = await axios.get(
        `/userSupplements/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const availableSupplementsResponse = await axios.get(
        "/supplements-names",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      const PatientData = activePatient.find(
        (patient) => patient["Full Name"] === record?.patientName
      );
      setPatientData(PatientData);
      setClickedTreatmentData(treatmentDataResponse.data);
      try {
        const response = await axios.get(
          "/getAllSupplements"
        );

        setAllSupplements(response.data)
        const updatedSupplements = userSupplementsResponse.data.map(supp => {
          const matchedSupplement = response.data.find(
            supplement => supplement.supplement_name === supp.name
          );

          if (matchedSupplement) {
            return { ...supp, frequencyOptions: matchedSupplement.frequency[0] === '1 dosage twice a day' ? [...matchedSupplement.frequency] : ['1 dosage twice a day', ...matchedSupplement.frequency] };
          }
          return supp;
        });

        // setSupplements(updatedSupplements);
        // const sortedItems = sortItems([...updatedSupplements]);
        setCurrentSupplements(updatedSupplements);
      }
      catch (error) {

        console.error("Error fetching supplements:", error);
      }
      const userSupplementsData = userSupplementsResponse.data;
      const availableSupplementsData = availableSupplementsResponse.data;

      // setCurrentSupplements(userSupplementsData);
      setAvailableSupplements(availableSupplementsData);
      setCurrentPatientIdForModal(patientId);
      setSupplementsPopup(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLabs = currentPatientLabs.labs.filter((lab) =>
    lab.test_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const halfLength = Math.ceil(filteredLabs.length / 2);

  const firstColumnItems = filteredLabs.slice(0, halfLength);
  const secondColumnItems = filteredLabs.slice(halfLength);

  const [recordForNotes, setRecordForNotes] = useState();
  const handleOpenDispenseModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userDispenses = response.data.userdispensed;
      setClickedTreatmentData(response.data);
      userDispenses.patientName = response.data.patientName;
      dispensesSortingFunction(contextDispensed, userDispenses);
      setCurrentDispensedItems(userDispenses);
      setDispenseModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleOpenVitalsModal = async (patientId, record) => {
    setRecordForNotes(record);

    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);

      response.data.vitals.patientName = response.data.patientName;
      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleOpenTreatmentNoteModal = async (treatmentNote) => {
    setCurrentPatientIdForModal(treatmentNote.key);
    //treatmentNote is basically the treatment we want to send to treatment Note
    if (treatmentNote) {
      const selectedTreatment = treatments.find(
        (treatmentFind) => treatmentFind._id === treatmentNote.key
      );

      setCurrentTreatment({
        ...treatmentNote,
        custom: selectedTreatment.custom,
        date: selectedTreatment.date.split("T")[0],
        userLabs: selectedTreatment.userLabs,
        userProcedures: selectedTreatment.userProcedures,
        userdispensed: selectedTreatment.userdispensed,
        PatientId: selectedTreatment.PatientId,
        _id: selectedTreatment._id,
      });
      setTreatmentNoteModalOpen(true);
      isTreatmentNoteEditing.current = true;
    }
  };

  const handleCloseTreatmentNoteModal = () => {
    setCurrentTreatment(null);
    setTreatmentNoteModalOpen(false);
  };

  const handleNextNotes = () => {
    if (currentTreatment.status === "pickup") {
      handleOpenSupplementsModal(currentPatientIdForModal, recordForNotes);
    } else {
      handleOpenVitalsModal(currentPatientIdForModal, recordForNotes);
    }

    handleCloseTreatmentNoteModal();
  };

  const handlePreviousNotes = () => {
    handleOpenDispenseModal(currentPatientIdForModal, recordForNotes);

    handleCloseTreatmentNoteModal();
  };

  const handleNextLabs = () => {
    handleOpenProcedureModal(currentPatientIdForModal, recordForNotes);

    handleCloseLabsModal();
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const roomsOrder = [
    'unassigned',
    'pickup',
    'Community 1',
    'Community 2',
    'Room 1',
    'Room 2',
    'Room 3',
    'Room 4',
    'Education Room'
  ];
  const handleCellChange = (
    orderId,
    columnName,
    newValue,
    category,
    newData
  ) => {
    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData,
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${orderId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
        const patientId = allTreatments.filter(item => item._id === orderId)[0].PatientId
        setIsTyping(false);
        if (columnName === 'assignees') {
          setRoomsData(prevState =>
            prevState.map(roomData =>
              roomData.patients.some(patient => patient._id === orderId)
                ? {
                  ...roomData,
                  patients: roomData.patients.map(patient =>
                    patient._id === orderId ? { ...patient, assignees: response.data.assignees } : patient
                  )
                }
                : roomData
            )
          );
          const updatedTreatments = allTreatments.map((treatment) => {
            if (treatment._id === orderId) {
              return { ...treatment, assignees: response.data.assignees }
            }
            else {
              return treatment
            }
          })
          setAllTreatments(updatedTreatments)
        }
        if (columnName === 'room') {
          setRoomsData((prevState) => {
            let patientToMove;
            let roomExists = false;

            const updatedRooms = prevState.map((roomGroup) => {
              const updatedPatients = roomGroup.patients.filter((patient) => {
                if (patient.PatientId === patientId) {
                  patientToMove = { ...patient, room: newValue };
                  return false;
                }
                return true;
              });

              if (roomGroup.room === newValue) {
                roomExists = true;
              }

              return { ...roomGroup, patients: updatedPatients };
            });

            const newRooms = roomExists
              ? updatedRooms.map((roomGroup) => {
                if (roomGroup.room === newValue && patientToMove) {
                  return {
                    ...roomGroup,
                    patients: [...roomGroup.patients, patientToMove],
                  };
                }
                return roomGroup;
              })
              : [...updatedRooms, { room: newValue, patients: [patientToMove] }];

            const sortedRooms = newRooms.sort((a, b) => {
              return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
            });

            return sortedRooms;
          });
          const updatedTreatments = allTreatments.map((treatment) => {
            if (treatment._id === orderId) {
              return { ...treatment, room: response.data.room }
            }
            else {
              return treatment
            }
          })
          setAllTreatments(updatedTreatments)
        }
        handleRefresh();
        handleDataReset(selectedDate);
      })
      .catch((error) => {
        toastifyToast.error("Error updating the treatment");
        console.error("Error:", error);
        setIsTyping(false);
      });
  };

  const handleCloseLabsModal = () => {
    setLabsModalOpen(false);
    setCustomItems([]);
    setSearchTerm(""); // Clear the search term
  };

  const handleLabsCheckboxChange = (labId, checked) => {
    const scrollTop = containerRef.current.scrollTop;
    setCurrentPatientLabs((prevState) => {
      // Find the lab by ID to get its name
      const lab = prevState.labs.find((lab) => lab._id === labId);
      const labName = lab ? lab.test_name : "Unknown Lab";
      const labItemNumber = lab?.item_number;

      // {
      //   "category": "patientLabs",
      //
      // }

      // Construct the payload for the POST request
      const payload = {
        category: "patientLabs",
        updates: {
          checked: checked,
          value: labItemNumber ? `${labItemNumber}` : labName,
          type: labItemNumber ? "item_number" : "test_name",
        },
      };

      // Send the update to the backend using a POST request
      axios
        .put(
          `/patient/order/update/${currentPatientLabs.orderId}`,

          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          // Handle the successful update
          handleRefresh();
          handleRefreshLabsAndTubes();
          handleDataReset(selectedDate);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Update failed:", error);
        });

      // Update the labs state
      return {
        ...prevState,
        labs: prevState.labs?.map((lab) =>
          lab._id === labId ? { ...lab, checked: checked } : lab
        ),
      };
    });
    requestAnimationFrame(() => {
      containerRef.current.scrollTop = scrollTop;
    });
  };

  const handleDeleteLabItem = async (labItemId) => {
    try {
      // Assuming `labItemId` is the ID of the lab item to be deleted
      const response = await axios.delete(
        `/delete/labitem/${labItemId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      // Remove the deleted item from the customItems state
      let updatedCustomItems = customItems;
      if (customItems.some((item) => item.id === labItemId)) {
        updatedCustomItems = customItems.filter(
          (item) => item.id !== labItemId
        );
        setCustomItems(updatedCustomItems);
      }

      // Now update currentPatientLabs to reflect the deletion
      setCurrentPatientLabs((prevState) => {
        const updatedLabs = prevState.labs.filter(
          (lab) => lab._id !== labItemId
        );
        return { ...prevState, labs: updatedLabs };
      });
    } catch (error) {
      console.error("Error deleting lab item:", error);
      // Optionally, handle the error (e.g., display an error message)
    }
  };

  const [customItems, setCustomItems] = useState([]);

  const handleAddEmptyRowNew = () => {
    setCustomItems([
      ...customItems,
      { id: Date.now(), test_name: "", checked: false, isNew: true },
    ]);
  };

  const handleInputChangeNew = (id, value) => {
    const updatedItems = customItems.map((item) =>
      item.id === id ? { ...item, test_name: value } : item
    );
    setCustomItems(updatedItems);
  };
  const handleCheckboxChangeNew = (id, checked) => {
    const updatedItems = customItems.map((item) =>
      item.id === id ? { ...item, checked: checked } : item
    );
    setCustomItems(updatedItems);
    const item = customItems.find((item) => item.id === id);
    // Ensure the item exists
    if (item) {
      // Get the test_name from the item
      const { test_name, isNew } = item;
      // Now you have access to test_name and checked here

      if (checked) {
        const payload = {
          category: "patientLabs",
          newData: {
            test_name,
            checked,
            isNew,
          },
        };
        axios
          .put(
            `/patient/order/update/${currentPatientLabs.orderId}`,

            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the successful update

            handleDataReset(selectedDate);
          })
          .catch((error) => {
            // Handle any errors

            console.error("Update failed:", error);
          });
      }
    }
  };

  const [pickup, setPickup] = useState(false);

  const handlePickupOpen = () => {
    setPickup(true);
  };

  const handlePickupClose = () => {
    setPickup(false);
  };

  const handlePickUp = async (patientId) => {
    // Prepare the data to be sent
    const data = {
      dispensed: [],
      supplements: [],
      patientId: patientId, // Assuming patientId is available in this scope
    };

    // Send the data to the endpoint

    const response = await fetch(
      `/schedule-pickup/${selectedDate}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      setSelectedPatientId(undefined);
      toastifyToast.success("Pick up scheduled successfully!");
    } else {
      console.error("Error sending data:", response.statusText);
      toastifyToast.error("Error scheduling pick up!");
    }
  };

  const [supplementsPopup, setSupplementsPopup] = useState(false);
  const handleSupplementsPopupOpen = () => {
    setSupplementsPopup(true);
  };

  const handleSupplementsPopupClose = () => {
    setSupplementsPopup(false);
  };

  //Search Functionality

  const handleSearchFilterChange = (e) => {
    setSearchFilter(e.target.value.toLowerCase());
  };

  const handleRoomFilterChange = (value) => {
    setRoomFilter(value);
  };

  const handleAssigneeFilterChange = (value) => {
    setAssigneeFilter(value);
  };

  const filteredTreatments = sortedTreatments.filter((item) => {
    const matchesStatus =
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.status)
        : true;
    const matchesSearch = searchFilter
      ? item.patientName.toLowerCase().includes(searchFilter) ||
      item.procedures.join(", ").toLowerCase().includes(searchFilter) ||
      item.dispensed.join(", ").toLowerCase().includes(searchFilter) ||
      item.labs.join(", ").toLowerCase().includes(searchFilter) ||
      item.room.toLowerCase().includes(searchFilter) ||
      (item.assignees || []).join(", ").toLowerCase().includes(searchFilter)
      : true;

    // const matchesType = showOrdersOnly ? item.type === "Order" : true;

    // return matchesStatus && matchesSearch && matchesType;
    return matchesStatus && matchesSearch ;
  });

  //Room Functionality

  const toggleRoomsList = () => {
    setIsRoomsListOpen(!isRoomsListOpen);
  };

  const groupPatientsByRoom = (treatments) => {
    const grouped = {};
    treatments.forEach((treatment) => {
      if (treatment.status === "complete") {
        return; // Skip this treatment if its status is 'complete'
      }
      const room = treatment.room || "unassigned";
      const patientFirstName = treatment.patientName.split(" ")[0]; // Get the first name
      if (!grouped[room]) {
        grouped[room] = [patientFirstName];
      } else {
        grouped[room].push(patientFirstName);
      }
    });
    return grouped;
  };

  const patientsGroupedByRoom = groupPatientsByRoom(treatments);

  const FloatingRoomsList = ({ rooms, patientsGroupedByRoom }) => {
    //
    const [isRoomsListOpen, setIsRoomsListOpen] = useState(false);

    const toggleRoomsList = () => {
      setIsRoomsListOpen(!isRoomsListOpen);
    };

    return (
      <div className="border border-blue-500 rounded-md mt-[30px]">
        <div onClick={toggleRoomsList}>
          <div className="div"></div>
          <h4 className="bg-blue-500 md:p-0 font-bold text-xl text-white">
            <span className="p-3">Available Rooms</span>
          </h4>
          <hr className="border-blue-500" />
          <div className="p-1 grid grid-cols-2 mt-[10px]">
            {rooms?.map((room, index) => (
              <DraggableCardRoom
                room={room}
                patientsGroupedByRoom={patientsGroupedByRoom}
                index={index}
                maxTabsPerRow={3}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  // Function to find the previous treatment based on a given date
  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };
  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };
  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };
  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };
  const showToastTT = (message, type) => {
    toast(message, { type });
  };
  const handleCopyLabsFromPreviousTreatment = async () => {
    setLabsModalLoader(true);
    try {
      // Fetch previous treatments
      const previousTreatmentsResponse = await axios.get(
        `/api/patient/${patientId}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setLabsModalLoader(false);
        showToast(
          "error",
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setLabsModalLoader(false);
        showToast(
          "error",
          "There are no treatments of the required type to copy labs from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setLabsModalLoader(false);
        return;
      }
      const previousLabs = treatment.userLabs.filter(
        (previouslab) => previouslab.checked === true
      );
      if (previousLabs.length <= 0) {
        setLabsModalLoader(false);

        showToast(
          "error",
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      const updatePromises = previousLabs.map((previouslab) => {
        const payload = {
          category: "patientLabs",
          updates: {
            checked: true,
            value: previouslab.item_number && `${previouslab.item_number}`,
            type: previouslab.item_number && "item_number",
          },
        };

        return axios.put(
          `/patient/order/update/${currentPatientLabs.orderId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      });

      Promise.all(updatePromises)
        .then((responses) => {
          const updatedLabs = responses
            .flatMap((response) => response.data.userLabs)
            .filter((lab) => lab.checked === true);

          handleRefreshLabsAndTubes();
          setLabsModalLoader(false);

          showToast("success", "Labs Copied Successfully");
        })
        .catch((error) => {
          setLabsModalLoader(false);

          showToast("error", "Failed to copy labs from previous treatment");
        });
    } catch (error) {
      showToast("error", "Error fetching previous treatments:");
      setLabsModalLoader(false);

      showToast("error", "Failed to fetch previous treatments");
    }
  };

  //Status Key Filters
  const handleStatusFilter = (status) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status)); // Remove status
    } else {
      setSelectedStatuses([...selectedStatuses, status]); // Add status
    }
    resetInteractionTimeout();
  };

  const isStatusSelected = (status) => {
    return selectedStatuses.includes(status);
  };

  const handleUpdateStatus = (treatmentId) => {
    axios
      .put(
        `/patient/order/bulk-update-status`,
        {
          treatmentIds: [`${treatmentId}`],
          newStatus: 'complete'

        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Success:", response.data);
        setRoomsData(prevState =>
          prevState.map(roomData =>
            roomData.patients.some(patient => patient._id === treatmentId)
              ? {
                ...roomData,
                patients: roomData.patients.map(patient =>
                  patient._id === treatmentId ? { ...patient, status: 'complete' } : patient
                )
              }
              : roomData
          )
        );


      })
      .catch((error) => {
        console.error("Error:", error);
        toastifyToast.error("Error Updating Status")
      });
  }

  const StatusColorKey = () => (
    <div className=" flex w-auto flex-wrap flex-grow md:justify-center justify-start">
      {/* All option */}
      <div
        className="filter-options"
        key="all"
        style={{
          display: "flex",
          alignItems: "center",
          border: selectedStatuses.length === 0 ? "1px solid #1C82F5" : "none",
          borderRadius: "5px",
          padding: "2px 9px 2px 2px",
          margin: "2px",
          cursor: "pointer",
        }}
        onClick={() => setSelectedStatuses([])}
      >
        <Checkbox checked={selectedStatuses.length === 0} />
        <span className={`${styles.checkboxtabletext}`}>All</span>
      </div>

      {/* Other statuses */}
      {/* Lock icon hidden */}
      <div
        className="filter-options"
        key="lock"
        style={{
          display: "none",
          // display: "flex",
          alignItems: "center",
          border: isLocked ? "1px solid #1C82F5" : "none",
          borderRadius: "5px",
          padding: "2px 9px 2px 2px",
          margin: "2px",
          cursor: "pointer",
          background:"yellow"
        }}
        onClick={() => setIsLocked(!isLocked)}
      >
        <LockOutlined
          style={{ color: isLocked ? "#1C82F5" : "black", marginRight: "5px" }}
        />
        <span className={`${styles.checkboxtabletext}`}>Lock</span>
      </div>
    </div>
  );

  return (
    <>
      {/* Main Container */}

      {/* Table Section */}

      <Table
        columns={columns}
        onRow={(record, rowIndex) => ({
          onContextMenu: (event) => handleContextMenu(event, record),
        })}
        dataSource={filteredTreatments}
        pagination={false}
        rowClassName={(record) =>
          treatmentId == record.key ? "matching-row" : record.type === 'Order' ? "order-row" : "other-row"
        }
        rowStyle={{
          backgroundColor: "#ed9e28",
          color: "#666", // Example for text color
        }}
      />

      {(contextMenu && !tooltipVisible) && (
        <div
          className="fadeIn"
          style={{
            position: "fixed",
            top: newContextMenu
              ? calculateTopPositionNCM(contextMenu.mouseY)
              : calculateTopPosition(contextMenu.mouseY),
            left: newContextMenu
              ? contextMenu.mouseX
              : calculateLeftPosition(contextMenu.mouseX),
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            zIndex: 1000,
            padding: "8px 0",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ padding: "8px 16px", cursor: "pointer" }}>
            {(contextMenu?.data && !hideContextMenu) && (
              <>
                {newContextMenu ? (
                  <>
                    <div
                      className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        onClick={() => {
                          onCopyClick(contextMenu?.data?.key);
                        }}
                      >
                        Copy
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {copiedData && <div
                      className="mt-[5px] relative group"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button className="ml-[5px]">Paste</button>
                      <div
                        style={{ borderRadius: "10px" }}
                        className={`absolute hidden w-[150px] top-[-10px] ${contextMenu.totalWidth > contextMenu.viewportWidth
                          ? "left-[-150px]"
                          : "left-[150px]"
                          } bg-white shadow-lg py-2 px-4 group-hover:block`}
                      >
                        <button
                          onClick={() => handlePaste("modify")}
                          className="hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left"
                        >
                          Merge
                        </button>
                        <button
                          onClick={() => handlePaste("overwrite")}
                          className="hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left"
                        >
                          Overwrite
                        </button>
                      </div>
                    </div>}
                    <div
                      className={`${!undoOrderId && !undoToken ? "cursor-not-allowed" : ""
                        } mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className={`${!undoOrderId && !undoToken ? "cursor-not-allowed" : ""
                          }`}
                        disabled={!undoOrderId && !undoToken}
                        onClick={() => handleUndo(undoOrderId, undoToken)}
                      >
                        Undo
                      </button>
                    </div>
                    {showDatePickerForAction && (
                      <DatePicker
                        style={{ marginLeft: 10, height: "45px" }}
                        onChange={(date, dateString) => {
                          setShowDatePickerForAction(false);

                          if (selectedAction === "Date") {
                            handleTreatmentUpdateInContext("Date", {
                              date: dateString,
                              treatment: contextMenu?.data,
                            });
                          } else if (selectedAction === "Duplicate") {
                            handleTreatmentUpdateInContext("Duplicate", {
                              date: dateString,
                              treatment: contextMenu?.data,
                            });
                          }

                          setSelectedAction(null);
                        }}
                      />
                    )}
                    {showDatePickerForActionDuplicate && (
                      <>
                        <DatePicker
                          style={{ marginLeft: 10, height: "45px" }}
                          onChange={(date, dateString) => {
                            setShowDatePickerForAction(false);

                            if (selectedAction === "Date") {
                              handleTreatmentUpdateInContext("Date", {
                                date: dateString,
                              });
                            } else if (selectedAction === "Duplicate") {
                              handleTreatmentUpdateInContext("Duplicate", {
                                date: dateString,
                                treatment: contextMenu?.data,
                              });
                            }
                            setSelectedAction(null);
                          }}
                        />
                        {selectedAction === "Duplicate" && (
                          <Checkbox
                            title="Duplicate Google Event"
                            checked={duplicateGoogleEvent}
                            onChange={(e) =>
                              setDuplicateGoogleEvent(e.target.checked)
                            }
                          />
                        )}
                      </>
                    )}
                    {showSelectPatient && (
                      <Select
                        className={`${styles.patientDropdownInner} w-full `}
                        placeholder="Select a patient"
                        onChange={(e) => {
                          if (selectedCopyAction === "Patient") {
                            handlePatientSelectNew(e, contextMenu?.data?.key);
                          }
                          if (selectedCopyAction === "Treatment") {
                            copyTreatmentToPatient(e, contextMenu?.data);
                          }
                          setSelectedCopyAction(null);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedPatientId}
                      >
                        {activePatient?.map((patient) => (
                          <Option
                            key={patient._id}
                            value={patient["Customer ID"]}
                          >
                            {patient["Full Name"]}
                          </Option>
                        ))}
                      </Select>
                    )}
                    <div
                      className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ManageTreatmentTemplates
                        showToast={showToastTT}
                        from="tdv"
                        contextMenu={contextMenu}
                      />
                    </div>
                    <div
                      className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <TreatmentTemplates
                        setTemplate={setTemplate}
                        patient={selectedPatientId}
                        from="tdv"
                        contextMenu={contextMenu}
                        handleRefresh={handleRefresh}
                      />
                    </div>
                    <div
                      className={` mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button

                        onClick={() => handleUpdateStatus(treatmentId)}
                      >
                        Set To Complete
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          margin: "20px auto",
          // marginTop: "15px",
        }}
      >
        {loader && <BounceLoader color="#000" />}
      </div>

      {/* Container 3 ends */}
      <ProcedureModal
        open={procedureModalOpen}
        procedures={currentProcedures}
        onClose={() => setProcedureModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        handleOpenLabsModal={handleOpenLabsModal}
        handleOpenDispenseModal={handleOpenDispenseModal}
        recordForNotes={recordForNotes}
        selectedDate={selectedDate}
        clickedTreatmentData={clickedTreatmentData}
      />

      <SupplementsModalPopup
        open={supplementsPopup}
        onClose={() => setSupplementsPopup(false)}
        supplements={currentSupplements}
        activePatients={activePatient}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        patientData={patientData}
        handleCellChange={handleCellChange}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        recordForNotes={recordForNotes}
        selectedDate={selectedDate}
        allSupplements={allSupplements}
        toastifyToast={toastifyToast}
        clickedTreatmentData={clickedTreatmentData}
      />

      <DispenseModal
        open={dispenseModalOpen}
        dispensedItems={currentDispensedItems}
        onClose={() => setDispenseModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenProcedureModal={handleOpenProcedureModal}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={selectedDate}
        clickedTreatmentData={clickedTreatmentData}
      />

      <VitalsModal
        open={vitalsModalOpen}
        VitalsItems={currentVitalsItems}
        onClose={() => setVitalsModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={selectedDate}
      />

      <Dialog
        open={labsModalOpen}
        onClose={handleCloseLabsModal}
        // slotProps={{
        //   backdrop: {
        //     onClick: setCustomItems([]),
        //   },
        // }}
        aria-labelledby="labs-modal-title"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            // ...modalStyle,
            // backgroundColor: "#f3f4f6",
            padding: "24px",
          }}
        >
          {toast && (
            <CustomToast message={toastifyToast.message} variant={toastifyToast.variant} />
          )}
          <div>
            <div className="flex justify-between mb-[16px]">
              {" "}
              <span className="labstitleStyling">
                <IconButton disabled={true}>
                  <ChevronLeft />
                </IconButton>
                Patient Labs
                <IconButton onClick={handleNextLabs}>
                  <ChevronRight />
                </IconButton>
                <MuiTooltip
                  title={
                    <h1 className="text-sm">
                      Copy Labs From Previous Treatment
                    </h1>
                  }
                  placement="right"
                >
                  <IconButton
                     onClick={() => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleCopyLabsFromPreviousTreatment();
                          }
                        }}
                    sx={{ ml: "10px" }}
                  >
                    <ContentCopyRounded sx={{ fontSize: 30 }} />
                  </IconButton>
                </MuiTooltip>
              </span>{" "}
              <IconButton onClick={handleRefreshLabsAndTubes}>
                <RefreshIcon
                  style={{
                    fontSize: 30,
                    animation: loading
                      ? "spin 0.5s linear infinite"
                      : "none",
                  }}
                />
              </IconButton>
            </div>
          </div>
          {labsModalLoader && (
            <div className="loader-backdrop">
              <BounceLoader color="#000" />
            </div>
          )}

          <div>
            <div
              className={`${styles.activeStylingD} mb-[16px]`}
            >
              {currentPatientLabs?.patientName}
            </div>
            <div className="flex">
              <div className="flex">
                {Object.entries(tubesSummary).map(
                  ([tubeType, quantity]) => (
                    <div key={tubeType} className="p-1 rounded">
                      <span
                        className="text-white rounded p-1"
                        style={{
                          backgroundColor: tubeColors[tubeType] || "black",
                        }}
                      >
                        {tubeType} ({quantity})
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Search bar */}
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search labs..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ marginBottom: "16px" }}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }} // optional, for emphasis
            >
              Active Labs: {getCheckedLabsSummary()}
            </Typography>
            {/* Labs list */}
            {/* <div className="flex"> */}
            <div className="outer-labs-container">
              <div className="inner-labs-container py-3" ref={containerRef}>
                <div className="w-1/2 space-y-3">
                  {firstColumnItems.map((lab) => (
                    <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} - (${lab.item_number})`}
                      />
                      <Checkbox
                        checked={lab.checked}
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          }
                          else{
                          handleLabsCheckboxChange(lab._id, e.target.checked)}}
                        }
                      />
                      {lab.custom && (
                        <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </div>
                <div className="w-1/2 space-y-3">
                  {secondColumnItems.map((lab) => (
                    <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} - (${lab.item_number})`}
                      />
                      <Checkbox
                        checked={lab.checked}
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          }
                          else{
                          handleLabsCheckboxChange(lab._id, e.target.checked)}}
                        }
                      />
                      {lab.custom && (
                        <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </div>
              </div>

            </div>
            {/* </div> */}
            {customItems.map((item) => (
              <div key={item.id} className="w-[50%]">
                <ListItem>
                  <input
                    className="dispensedTextInputHere"
                    value={item.test_name}
                    onChange={(e) =>
                      handleInputChangeNew(item.id, e.target.value)
                    }
                    placeholder="Custom item"
                  />
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckboxChangeNew(item.id, e.target.checked)
                    }
                  />
                </ListItem>
              </div>
            ))}
            <button
              className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={() => {
                    if (clickedTreatmentData.review) {
                      return message.warning(
                        "This treatment is finalized so can't make any changes in it"
                      );
                    } else {
                      handleAddEmptyRowNew();
                    }
                  }}
            >
              Add Custom Item
            </button>
          </div>
        </Box>
      </Dialog>

      <EventsModal
        open={isModalVisibleNew}
        treatments={treatments}
        events={eventsNew}
        ignoredEvents={ignoredEvents}
        patients={activePatient}
        currentCalendar={currentCalendar}
        onClose={() => setIsModalVisibleNew(false)}
        handleCellChange={handleCellChange}
        status={status}
        toast={toast}
        fetchTreatments={fetchTreatments}
        formattedDate={formattedDate}
        setStatus={setStatus}
        fetchEventsNew={fetchEventsNew}
        scheduledCalendar={scheduledCalendar}
        arrivedCalendar={arrivedCalendar}
        pickupCalendar={pickupCalendar}
        completedCalendar={completedCalendar}
        allEvents={allEvents}
      />

      <AntdModal
        visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
        onCancel={handleCloseTreatmentNoteModal} // Changed to the corresponding close handler function for treatment notes
        footer={null}
        width="80vw" // Set the width to 80% of the viewport width
        styles={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <span className="vitalstitleStyling">
          {" "}
          <IconButton onClick={handlePreviousNotes}>
            <ChevronLeft />
          </IconButton>
          Treatment Notes
          <IconButton onClick={handleNextNotes}>
            <ChevronRight />
          </IconButton>
        </span>

        <TreatmentNotes
          treatment={currentTreatment}
          isTreatmentNoteEditing={isTreatmentNoteEditing.current}
          setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
        />
      </AntdModal>
    </>
  );
};

export default MultipleTreatmentsView;
