import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Form, Select, Button, DatePicker } from "antd";
import "tailwindcss/tailwind.css";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

function TreatmentTemplates({
  setTemplate,
  addNewOrderTemplate,
  addNewOrder,
  from,
  contextMenu,
  patient,
  handleRefresh,
  activePatient,
  treatments,
  setTreatments
}) {
  const [form] = Form.useForm();
  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCategorySelect, setShowCategorySelect] = useState(false);
  const [showDateSelect, setShowDateSelect] = useState(false);
  const [selectedTreatmentDate, setSelectedTreatmentDate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const fetchTreatmentTemplates = () => {
    setSelectedCategory(null);
    axios
      .get(
        `/treatment-templates?subcategory=${selectedSubcategory}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        setTemplates(response.data.data);
        axios.get("/categories").then((res) => {
          setCategories(res.data.data);
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the templates!", error);
      });
  }

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `/subcategories/${categoryId}`
      );
      setSubcategories(response.data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setSelectedTemplate(null);
    setTemplate(null);

    const ct = categories.find((c) => c.name === category);

    fetchSubCategories(ct?._id);
  };

  const handleSelectSubcategory = (subcategory) => {
    setSelectedSubcategory(subcategory);
    if (from === "tdv") {
      const temp = templates.filter(
        (template) =>
          template.category.name === selectedCategory &&
          template.subCategory.name === subcategory
      );

      setSelectedTemplate(temp[0]);
    } else {
      setSelectedTemplate(null);
    }

    setTemplate(null);
  };

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setTemplate(template);
  };

  const showAddTemplateModal = () => {
    form.resetFields();
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setSelectedTemplate(null);

    setIsAddTemplateOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    handleCheckboxChange(false);
    setSelectedCategory(null);
    setIsAddTemplateOpen(false);
    handleDateCheckboxChange(false);
  };

  const filteredTemplates = templates.filter(
    (template) =>
      template.category.name === selectedCategory &&
      template.subCategory.name === selectedSubcategory
  );

  const handleCheckboxChange = (isChecked) => {
    setShowCategorySelect(isChecked);

    if (!isChecked) {
      form.resetFields();
      setSelectedTemplate(null);
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setTemplate(null);
    }
  };
  const handleDateCheckboxChange = (isChecked) => {
    setShowDateSelect(isChecked);
  };
  const onChange = (date, dateString) => {
    const dateStr = date.$d;
    const parsedDate = moment(dateStr, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

    const formattedDate = parsedDate
      .tz("America/Phoenix")
      .format("YYYY-MM-DDTHH:mm:ssZ");

    setSelectedTreatmentDate(formattedDate);
  };

  return (
    <>
      {from === "tdv" ? (
        <button
          onClick={() => {
            if (!contextMenu?.data?.review) {
              fetchTreatmentTemplates()
              showAddTemplateModal();
            }
          }}
          style={{
            cursor:contextMenu?.data?.review?"not-allowed":"pointer"
          }}
        >
          Copy from Template
        </button>
      ) : (
        <Button
          className="py-2 px-4 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          onClick={() => {
            fetchTreatmentTemplates()
            showAddTemplateModal()
          }}
          icon={<PlusOutlined />}
        >
          Add New Treatment
        </Button>
      )}

      <Modal
        title="Select Treatment Template"
        visible={isAddTemplateOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            className="bg-blue-500 text-white rounded-md"
          >
            Close
          </Button>,
          <Button
            key="submit"
            onClick={() => {
              if (from === "tdv") {
                const url = `/updateOrderWithTemplate/${contextMenu?.data?.key}/${selectedTemplate._id}`;
                axios
                  .post(
                    url,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "sessionToken"
                        )}`,
                      },
                    }
                  )
                  .then((response) => {
                    const selectedPatient = activePatient.filter((item) => item["Customer ID"] === contextMenu?.data?.PatientId)
                    const filteredTreatments = treatments.map((treatment) => {
                      if (treatment._id === response.data._id) {
                        return { ...response.data, patientName: treatment.patientName }
                      }
                      return treatment
                    })
                    setTreatments(filteredTreatments)
                    handleRefresh();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                addNewOrderTemplate({
                  type: "Treatment",
                  date: selectedTreatmentDate,
                });
                setSelectedTreatmentDate(null);
              }
              form.resetFields();
              setIsAddTemplateOpen(false);
              setSelectedTemplate(null);
              setSelectedCategory(null);
              setSelectedSubcategory(null);
            }}
            className="bg-blue-500 text-white rounded-md"
          >
            {from === "tdv"
              ? "Copy From Template"
              : showCategorySelect
                ? "Add From Template"
                : "Add New Treatment"}
          </Button>,
          // from !== "tdv" && (
          //   <Button
          //     key="addNewTreatment"
          //     onClick={() => {
          //       addNewOrder({ type: "Treatment" })
          //       form.resetFields();
          //       setIsAddTemplateOpen(false);
          //       setSelectedTemplate(null);
          //       setSelectedCategory(null);
          //       setSelectedSubcategory(null);
          //     }}
          //     className="bg-blue-500 text-white rounded-md"
          //   >
          //     Add New Treatment
          //   </Button>
          // ),
        ]}
      >
        <div>
          {from !== "tdv" && (
            <div className="flex flex-row items-center justify-between">
              <label className="block mb-4">
                <input
                  type="checkbox"
                  checked={showCategorySelect}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                  className="mr-2"
                />
                Show Category Selection
              </label>
              <label className="block mb-4">
                <input
                  type="checkbox"
                  checked={showDateSelect}
                  onChange={(e) => handleDateCheckboxChange(e.target.checked)}
                  className="mr-2"
                />
                Show Date Selection
              </label>
            </div>
          )}
          {from === "tdv" || showCategorySelect ? (
            <>
              <Select
                placeholder="Select a category"
                className="w-full mb-4"
                value={selectedCategory}
                onChange={handleSelectCategory}
              >
                {categories.map((category) => (
                  <Option key={category._id} value={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>

              {selectedCategory && (
                <Select
                  placeholder="Select a subcategory"
                  className="w-full mb-4"
                  onChange={handleSelectSubcategory}
                >
                  {subcategories.map((subcategory) => (
                    <Option key={subcategory._id} value={subcategory.name}>
                      {subcategory.name}
                    </Option>
                  ))}
                </Select>
              )}
            </>
          ) : null}
          {from !== "tdv" && showDateSelect && (
            <>
              <DatePicker onChange={onChange} style={{ width: "100%" }} />
            </>
          )}
          {selectedCategory && selectedSubcategory && (
            <div>
              <h2 className="text-lg font-bold mb-4">Template</h2>
              <ul className="space-y-4">
                {filteredTemplates.map((template) => (
                  <div
                    key={template._id}
                    onClick={() => handleSelectTemplate(template)}
                    className="flex justify-between p-4 border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    <li className="list-none">
                      <p>
                        <strong>Labs:</strong> {template.labs.join(", ")}
                      </p>
                      <p>
                        <strong>Dispensed:</strong>{" "}
                        {template.dispensed.map((d) => d.name).join(", ")}
                      </p>
                      <p>
                        <strong>Procedures:</strong>{" "}
                        {template.procedures.map((p) => p.name).join(", ")}
                      </p>
                      <p>
                        <strong>Supplements:</strong>{" "}
                        {template.supplements.map((s) => s.name).join(", ")}
                      </p>
                    </li>
                    <button>
                      {selectedTemplate && selectedTemplate._id === template._id
                        ? "Selected"
                        : "Select"}
                    </button>
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default TreatmentTemplates;
