import React from "react";
import {
  Checkbox as AntdCheckbox,
  Tooltip as AntdTooltip,
  Button,
  Radio,
} from "antd";
import { Box, Paper } from "@mui/material";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const TableFilters = ({
  visibility,
  setVisibility,
  checkboxes,
  setCheckboxes,
  handleFilterChange,
  filter,
}) => {
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const handleToggle = (key) => {
    setVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  return (
    <div>
      <Box
        component={Paper}
        elevation={1}
        borderRadius={2}
        padding={3}
        marginBottom={4}
        width={"fit-content"}
      >
        <h2 className="text-2xl md:text-xl font-bold text-gray-800 tracking-wide">
          Table Filters
        </h2>
        <Radio.Group onChange={handleFilterChange} value={filter}>
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value="procedures">Procedures</Radio.Button>
          <Radio.Button value="dispensed">Dispensed</Radio.Button>
          <Radio.Button value="labs">Labs</Radio.Button>
        </Radio.Group>
        <div className="flex items-center gap-4 my-4">
          <AntdTooltip
            title={`Click to ${visibility.showNotes ? "hide" : "show"} notes`}
          >
            <Button
              className={
                visibility.showNotes
                  ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
              }
              onClick={() => handleToggle("showNotes")}
              icon={
                visibility.showNotes ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )
              }
            >
              {visibility.showNotes ? "Hide Notes" : "Show Notes"}
            </Button>
          </AntdTooltip>
          <AntdTooltip
            title={`Click to ${visibility.showLabs ? "hide" : "show"} labs`}
          >
            <Button
              className={
                visibility.showLabs
                  ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
              }
              onClick={() => handleToggle("showLabs")}
              icon={
                visibility.showLabs ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
            >
              {visibility.showLabs ? "Hide Labs" : "Show Labs"}
            </Button>
          </AntdTooltip>
          <AntdTooltip
            title={`Click to ${
              visibility.showProcedures ? "hide" : "show"
            } procedures`}
          >
            <Button
              className={
                visibility.showProcedures
                  ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
              }
              onClick={() => handleToggle("showProcedures")}
              icon={
                visibility.showProcedures ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )
              }
            >
              {visibility.showProcedures
                ? "Hide Procedures"
                : "Show Procedures"}
            </Button>
          </AntdTooltip>
          <AntdTooltip
            title={`Click to ${
              visibility.showDispensed ? "hide" : "show"
            } dispensed items`}
          >
            <Button
              className={
                visibility.showDispensed
                  ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
              }
              onClick={() => handleToggle("showDispensed")}
              icon={
                visibility.showDispensed ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )
              }
            >
              {visibility.showDispensed ? "Hide Dispensed" : "Show Dispensed"}
            </Button>
          </AntdTooltip>
          <AntdTooltip
            title={`Click to ${
              visibility.hideAllEmptyColumns ? "show all" : "hide empty columns"
            }`}
          >
            <Button
              className={
                visibility.hideAllEmptyColumns
                  ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
              }
              onClick={() => handleToggle("hideAllEmptyColumns")}
              icon={
                visibility.hideAllEmptyColumns ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )
              }
            >
              {visibility.hideAllEmptyColumns
                ? "Show All"
                : "Hide Empty Columns"}
            </Button>
          </AntdTooltip>
        </div>
        <div className="flex items-center gap-4 my-4">
          <AntdCheckbox
            name="activeLabs"
            checked={checkboxes.activeLabs}
            onChange={handleCheckboxChange}
          >
            Active Labs
          </AntdCheckbox>
          <AntdCheckbox
            name="activeProcedures"
            checked={checkboxes.activeProcedures}
            onChange={handleCheckboxChange}
          >
            Active Procedures
          </AntdCheckbox>
          <AntdCheckbox
            name="activeDispensed"
            checked={checkboxes.activeDispensed}
            onChange={handleCheckboxChange}
          >
            Active Dispensed
          </AntdCheckbox>
        </div>
      </Box>
    </div>
  );
};

export default TableFilters;
