import React, { forwardRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Box, Paper } from '@mui/material';
import { Button, Segmented } from 'antd';
import SummarySection from '../SummarySection';
import PastEncountersSection from './PastEncountersSection';
import TemplatesSection from './TemplatesSection';
const InnerTabsSection = forwardRef(({ selectedPatient, addTab }, ref) => {
  const tabOptions = ['Summary', 'Past Encounters', 'Templates'];
  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => {
    setActiveTab((prev) => (prev + 1) % tabOptions.length);
  };

  const handlePrev = () => {
    setActiveTab((prev) => (prev - 1 + tabOptions.length) % tabOptions.length);
  };


  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <SummarySection addTab={addTab} ref={ref} selectedPatient={selectedPatient} fromSoapNote={true}  />;
      case 1:
        return <PastEncountersSection addTab={addTab} selectedPatient={selectedPatient} />;
      case 2:
        return <TemplatesSection />;
      default:
        return <div>Select a tab to view content</div>;
    }
  };

  return (

    <Box item component={Paper} sx={{ width: '100%', height: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%', padding: 8, marginTop: 10 }}>
        <Button
          icon={<LeftOutlined />}
          onClick={handlePrev}
          disabled={activeTab === 0}
        />

        <Segmented
          options={tabOptions}
          block
          style={{ width: '100%' }}
          value={tabOptions[activeTab]}
          onChange={(value) => setActiveTab(tabOptions.indexOf(value))}
        />

        <Button
          icon={<RightOutlined />}
          onClick={handleNext}
          disabled={activeTab === tabOptions.length - 1}
        />
      </div>
      <div className='mt-[16px] w-[95%] h-[85%]'>
        {renderContent()}
      </div>
    </Box>

  )
})

export default InnerTabsSection