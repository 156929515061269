import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { DatePicker, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
const EventsModal = ({
  isPopoverOpen,
  setIsPopoverOpen,
  orders,
  setUpdatedEvents,
  updatedEvents,
  selectedDate,
  setSelectedDate,
}) => {
  const [editedValues, setEditedValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCalendars(selectedDate.format("YYYY-MM-DD"));
  }, [selectedDate]);
  useEffect(() => {
    if (isPopoverOpen === true) {
      fetchCalendars(selectedDate.format("YYYY-MM-DD"));
    }
  }, [isPopoverOpen]);

  const fetchCalendars = async (date) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const calendarIds = response.data.map((item) => item._id); // Get an array of calendarIds

        const requestPromises = response.data.map((item) =>
          axios.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const updatedResults = [];

        // Iterate through results and calendarIds simultaneously
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const calendarId = calendarIds[i]; // Get the corresponding calendarId

          if (result?.data?.message === "No events .") {
            continue; // Skip this result if there are no events
          }

          const events = result.data.events; // Get all events from the result

          for (const event of events) {
            try {
              const orderResponse = await axios.get(
                `/orders-by-event/${event.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );

              if (orderResponse.data && orderResponse.data.length > 0) {
                event.order = true;
              } else {
                event.order = false;
              }

              // Add calendarId to the event
              event.calendarId = calendarId;

              updatedResults.push(event);
            } catch (error) {
              console.error("Error fetching orders:", error);
              event.calendarId = calendarId;
              event.order = false;
              updatedResults.push(event);
            }
          }
        }
        setUpdatedEvents(updatedResults);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
      setIsLoading(false);
    }
  };

  const handlePreviousDate = () => {
    const previousDate = selectedDate.subtract(1, "day");
    setSelectedDate(previousDate);
  };
  const handleNextDate = () => {
    const nextDate = selectedDate.add(1, "day");
    setSelectedDate(nextDate);
  };
  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
  const updateTreatmentWithEventDataTime = async (event, treatment) => {
    const eventDate = moment(event?.start?.dateTime).format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime).format("hh:mm A");

    try {
      handleCellChange(treatment?._id, "event", event?.id, "patientInfo");
      handleCellChange(treatment?._id, "date", eventDate, "patientInfo");
      handleCellChange(treatment?._id, "time", eventTime, "patientInfo");
      const responseWatch = await axios.post(
        `/create-watch`,
        {
          eventId: event?.id,
          treatmentId: treatment?._id,
          calendarId: event?.calendarId,
          status: treatment?.status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      message.success("Event assigned to treatment");
    } catch (error) {
      console.error("Error creating treatment:", error);
    }
  };

  const handleCellChange = (patientId, columnName, newValue, category) => {
    if (category === "userProcedures") {
      const treatment = orders?.find((item) => item._id === treatment._id);
      if (treatment.review === true) {
        message.warn("Changes can't be made as treatment is finalized");
        return;
      }
    }
    if (columnName != "custom" || columnName != "note") {
      // Update local state first
      setEditedValues((prevValues) => ({
        ...prevValues,
        [`${patientId}-${columnName}`]: newValue,
      }));
      // updateEditedValues({ [`${patientId}-${columnName}`]: newValue });
    }

    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <Dialog
        open={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
          setUpdatedEvents([]);
        }}
        maxWidth="md"
        fullWidth
      >
        <Box p={1}>
          <DialogTitle>
            <div className="flex justify-between">
              <p>Events</p>
              <div>
                <IconButton onClick={handlePreviousDate}>
                  <ChevronLeft />
                </IconButton>
                <DatePicker
                  selected={selectedDate}
                  value={selectedDate}
                  format="YYYY/MM/DD"
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                />
                <IconButton onClick={handleNextDate}>
                  <ChevronRight />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {" "}
            {isLoading ? (
              <div className="flex items-center justify-center w-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="overflow-x-auto">
                {updatedEvents.length > 0 ? (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Start Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Link
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {updatedEvents
                        .filter((event) => !event.order)
                        .map((event, index) => (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "bg-gray-50" : "bg-white"
                            }
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              {event.summary}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {new Date(event.start.dateTime).toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <a
                                href={event.htmlLink}
                                className="text-blue-500 hover:underline"
                              >
                                Link
                              </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Autocomplete
                                id={`treatment-autocomplete-${index}`}
                                options={orders?.filter(
                                  (option) =>
                                    option.event === "unassigned" ||
                                    !option.event
                                )}
                                style={{ width: 200 }}
                                getOptionLabel={(option) => {
                                  const date = new Date(option["date"]);
                                  return date.toISOString().split("T")[0]; // Extracting only the date part
                                }}
                                // value={selectedPatients[index] || null}
                                onChange={(_, newValue) => {
                                  updateTreatmentWithEventDataTime(
                                    event,
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Treatment"
                                    variant="outlined"
                                  />
                                )}
                                isOptionEqualToValue={(option, value) =>
                                  option["date"] === value["date"]
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No events found for selected date </p>
                )}
              </div>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default EventsModal;
