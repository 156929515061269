import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import "../../customeCalender.css";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import "react-calendar/dist/Calendar.css";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../Components/SingleTreatmentView/labsModel.css";
import "./todayView.css";
import {
  ContentCopyRounded, East,
} from "@mui/icons-material";
import { ChevronLeft, ChevronRight } from "lucide-react"
import {
  Button, Table, Select, Input, Checkbox as AntdCheckbox, Popconfirm, message,
  Row, Card, Col, Badge, Space, Modal, Form, TimePicker, Dropdown, Menu as AntdMenu,
  DatePicker,
  Tooltip
} from "antd";
import { Modal as AntdModal } from "antd";
import "../../antTable.css";
import Dialog from "@mui/material/Dialog";
import SupplementsModalPopup from "../../Components/SupplementsModal";
import TreatmentNotes from "../../Components/TreatmentNotes";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Tooltip as MuiTooltip } from "@mui/material";
import { Box, Typography, ListItem, ListItemText, Checkbox } from "@mui/material";
import axios, { all } from "axios";
import { Menu, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; // Make sure this path is correct
import { BounceLoader, ClipLoader } from "react-spinners";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import ProcedureModal from "../../Components/ProcedureModal";
import DispenseModal from "../../Components/DispenseModal";
import VitalsModal from "../../Components/VitalsModal";
import ScheduleTreatmentModal from "../../Components/ScheduleTreatmentModal";
import MergeTemplatesModal from "../../Components/MergeTemplatesModal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataContext } from "../../context/DataContext";
import { blue } from "@mui/material/colors";
import { dispensesSortingFunction, proceduresSortingFunction } from "../../utils";
import EventsModal from "../../Components/EventsModal";
import CustomToast from "../../Components/CustomToast";
import styled from 'styled-components';
import { FilterOutlined, EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
import ConversationSection from "../../Components/ConversationSection";
import ManageTreatmentTemplates from "../../Components/Updations/ManageTreatmentTemplates";
import TreatmentTemplates from "../TreatmentViewDisplay/TreatmentTemplates";

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    background-color: white !important;
    color: black !important;
  }

  .ant-select-selection-item {
    color: black !important;
  }
`;

// Create an axios instance with default config
const api = axios.create({
  baseURL: '',
});

// Timezone utilities
const PHX_TIMEZONE = 'America/Phoenix';

// Get current date in Phoenix timezone
const getPhoenixDate = () => {
  const date = new Date();
  return new Date(date.toLocaleString('en-US', { timeZone: PHX_TIMEZONE }));
};

// Format date for API calls (YYYY-MM-DD)
const formatDateForAPI = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

// Format date for display
const formatDisplayDate = (date) => {
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

// Status card component
const TreatmentCardStatus = ({ treatment, startTime, ivPrepByTreatment, eventDetailsList, deleteTreatment, handleEventTimeChange }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'TREATMENT',
    item: { id: treatment._id, treatment },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const navigate = useNavigate();
  const eventDetail = eventDetailsList.find(
    (detail) => detail.treatmentId === treatment._id
  );

  let time = ""
  let note = ""

  if (eventDetail && eventDetail.eventData) {
    time = dayjs(eventDetail.eventData.start.dateTime)
    note = eventDetail.eventData.description || ""

  }

  return (
    <Tooltip
      title={<TooltipPatientContent treatment={treatment} ivPrepByTreatment={ivPrepByTreatment} />}
      placement="top"
      
    >
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          background: '#f0f0f0',
          borderRadius: '8px',
          marginBottom: '8px',
          fontSize: '12px'
        }}
        onClick={() => navigate(`/auditview/${treatment.PatientId}/${treatment._id}`)}
      >
        <span>{treatment.patientName}
          {treatment.assignees.length > 0 &&
            ` (${treatment.assignees.map(
              (assignee, index) =>
                `${index !== 0 ? ` ${assignee}` : `${assignee}`}`
            )})`}
          {treatment.time ? ` - ${treatment.time}` : ""}
          {startTime ? ` - ${startTime}` : ""}

        </span>
        <div className="flex justify-between items-center">
          <span style={{ color: '#888' }}>{treatment.room}</span>
          <div className="flex flex-row items-center" onClick={(e) => e.stopPropagation()}>
            <TimeModal time={time} note={note} submitFunction={(time, note) => {
              handleEventTimeChange(time, treatment._id, treatment?.event, treatment?.status, treatment?.patientName, note);
            }} />
            <div className="flex justify-center items-center">
              <Popconfirm
                title={`Are you sure to delete ${treatment.patientName}'s treatment?`}
                onConfirm={() => deleteTreatment(treatment._id)}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  className:
                    "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                }}
                cancelButtonProps={{
                  className:
                    "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                }}
              >
                <DeleteOutlined style={{ fontSize: "16px" }} />
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

// Status column component
const StatusColumn = ({ status, treatments, calendarEvents, moveCardStatus, calendar, ignoredEvents, fetchEventsNew, isLoading, ivPrepByTreatment, eventDetailsList, deleteTreatment, handleEventTimeChange }) => {
  const [, drop] = useDrop({
    accept: 'TREATMENT',
    drop: (item) => moveCardStatus(item.id, status),
  });

  return (
    <Col xs={24} md={12} lg={6}>
      <Card
        ref={drop}
        styles={{ body: { overflow: 'auto', height: '250px', marginBottom: '10px' } }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
            {calendar &&
              <Badge
                className="cursor-pointer"
                title={'Events'}
                count={calendar?.events?.filter(
                  (event) =>
                    !ignoredEvents?.some(
                      (ignoredEvent) =>
                        ignoredEvent.eventId === event.id
                    ) &&
                    !treatments?.some(
                      (treatment) =>
                        treatment.status === status &&
                        treatment.event === event.id
                    )
                )?.length ?? 0}
                onClick={() => fetchEventsNew(calendar, status)} />
            }
          </div>
        }
      >

        {!isLoading ?
          <div className="grid md:grid-cols-2 gap-4">
            {
              treatments.map((treatment) => {

                const event = calendarEvents.find(
                  (event) => event.treatmentId == treatment["_id"]
                );

                const startTime = event ? moment(event.startTime).format("HH:mm") : "";
                return <TreatmentCardStatus key={treatment._id} treatment={treatment} startTime={startTime} ivPrepByTreatment={ivPrepByTreatment} eventDetailsList={eventDetailsList} deleteTreatment={deleteTreatment} handleEventTimeChange={handleEventTimeChange} />
              })
            }
          </div>
          :
          <div className="flex justify-center items-center h-32">
            <ClipLoader color="#000000" loading={isLoading} size={25} />
          </div>
        }

      </Card>
    </Col>
  );
};

// Room card component
const TreatmentCardRoom = ({ treatment, moveCardRoom, ivPrepByTreatment, eventDetailsList, deleteTreatment, handleEventTimeChange, variant = 'vertical', disabled = false }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROOM_TREATMENT',
    item: { id: treatment._id, treatment, room: treatment.room, status: treatment.status },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disabled,
  });

  const rooms = [
    "Community 1", "Community 2", "Room 1", "Room 2", "Room 3", "Room 4", "Education Room"
  ]

  const items = rooms.map((room) => ({ label: room, key: room }));
  items.push({ label: "Pickup", key: "pickup" });
  items.push({ label: "Unassign", key: "unassigned" });
  items.push({ label: "Set To Completed", key: "complete" });

  const eventDetail = eventDetailsList.find(
    (detail) => detail.treatmentId === treatment._id
  );

  let time = ""
  let note = ""

  if (eventDetail && eventDetail.eventData) {
    time = dayjs(eventDetail.eventData.start.dateTime)
    note = eventDetail.eventData.description || ""

  }

  // Create context menu with AntdMenu with title "Move to"
  const ContextMenu = () => (
    <AntdMenu>
      <span className="text-xs font-semibold">Move to</span>
      {items.map((item) => (
        <AntdMenu.Item key={item.key} onClick={() => item.key === 'complete' || item.key === 'pickup' ? moveCardRoom(treatment._id, item.key, true) : moveCardRoom(treatment._id, item.key)}>
          {item.label}
        </AntdMenu.Item>
      ))}
    </AntdMenu>
  );



  return (
    <Tooltip
      title={<TooltipPatientContent treatment={treatment} ivPrepByTreatment={ivPrepByTreatment} />}
      placement="top"
    >
      <Dropdown
        overlay={ContextMenu()}
        trigger={['contextMenu']}
      >
        <div
          ref={disabled ? null : drag}
          className={`
        flex justify-between items-center rounded-lg ${treatment.status === 'complete' && treatment.room !== 'unassigned' ? 'bg-green-200' : 'bg-gray-100'}
        ${!disabled && 'cursor-move'}
        ${isDragging ? 'opacity-50' : 'opacity-100'}
        ${variant === 'horizontal' ? 'p-3 mr-3 flex-shrink-0' : 'p-2 mb-2'}
      `}
        >
          <span className="text-sm">{treatment.patientName}</span>
          <div className="flex flex-row items-center" onClick={(e) => e.stopPropagation()}>
            <TimeModal time={time} note={note} submitFunction={(time, note) => {
              handleEventTimeChange(time, treatment._id, treatment?.event, treatment?.status, treatment?.patientName, note);
            }} />
            <div className="flex justify-center items-center">
              <Popconfirm
                title={`Are you sure to delete ${treatment.patientName}'s treatment?`}
                onConfirm={() => deleteTreatment(treatment._id)}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  className:
                    "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                }}
                cancelButtonProps={{
                  className:
                    "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                }}
              >
                <DeleteOutlined style={{ fontSize: "16px" }} />
              </Popconfirm>
            </div>
          </div>
        </div>

      </Dropdown>
    </Tooltip>

  );
};

// Room Column Unassigned component
const RoomColumnUnassigned = ({ room, treatments, ivPrepByTreatment, moveCardRoom, isLoading, eventDetailsList, deleteTreatment, handleEventTimeChange, height='180px', fullHeight=false }) => {
  const [, drop] = useDrop({
    accept: 'ROOM_TREATMENT',
    drop: (item) => {

      if (item.room !== 'unassigned') {
        moveCardRoom(item.id, 'unassigned');
      }

      if (item.status !== room) {
        moveCardRoom(item.id, room, true);
      }

    },
  });

  const unique_assignees = [...new Set(treatments.map(treatment => treatment.assignees).flat())];

  return (
    <div className="w-full p-2">
      <Card
        styles={{ body: fullHeight?{ overflow: 'auto', marginBottom: '15px', minHeight: '105px', height: height }:{ overflow: 'auto', marginBottom: '15px', minHeight: '105px', maxHeight: height }}}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {room.charAt(0).toUpperCase() + room.slice(1)}
            {/* {
              unique_assignees.length > 0 ?

                <span className="text-xs">{unique_assignees.join(", ")}</span>
                :
                <span className="text-xs text-red-400">No Assignees</span>
            } */}
          </div>
        }
      >
        <div ref={drop} className="h-full" style={{minHeight: '105px'}}>
        <div className="overflow-auto w-full flex flex-wrap gap-[10px]">
            {
              !isLoading ?
                treatments.map((treatment) => (
                  <TreatmentCardRoom

                    key={treatment._id}
                    treatment={treatment}
                    disabled={false}
                    ivPrepByTreatment={ivPrepByTreatment}
                    moveCardRoom={moveCardRoom}
                    eventDetailsList={eventDetailsList}
                    deleteTreatment={deleteTreatment}
                    handleEventTimeChange={handleEventTimeChange}
                  />
                )) :
                <div className="flex justify-center items-center h-32 w-full">
                  <ClipLoader color="#000000" loading={isLoading} size={15} />
                </div>
            }
          </div>
        </div>
      </Card>
      <div className="flex justify-center items-center mt-2 bg-white" style={{ borderRadius: '8px', padding: '2px' }}>
        {
          unique_assignees.length > 0 ?

            <span className="text-xs">{unique_assignees.join(", ")}</span>
            :
            <span className="text-xs text-red-400">No Assignees</span>
        }
      </div>
    </div>
  );
};

// Room column component
const RoomColumn = ({ room, treatments, ivPrepByTreatment, moveCardRoom, isLoading, eventDetailsList, deleteTreatment, handleEventTimeChange }) => {
  const isPickup = room === "Pickup";
  const [, drop] = useDrop({
    accept: 'ROOM_TREATMENT',
    drop: (item) => room === "Pickup" ? moveCardRoom(item.id, 'pickup', true) : moveCardRoom(item.id, room),
    // canDrop: () => !isPickup
  });

  const unique_assignees = [...new Set(treatments.map(treatment => treatment.assignees).flat())];

  //Sort Treatments such that the ones with status 'complete' are at the end
  const sortedTreatments = treatments.sort((a, b) => a.status === 'complete' ? 1 : -1);

  return (
    <div className="w-full p-2">
      <Card
        styles={{ body: { overflow: 'auto', height: '180px', marginBottom: '10px' } }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {room}
          </div>
        }

      >
        <div ref={drop} className="h-full">
          <div className=" max-h-48">
            {
              !isLoading ?
                sortedTreatments.map((treatment) => (
                  <TreatmentCardRoom
                    key={treatment._id}
                    treatment={treatment}
                    disabled={isPickup}
                    ivPrepByTreatment={ivPrepByTreatment}
                    moveCardRoom={moveCardRoom}
                    eventDetailsList={eventDetailsList}
                    deleteTreatment={deleteTreatment}
                    handleEventTimeChange={handleEventTimeChange}
                  />
                )) :
                <div className="flex justify-center items-center h-32">
                  <ClipLoader color="#000000" loading={isLoading} size={15} />
                </div>
            }
          </div>


        </div>

      </Card>
      <div className="flex justify-center items-center mt-2 bg-white" style={{ borderRadius: '8px', padding: '2px' }}>
        {
          unique_assignees.length > 0 ?

            <span className="text-xs">{unique_assignees.join(", ")}</span>
            :
            <span className="text-xs text-red-400">No Assignees</span>
        }
      </div>
    </div>
  );
};

const TimeModal = ({ time, note, submitFunction }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // Update form values when props change
    form.setFieldsValue({
      time: time ? dayjs(time) : null,
      note: note || ''
    });
  }, [time, note, form]);

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  const handleSubmit = (values) => {

    submitFunction(values.time, values.note);
    setIsModalOpen(false);
  };


  return (
    <>
      <Button
        icon={<ClockCircleOutlined />}
        type="text"
        onClick={(e) => {
          e.stopPropagation();

          setIsModalOpen(true);
        }}

      />

      <Modal
        title="Set Time and Note"
        open={isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);

        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            time: time ? dayjs(time) : null,
            note: note || ''
          }}
        >
          <Form.Item
            name="time"
            label="Time"
            rules={[{ required: true, message: 'Please select time' }]}
          >
            <TimePicker
              format="hh:mm A"
              minuteStep={15}
              disabledHours={disabledHours}
              hideDisabledOptions
              use12Hours
              style={{ width: '100%' }}
              needConfirm={false}
              showNow={false}
            />
          </Form.Item>

          <Form.Item
            name="note"
            label="Note"
            rules={[{ required: true, message: 'Please enter a note' }]}
          >
            <Input.TextArea />
          </Form.Item>

          <div className="flex justify-end items-center">
            <Form.Item>

              <Button className="bg-white text-black px-8" htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const EditRoomModal = ({ record, roomOptions, assigneeOptions, onCellChange }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectRoomOptions = roomOptions.map((room) => ({ label: room, value: room }));
  const selectAssigneeOptions = assigneeOptions.map((assignee) => ({ label: assignee, value: assignee }));
  selectRoomOptions.unshift({ label: "Unassigned", value: "unassigned" });

  return (
    <>
      <Button
        icon={<EditOutlined />}
        type="text"
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        title="Edit Room & Assignees"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Done
          </Button>,
        ]}
      >
        <div className="space-y-4">
          <div>
            <label className="block mb-2">Room</label>
            <Select
              defaultValue={record.room || null}
              onChange={(value) => onCellChange(record.key, "room", value, "patientInfo")}
              options={selectRoomOptions}
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label className="block mb-2">Assignees</label>
            <Select
              mode="multiple"
              defaultValue={record.assignees || []}
              onChange={(value) => onCellChange(record.key, "assignees", value, "patientInfo")}
              options={selectAssigneeOptions}
              style={{ width: '100%' }}

            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const ConversationsModal = ({ patientIDs, setUnreadMessagesCount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);



  return (
    <>
      <Button className="bg-white text-black" onClick={() => setIsModalOpen((prev) => !prev)}>Recent Conversations</Button>

      <Modal
        title="Recent Conversations"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={'900px'}
      >
        <div className="flex justify-center items-center">
          <ConversationSection
            key={patientIDs.join(",")}
            patientIds={patientIDs}
            showUnreadMessages={true}
            setUnreadMes
            sagesCount={setUnreadMessagesCount}
          />
        </div>

      </Modal>
    </>
  );
};

const TooltipPatientContent = ({ ivPrepByTreatment, treatment }) => {
  const ivPrepData = ivPrepByTreatment[treatment._id] || {
    procedures: [],
    dispensed: [],
  };

  const checkedLabs = treatment.userLabs.filter((lab) => lab.checked);
  const dispensedItems = treatment.userdispensed.filter((item) => item.selectedField)
  const relevantProcedures = treatment.userProcedures.filter((procedure) => procedure.checked || procedure.selectedField);

  return (
    <div onClick={() => { }}>
      {checkedLabs.length > 0 && (
        <div className="tooltipSection">
          <strong>Labs:</strong>
          {checkedLabs?.map((lab) => (
            <div key={lab._id} className="tooltipItem">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensedItems.length > 0 && (
        <div className="tooltipSection">
          <br />
          <strong>Dispensed:</strong>
          {dispensedItems?.map((item) => (
            <div
              key={item._id}
              className="tooltipItem"
              style={

                ivPrepData.dispensed.find(
                  (p) => p.name === item.name && p.comped
                ) ? { color: "orange" } : {}

              }
            >
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {relevantProcedures.length > 0 && (
        <div className="tooltipSection">
          <br />
          <strong>Procedures:</strong>
          {relevantProcedures?.map((procedure) => (
            <div
              key={procedure._id}
              className="tooltipItem"
              style={
                ivPrepData.procedures.find(
                  (p) => p.name === procedure.procedure_name && p.comped
                ) ? { color: "orange" } : {}
              }
            >
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


const SelectPatientModal = ({ visible, patients, onSelect, onClose, treatment }) => {

  return (
    <Modal
      title="Select Patient"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="space-y-4">
        <Select
          showSearch
          placeholder="Select a patient"
          optionFilterProp="children"
          value={treatment ? treatment.patientId : null}
          onChange={
            (value) => {
              onSelect(value, treatment.key);
            }}
          style={{ width: '100%' }}
        >
          {patients.map((patient) => (
            <Option key={patient._id} value={patient['Customer ID']}>
              {patient['Full Name']}
            </Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};


const SelectDateModal = ({ visible, onSelect, onClose, treatment }) => {
  // Convert the date string to dayjs object
  const currentDate = treatment ? dayjs(treatment.date) : dayjs();

  return (
    <Modal
      title="Select Date"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="space-y-4">
        <DatePicker
          value={currentDate}
          onChange={(date, dateString) => {
            onSelect([treatment.key], dateString);
            onClose();
          }}
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  );
};


const TodayView = () => {
  const {
    data: {
      labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
    isTyping,
    setIsTyping,
  } = useContext(DataContext);
  const [currentDate, setCurrentDate] = useState(getPhoenixDate());
  const [treatments, setTreatments] = useState([]);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [ivPrepByTreatment, setIvPrepByTreatment] = useState({});
  const [newLabsMap, setNewLabsMap] = useState({});
  const [allLabs, setAllLabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patientLoader, setPatientLoader] = useState(false);
  const [activePatients, setActivePatients] = useState([]);
  const [inActivePatients, setInActivePatients] = useState([]);
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [byRoom, setByRoom] = useState(false);
  const [searchTermLabs, setSearchTermLabs] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const statuses = ['scheduled', 'arrived', 'pickup', 'complete']
  const rooms = [
    "Pickup", "Community 1", "Community 2", "Room 1", "Room 2", "Room 3", "Room 4", "Education Room"
  ]
  const formattedDateForAPI = useMemo(() => formatDateForAPI(currentDate), [currentDate]);
  const displayDate = useMemo(() => formatDisplayDate(currentDate), [currentDate]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [eventDetailsList, setEventDetailsList] = useState([]);
  const [scheduledCalendar, setScheduledCalendar] = useState(null);
  const [arrivedCalendar, setArrivedCalendar] = useState(null);
  const [cancelledCalendar, setCancelledCalendar] = useState(null);
  const [noShowCalendar, setNoShowCalendar] = useState(null);
  const [pickupCalendar, setPickupCalendar] = useState(null);
  const [completedCalendar, setCompletedCalendar] = useState(null);
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [eventsNew, setEventsNew] = useState([]);
  const [ignoredEvents, setIgnoredEvents] = useState([]);
  const [currentCalendar, setCurrentCalendar] = useState(null);
  const [status, setStatus] = useState(null);
  const [toast, setToast] = useState(null);
  const [isCreateScheduleLoading, setIsCreateScheduleLoading] = useState(false);
  const [isCreatePickupLoading, setIsCreatePickupLoading] = useState(false);
  const [isFetchingEvents, setIsFetchingEvents] = useState(false);
  const [patientIDs, setPatientIDs] = useState([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [recordForNotes, setRecordForNotes] = useState();
  const [currentPatientIdForModal, setCurrentPatientIdForModal] = useState(null);
  const [patientId, setPatientId] = useState();
  const [tubesSummary, setTubesSummary] = useState({});
  const [currentPatientLabs, setCurrentPatientLabs] = useState({
    labs: [],
    orderId: null,
  });
  const [labsModalOpen, setLabsModalOpen] = useState(false);
  const [selectRedrawnLabs, setSelectRedrawnLabs] = useState(false);
  const [clickedTreatmentData, setClickedTreatmentData] = useState();
  const [currentProcedures, setCurrentProcedures] = useState([]);
  const [procedureModalOpen, setProcedureModalOpen] = useState(false);
  const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
  const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
  const [currentTreatment, setCurrentTreatment] = useState();
  const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
  const isTreatmentNoteEditing = useRef(false);
  const [patientData, setPatientData] = useState(null);
  const [allSupplements, setAllSupplements] = useState([]);
  const [currentSupplements, setCurrentSupplements] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);
  const [supplementsPopup, setSupplementsPopup] = useState(false);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const isNoteEditing = useRef(false);
  const [inputWidth, setInputWidth] = useState("100px");
  const [labsModalLoader, setLabsModalLoader] = useState(false);
  const [isLabsRefreshLoading, setIsLabsRefreshLoading] = useState(false);
  const containerRef = useRef(null);
  const [customItems, setCustomItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [bulkAction, setBulkAction] = useState("");
  const [showBulkDatePicker, setShowBulkDatePicker] = useState(false);
  const [bulkActionDate, setBulkActionDate] = useState(null);
  const [isMergeTemplatesModalVisible, setIsMergeTemplatesModalVisible] = useState(false);
  const [tableContextMenuCurrentRecord, setTableContextMenuCurrentRecord] = useState(null);
  const [isSelectPatientModalOpen, setIsSelectPatientModalOpen] = useState(false);
  const [selectPatientAction, setSelectPatientAction] = useState(null);
  const [isSelectDateModalOpen, setIsSelectDateModalOpen] = useState(false);
  const [selectDataAction, setSelectDataAction] = useState(null);
  const [template, setTemplate] = useState(null);
  const navigate = useNavigate();

  const tubeColors = {
    "Gel-Barrier": "#C25C22",
    "Lavender Top (EDTA) Whole Blood": "#9900FF",
    "Yellow-top (ACD-A)": "#F1C232",
    "Futhan Collection Kit": "#C25C22",
    "Blue-top (sodium citrate)": "#68BDBD",
    "Lavender Top (EDTA) Serum Separator": "#674EA7",
  };

  const filteredLabs = currentPatientLabs.labs.filter((lab) =>
    lab.test_name.toLowerCase().includes(searchTermLabs.toLowerCase())
  );

  const halfLength = Math.ceil(filteredLabs.length / 2);

  const firstColumnItems = filteredLabs.slice(0, halfLength);
  const secondColumnItems = filteredLabs.slice(halfLength);




  // Format date for input (YYYY-MM-DD)
  const formattedDateForInput = useMemo(() => {
    return formatDateForAPI(currentDate); // reuse API format as it's the same format needed for input
  }, [currentDate]);

  // Memoize the API call function
  const getTreatments = async (loading = true) => {


    if (!loading) {

      if (isLoading) {
        return;
      }
    }

    else {
      setIsLoading(true);
    }

    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const calendars = await fetchCalendars(formattedDateForAPI);
      const response = await api.get(`/ordered/${formattedDateForAPI}`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      // Initialize empty arrays for both states
      let newTreatments = [];
      let newFilteredTreatments = [];


      if (response.data && response.data.length > 0) {
        const treatmentsWithIvPrep = response.data?.map((treatment) => ({
          ...treatment,
          ivPrepComplete: treatment.iv_prep === 'complete',
        })) ?? [];

        treatmentsWithIvPrep.forEach(async (treatment) => {
          const ivPrepData = await fetchIVPrepData(treatment._id);
          setIvPrepByTreatment((prev) => ({
            ...prev,
            [treatment._id]: ivPrepData,
          }));
        });

        newTreatments = treatmentsWithIvPrep;
        newFilteredTreatments = await formatDataForTable(treatmentsWithIvPrep);
      }


      if (newTreatments.length > 0) {
        await fetchAllEventDetails(newTreatments, calendars);
      }

      // Always set both states, even if empty
      setTreatments(newTreatments);
      setFilteredTreatments(newFilteredTreatments);
      setPatientIDs(newTreatments.map((item) => item.PatientId))



    } catch (error) {
      // On error, clear both states
      setTreatments([]);
      setFilteredTreatments([]);
      setPatientIDs([]);
      console.error('Error fetching treatments:', error);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
      fetchCalendarEvents();
    }
  }

  // Fetch all labs
  const fetchallLabs = async () => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get("/allLabs", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
      });

      setAllLabs(response.data.allLabs);
      return response.data.allLabs;
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // Fetch IV preparation data for a treatment
  const fetchIVPrepData = async (treatmentID) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/iv_prep/treatment/${treatmentID}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IV preparation data:", error);
    }
  };

  // Format data for table
  const fetchDataForAllLabs = async (labs, userLabs) => {
    try {
      const allLabs = await fetchallLabs();

      // Map each testName to its display name in the allLabs array
      const displayNames = labs.map((testName) => {
        // Find the lab with the matching test_name in the allLabs array

        const lab = allLabs.find((lab) => lab.test_name === testName);

        const displayName =
          lab && lab.display_name ? lab.display_name : testName;
        const userLab = userLabs.find((lab) => lab.test_name === testName);
        const customIndicator = userLab && userLab.custom ? " (Custom)" : "";

        return displayName + customIndicator;
      });

      // Filter out any null values
      const validDisplayNames = displayNames.filter((name) => name !== null);

      return validDisplayNames;
    } catch (error) {
      console.error("Error fetching lab data:", error);
      return [];
    }
  };

  const setNewLabsForSet = (setId, labs) => {
    setNewLabsMap((prevState) => ({
      ...prevState,
      [setId]: labs,
    }));
  };

  const formatDataForTable = async (data) => {
    const formattedData = await Promise.all(data?.map(async (item) => {
      // Include userLabs where checked is true
      const labs = item.userLabs
        .filter((lab) => lab.checked)
        ?.map((lab) => lab.test_name);

      if (labs.length > 0) {
        const setId = item._id;
        const currentLabs = newLabsMap[setId] || [];

        if (labs.length !== currentLabs.length) {
          const labsResp = await fetchDataForAllLabs(labs, item.userLabs);
          if (JSON.stringify(labsResp) !== JSON.stringify(currentLabs)) {
            if (labsResp.length > 0) {
              setNewLabsForSet(setId, labsResp);
              // Update local reference to newLabsMap
              newLabsMap[setId] = labsResp;
            }
          }
        }
      }

      // Include userdispensed items with non-empty selectedField
      const dispensed2 = item.userdispensed
        .filter((d) => d.selectedField !== "")
        ?.map((d) => {
          let dispensedName = d.name;
          if (d.custom) {
            dispensedName += " (Custom)"; // Append "(Custom)" if the dispensed item is custom
          }
          return `${dispensedName} (${d.selectedField}) x${d.quantity}`;
        });

      const supplements = item.userSupplements
        .filter((s) => s.quantity > 0)
        .map(
          (s) =>
            `${s.name} ${s?.selectedField ? `(${s?.selectedField})` : ""} x${s.quantity
            } SUP ${s.custom ? "(Custom)" : ""}`
        );

      const dispensed = [...dispensed2, ...supplements];

      // Include userProcedures where checked is true or selectedField is not an empty string
      const procedures = item.userProcedures
        .filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "")
        )
        ?.map((procedure) => {
          // Check if the procedure has a custom flag
          const isCustom = procedure.custom === true;
          let procedureName = procedure.procedure_name;

          if (procedure.selectedField && procedure.selectedField !== "") {
            procedureName += ` (${procedure.selectedField})`;
          }

          if (isCustom) {
            procedureName += " (Custom)";
          }

          return procedureName;
        });

      // Filter vitals
      const vitals = item?.vitals?.some(
        (vital) =>
          vital.HR !== 0 ||
          vital.BP !== "" ||
          vital.T !== 0 ||
          vital.W !== 0 ||
          vital.RR !== 0 ||
          vital.SPO2 !== 0
      )
        ? item.vitals
        : [];

      const supplementsNew = item.userSupplements.map((s) => `${s.name}`);

      // Use the correct properties from the data structure
      return {
        key: item["_id"],
        patientName: item.patientName, // Assuming patient name isn't provided, so using PatientId
        patientId: item.PatientId,
        room: item.room || "unassigned", // Provide a fallback if room is undefined
        date: item.date,
        procedures,
        time: item.time,
        event: item.event,
        dispensed,
        usersupplements: supplementsNew,
        labs: labs.length === 0 ? [] : newLabsMap[item._id] || [],
        note: item.note || "",
        assignees: item.assignees || "",
        status: item.status,
        review: item.review,
        treatmentNotes: item.treatmentNotes,
        vitals,
      };
    }));
    return formattedData;
  };

  // Navigate date by days
  const navigateDate = useCallback((days) => {
    setCurrentDate((prev) => {
      const newDate = new Date(prev);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  }, []);

  // Handle date input change
  const handleDateChange = useCallback((e) => {
    if (e.target.value) {
      const newDate = new Date(e.target.value);
      // Add 12 hours to avoid timezone issues
      newDate.setHours(12, 0, 0, 0);
      setCurrentDate(newDate);
    }
  }, []);

  // Reset to today's date (in Phoenix timezone)
  const resetToToday = useCallback(() => {
    setCurrentDate(getPhoenixDate());
  }, []);

  // Get active patients
  const getActivePatients = async () => {

    setPatientLoader(true);

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(`/patient-profiles/active`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        setActivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching active patients:", error);
    }

    setPatientLoader(false);
  };

  // Get inactive patients
  const getInactivePatients = async () => {

    setPatientLoader(true);
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(`/patient-profiles/inActive-list`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        setInActivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
    }

    setPatientLoader(false);
  };

  // Drag and Drop Change Status
  const changeTreatmentStatus = async (treatmentId, newStatus) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.put(
        `/treatments/updateStatus/${treatmentId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatment status updated successfully");

        const updatedTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data._id) {
            return { ...treatment, status: response.data.status, room: response.data.room };
          } else {
            return treatment;
          }
        })

        setTreatments(updatedTreatments);
        setFilteredTreatments(await formatDataForTable(updatedTreatments));
      } else {
        toastifyToast.error("Failed to update treatment status");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  const moveCardStatus = async (cardId, newStatus) => {
    // Change status of the treatment with matching cardId
    const updatedTreatments = treatments?.map((treatment) => {
      if (treatment._id === cardId) {
        return { ...treatment, status: newStatus };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);
    setFilteredTreatments(await formatDataForTable(updatedTreatments));

    // Persist the status change to the server
    changeTreatmentStatus(cardId, newStatus);

  };

  // Drag and Drop change Room
  const changeTreatmentRoom = async (treatmentId, newRoom) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      if (newRoom.toLowerCase() === "pickup") {
        toastifyToast.error("Cannot assign treatment to Pickup as Room");
        return;
      }

      const response = await api.put(`/patient/order/update/${treatmentId}?fromPrepView=true`,
        {
          category: 'patientInfo',
          identifier: {
            key: "name",
            value: 'r',
          },
          updates: {
            room: newRoom,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        });


      if (response.status === 200) {
        toastifyToast.success("Treatment Room updated successfully");

        const updatedTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data._id) {
            return { ...treatment, room: response.data.room, status: response.data.status };
          } else {
            return treatment;
          }
        })

        setTreatments(updatedTreatments);
        setFilteredTreatments(await formatDataForTable(updatedTreatments));
      } else {
        toastifyToast.error("Failed to update treatment status");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  const moveCardRoom = async (treatmentId, newRoom, status = false) => {

    if (status) {
      moveCardStatus(treatmentId, newRoom);
    }
    else {
      const updatedTreatments = treatments?.map((treatment) => {
        if (treatment._id === treatmentId) {
          return { ...treatment, room: newRoom };
        }
        return treatment;
      });

      setTreatments(updatedTreatments);
      changeTreatmentRoom(treatmentId, newRoom);
    }
  };

  // Handle status filter

  const handleStatusFilter = (status) => {

    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    }
    else {
      setSelectedStatuses([...selectedStatuses, status]);
    }

  }

  const applyStatusFilter = async () => {

    const tempFilteredTreatments = await formatDataForTable(treatments);
    const newFilteredTreatments = tempFilteredTreatments.filter((item) => {
      const matchesStatus = selectedStatuses.length > 0 ? selectedStatuses.includes(item.status) : true;

      return matchesStatus;

    });

    setFilteredTreatments(newFilteredTreatments);

  }
  // Handle Search Filter
  const applySearchFilter = async () => {

    const searchTermLower = searchTerm.trim().toLowerCase();
    const tempFilteredTreatments = await formatDataForTable(treatments);

    const newFilteredTreatments = tempFilteredTreatments.filter((item) => {
      const matchesSearch = searchTermLower
        ? (item.patientName || '').toLowerCase().includes(searchTermLower) ||
        (item.procedures || []).join(", ").toLowerCase().includes(searchTermLower) ||
        (item.dispensed || []).join(", ").toLowerCase().includes(searchTermLower) ||
        (item.labs || []).join(", ").toLowerCase().includes(searchTermLower) ||
        (item.room || '').toLowerCase().includes(searchTermLower) ||
        (item.assignees || []).join(", ").toLowerCase().includes(searchTermLower)
        : true;

      return matchesSearch;
    });

    setFilteredTreatments(newFilteredTreatments);
  }

  const onSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRowKeys = treatments.map((t) => t._id); // Ensure 'treatments' is the correctly formatted data
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  // Handle delete treatment
  const deleteTreatment = async (treatmentId) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.delete(
        `/patient/order/delete/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );


      if (response.status === 200) {
        toastifyToast.success("Treatment deleted successfully");
        const filteredTreatments = treatments.filter(
          (treatment) => treatment._id !== treatmentId
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
      } else {
        toastifyToast.error("Failed to delete treatment");
      }
    } catch (error) {
      console.error("Error deleting treatment:", error);
      toastifyToast.error("Error deleting treatment");
    }
  };

  // Fetch Calendars
  const fetchCalendars = async (date) => {

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const requestPromises = response.data.map(async (item) =>
          await api.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${sessionToken}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const calendarsWithCounts = response.data.map((item, index) => ({
          ...item,
          events: results[index]?.data?.events,
          eventsCount: results[index]?.data?.events?.length
            ? results[index]?.data?.events?.length
            : 0, // Add the events count to each calendar
        }));

        calendarsWithCounts.forEach((calendar) => {
          if (calendar.name === "Scheduled") {
            setScheduledCalendar(calendar);
          } else if (calendar.name === "Complete") {
            setCompletedCalendar(calendar);
          } else if (calendar.name === "Pickup") {
            setPickupCalendar(calendar);
          } else if (calendar.name === "Arrived") {
            setArrivedCalendar(calendar);
          } else if (calendar.name === "noshow") {
            setNoShowCalendar(calendar);
          } else if (calendar.name === "cancelled") {
            setCancelledCalendar(calendar);
          }
        });

        // Set state for each calendar based on its name
        return calendarsWithCounts;

      }
      return [];

    } catch (error) {
      console.error("Error fetching calendars:", error);
      return [];
    }
  };

  const fetchCalendarEvents = async () => {
    const treatmentCalendarIds = treatments
      .filter((treatment) => treatment._id != null)
      .map((treatment) => treatment._id);



    if (treatmentCalendarIds.length > 0) {
      try {
        const response = await api.post(
          "/calendar-events-treatments",
          { treatmentCalendarIds }
        );

        setCalendarEvents(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCalendarByType = (type) => {

    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar : null;
      case "complete":
        return completedCalendar ? completedCalendar : null;
      default:
        return null;
    }
  };

  const getCalendarCIDByType = (type) => {

    const calendar = getCalendarByType(type);
    return calendar ? calendar.cid : null;
  };

  const getCalendarIDByType = (type) => {

    const calendar = getCalendarByType(type);
    return calendar ? calendar._id : null;
  };

  // Fetch All Event Details
  const fetchAllEventDetails = async (treatments, calendars) => {

    const eventsToFetch = treatments
      .filter(
        (treatment) => treatment.event && treatment.event !== "unassigned"
      )
      .map((treatment) => {
        const cid = calendars.find((calendar) => calendar.name.toLowerCase() === treatment.status)?.cid;
        if (!cid) {
          console.error(`No CID found for status: ${treatment.status}`);
        }
        return {
          eventId: treatment.event,
          cid: cid,
          treatmentId: treatment._id,
        };
      });


    if (eventsToFetch.length === 0) {
      setEventDetailsList([]);
      return;
    }

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.post(
        "/calendar-events/batch",
        { events: eventsToFetch },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setEventDetailsList(response.data);
    } catch (error) {
      console.error("Failed to fetch event details:", error);
    }
  };

  const fetchEventsNew = async (calendar, status, eventDate) => {

    setIsFetchingEvents(true);

    setCurrentCalendar(calendar._id);
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/calendar-events-all/${calendar._id}/${eventDate ?? formattedDateForAPI
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      const ignoredEvents = await api.get(
        `/ignored-calendar-events`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setIgnoredEvents(ignoredEvents.data);
      setEventsNew(response.data.events || []);
      fetchAllEvents();
      setIsEventModalVisible(true);
      setStatus(status);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }

    setIsFetchingEvents(false);
  };

  const fetchAllEvents = async () => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      const response = await api.get(
        `/all-calendar-events/${formatDateForAPI}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setAllEvents(response.data.events);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  // Change Event Time

  async function changeEventTime(eventId, status, time, note, timezone, patientName) {
    const calendar = getCalendarByType(status);

    const cid = calendar ? calendar.cid : null;

    try {
      const response = await api.post(
        `/change-event-time/${eventId}`,
        {
          newTime: time,
          patientName,
          note,
          timezone,
          cid,
          selectedDate: formattedDateForAPI,
        }
      );
      toastifyToast.success("Calendar event time changed");
      await fetchAllEventDetails(treatments, [scheduledCalendar, arrivedCalendar, cancelledCalendar, noShowCalendar, pickupCalendar, completedCalendar]);
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  async function createEventWithTime(time, status, treatmentId, timezone, note, patientName) {
    try {
      const calendar = getCalendarByType(status);
      const cid = calendar ? calendar.cid : null;

      const response = await api.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate: formattedDateForAPI },
      );
      const idc = calendar ? calendar.cid : null;
      createWatch(response.data.id, treatmentId, idc, status);
      toastifyToast.success("New event created for treatment");
      await fetchAllEventDetails(treatments, [scheduledCalendar, arrivedCalendar, cancelledCalendar, noShowCalendar, pickupCalendar, completedCalendar]);
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }
  const handleEventTimeChange = async (time, treatmentId, eventId, status, patientName, note) => {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeString = time ? time.format("hh:mm a") : "";

    if (eventId && eventId !== "unassigned") {
      changeEventTime(eventId, status, timeString, note, timezone, patientName);
    }
    if (eventId === "unassigned") {
      createEventWithTime(timeString, status, treatmentId, timezone, note, patientName);
    }

    const updatedTreatments = treatments.map((treatment) => {
      if (treatment._id === treatmentId) {
        return { ...treatment, time: timeString };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);
    setFilteredTreatments(await formatDataForTable(updatedTreatments));

  };

  // Fetch all labs on component mount
  // useEffect(() => {

  // }, []);

  // Fetch active patients on component mount
  useEffect(() => {
    fetchallLabs();
    getActivePatients();
  }, []);

  // Fetch inactive patients when showInactivePatients changes
  useEffect(() => {

    if (showInactivePatients) {
      getInactivePatients();
    } else {
      setInActivePatients([]);
    }

  }, [showInactivePatients]);

  // Apply search filter when search term changes
  useEffect(() => {

    applySearchFilter();

  }, [searchTerm]);

  // Apply status filter when selected statuses change
  useEffect(() => {

    applyStatusFilter();

  }, [selectedStatuses]);

  // Change Cell
  const splitByLastDash = (columnName) => {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const handleCellChange = (patientId, columnName, newValue, category, newData) => {
    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData,
      };
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Send the update request using axios
    api.put(`/patient/order/update/${patientId}?fromTodayView=true`, payload, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {

        const updatedTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data._id) {
            return { ...response.data };
          } else {
            return treatment;
          }
        })

        setTreatments(updatedTreatments);
        setFilteredTreatments(await formatDataForTable(updatedTreatments));

      })
      .catch((error) => {
        toastifyToast.error("Error updating the treatment");
        console.error("Error:", error);
      });
  };

  // Create Watch
  async function createWatch(eventId, treatmentId, calendarId, status) {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const responseWatch = await api.post(
      `/create-watch`,
      {
        eventId: eventId,
        treatmentId: treatmentId,
        calendarId: calendarId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
  }

  // Create Schedule for a patient
  const createSchedule = async () => {

    if (!selectedPatient || !formattedDateForAPI) {
      toastifyToast.error("Please select all fields");
      return;
    }

    setIsCreateScheduleLoading(true);
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }
      const response = await api.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatient,
          Scheduled_Date: formattedDateForAPI,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Schedule created successfully");
        const newTreatments = [
          ...treatments,
          { ...response.data, patientName: activePatients.find((p) => p["Customer ID"] === selectedPatient)["Full Name"] },
        ];

        setTreatments(newTreatments);
        setFilteredTreatments(await formatDataForTable(newTreatments));

        setSelectedPatient(null);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message ||
        "An error occurred while creating the schedule"
      );
      console.error("Error creating schedule:", error);
    }

    setIsCreateScheduleLoading(false);
  };

  // Create PickUp for a patient
  const createPickup = async () => {

    setIsCreatePickupLoading(true);

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const response = await api.post(
      `/schedule-pickup/${formattedDateForAPI}`,
      {
        dispensed: [],
        supplements: [],
        patientId: selectedPatient, // Assuming patientId is available in this scope
      },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );


    if (response.status === 200 || response.status === 201) {
      setSelectedPatient(null);
      toastifyToast.success("Pick up scheduled successfully!");
      const newTreatments = [
        ...treatments,
        { ...response.data, patientName: activePatients.find((p) => p["Customer ID"] === selectedPatient)["Full Name"] },
      ];

      setTreatments(newTreatments);
      setFilteredTreatments(await formatDataForTable(newTreatments));

    } else {
      console.error("Error sending data:", response.statusText);
      toastifyToast.error("Error scheduling pick up!");
    }

    setIsCreatePickupLoading(false);
  };

  // Fetch treatments when date changes
  useEffect(() => {

    getTreatments();


  }, [formattedDateForAPI]);


  // Call Get Treatments after Every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getTreatments(false);
    }, 15000);
    return () => clearInterval(interval);
  }, [formattedDateForAPI]);


  // Context Menu
  const ContextMenu = ({ onEdit, label }) => (
    <AntdMenu items={[
      {
        key: 'edit',
        label: label || 'Edit',
        onClick: onEdit,
      }
    ]} />
  );

  const ContextMenuTable = () => {

    const record = tableContextMenuCurrentRecord;
    const items = [
      {
        key: 'set_to_scheduled',
        label: 'Set To Scheduled',
        click: () => changeTreatmentStatus(record.key, "scheduled"),
      },
      {
        key: 'set_to_arrived',
        label: 'Set To Arrived',
        click: () => changeTreatmentStatus(record.key, "arrived"),
      },
      {
        key: 'set_to_completed',
        label: 'Set To Completed',
        click: () => changeTreatmentStatus(record.key, "complete"),
      },
      {
        key: 'final_review_complete',
        label: 'Final Review Complete',
        click: () => handleReviewChange(record.key, true)
      },
      {
        key: 'undo',
        label: 'Undo',
        click: () => { }
      },
      {
        key: 'change_date',
        label: 'Change Date',
        click: () => {
          // setTableContextMenuCurrentRecord(record);
          setSelectDataAction('date');
          setIsSelectDateModalOpen(true)
        }
      },
      {
        key: 'duplicate_to_another_date',
        label: 'Duplicate to Another Date',
        click: () => {
          // setTableContextMenuCurrentRecord(record);
          setSelectDataAction('duplicate');
          setIsSelectDateModalOpen(true)
        }
      },
      {
        key: 'delete_selected',
        label: 'Delete Selected',
        click: () => deleteTreatment(record.key)
      },
      {
        key: 'change_patient',
        label: 'Change Patient',
        click: () => {
          setSelectPatientAction('change_patient');
          // setTableContextMenuCurrentRecord(record);
          setIsSelectPatientModalOpen(true);
        }
      },
      {
        key: 'copy_to',
        label: 'Copy To',
        click: () => { },
        children: [
          {
            key: 'copy_to_add_patient',
            label: 'Add Patient',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectPatientAction('copy_to_add_patient');
              setIsSelectPatientModalOpen(true);
            }
          },
          {
            key: 'copy_to_scheduled_patient',
            label: 'Scheduled Patient',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectPatientAction('copy_to_scheduled_patient');
              setIsSelectPatientModalOpen(true);
            },
          },
        ],
      },
      {
        key: 'make_template',
        label: 'Make template',
        click: () => { }

      },
      {
        key: 'copy_from_template',
        label: 'Copy From template',
        click: () => { }

      },
      {
        key: 'merge_templates',
        label: 'Merge Templates',
        click: () => {
          // setTableContextMenuCurrentRecord(record);
          setIsMergeTemplatesModalVisible(true);
        }
      },
      {
        key: 'move_to_no_show',
        label: 'Move to No Show',
        click: () => { }

      },
      {
        key: 'move_to_cancel',
        label: 'Move to Cancel',
        click: () => { }

      }

    ];


    return (
      <AntdMenu>
        <span className="text-xs font-semibold">Action</span>
        {items.map((item) => {
          return (

            item.key === 'copy_to' ?
              <AntdMenu.SubMenu key={item.key} title={item.label}>
                {item.children.map((child) => {
                  return <AntdMenu.Item key={child.key} onClick={child.click}>
                    {child.label}
                  </AntdMenu.Item>
                })}
              </AntdMenu.SubMenu>
              :
              item.key === 'make_template' ?
                <AntdMenu.Item key={item.key}>
                  <ManageTreatmentTemplates
                    showToast={(message, type) => toast(message, { type })}
                    from="tdv"
                    contextMenu={{ data: { key: record ? record.key : null } }}
                  />
                </AntdMenu.Item>
                :
                item.key === 'copy_from_template' ?
                  <AntdMenu.Item key={item.key}>
                    <TreatmentTemplates
                      setTemplate={setTemplate}
                      patient={record ? record.patientId : null}
                      from="tdv"
                      contextMenu={{
                        data: record ? {
                          key: record.key,
                          PatientId: record.patientId,
                          review: record.review,
                        } :
                          {
                            key: null,
                            PatientId: null,
                            review: false,
                          }
                      }}
                      activePatient={activePatients}
                      treatments={treatments}
                      setTreatments={setTreatments}
                    />
                  </AntdMenu.Item>
                  :
                  <AntdMenu.Item key={item.key} onClick={item.click}>
                    {item.label}
                  </AntdMenu.Item>
          )
        })}



      </AntdMenu>
    );
  }

  // LPDN/S/V Modals

  const handleNextLabs = () => {
    handleOpenProcedureModal(currentPatientIdForModal, recordForNotes);

    setLabsModalOpen(false);
  };

  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };

  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(formattedDateForAPI);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };
  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(formattedDateForAPI).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };

  const handleRefreshLabsAndTubes = async () => {
    const scrollTop = containerRef.current.scrollTop;
    setIsLabsRefreshLoading(true);
    try {
      // Assuming orderId is available in the component's state or props
      const orderId = currentPatientLabs.orderId;

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      // Fetch new labs data
      const labsResponse = await api.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      // Function to sort labs if needed
      const sortLabs = (labs) => {
        // Your sorting logic here. Example:
        if (!selectRedrawnLabs) {
          return labs.sort((a, b) =>
            a.checked === b.checked ? 0 : a.checked ? -1 : 1
          );
        }
        if (selectRedrawnLabs) {
          return labs.sort((a, b) =>
            a.redrawn === b.redrawn ? 0 : a.redrawn ? -1 : 1
          );
        }
      };
      // Sort labs here if necessary
      const sortedLabs = sortLabs(labsResponse.data.userLabs);

      // Fetch new tubes summary
      const tubesResponse = await api.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      // Update the state with the new data
      setCurrentPatientLabs((prevState) => {
        return {
          ...prevState,
          labs: sortedLabs,
          orderId: orderId,
        };
      });
      if (tubesResponse?.data?.TubesSummary) {
        setTubesSummary(tubesResponse?.data?.TubesSummary);
      }

      setLabsModalLoader(false);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    } catch (error) {
      console.error("Error refreshing labs and tubes:", error);
    }
    setIsLabsRefreshLoading(false);

  };

  const handleDeleteLabItem = async (labItemId) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.delete(
        `/delete/labitem/${labItemId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      // Remove the deleted item from the customItems state
      let updatedCustomItems = customItems;
      if (customItems.some((item) => item.id === labItemId)) {
        updatedCustomItems = customItems.filter(
          (item) => item.id !== labItemId
        );
        setCustomItems(updatedCustomItems);
      }

      // Now update currentPatientLabs to reflect the deletion
      setCurrentPatientLabs((prevState) => {
        const updatedLabs = prevState.labs.filter(
          (lab) => lab._id !== labItemId
        );
        return { ...prevState, labs: updatedLabs };
      });
    } catch (error) {
      console.error("Error deleting lab item:", error);
      // Optionally, handle the error (e.g., display an error message)
    }
  };

  const getCheckedLabsSummary = () => {
    const checkedLabs = currentPatientLabs.labs
      .filter((lab) => lab.checked)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedLabs;
  };

  const getCheckedRedrawnLabsSummary = () => {
    const checkedRedrawnLabs = currentPatientLabs.labs
      .filter((lab) => lab.redrawn)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedRedrawnLabs;
  };

  const handleLabsCheckboxChange = (labId, checked) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const scrollTop = containerRef.current.scrollTop;
    setCurrentPatientLabs((prevState) => {
      // Find the lab by ID to get its name
      const lab = prevState.labs.find((lab) => lab._id === labId);
      const labName = lab ? lab.test_name : "Unknown Lab";
      const labItemNumber = lab?.item_number;

      let url = ``;
      let payload = {};
      if (!selectRedrawnLabs) {
        url = `/patient/order/update/${currentPatientLabs.orderId}`;
        payload = {
          category: "patientLabs",
          updates: {
            checked: checked,
            value: labItemNumber ? `${labItemNumber}` : labName,
            type: labItemNumber ? "item_number" : "test_name",
          },
        };
      }

      if (selectRedrawnLabs) {
        url = `/patient/order/${currentPatientLabs.orderId}/lab/${labItemNumber}`;
        payload = {
          redrawn: checked,
        };
      }


      api
        .put(url, payload, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        })
        .then(async (response) => {
          // Handle the successful update

          const updatedTreatments = treatments.map((treatment) => {
            if (treatment._id === response.data._id) {
              return { ...response.data };
            } else {
              return treatment;
            }
          })

          setTreatments(updatedTreatments);
          setFilteredTreatments(await formatDataForTable(updatedTreatments));

          handleRefreshLabsAndTubes();
          // handleDataReset(selectedDate);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Update failed:", error);
        });

      // Update the labs state
      if (!selectRedrawnLabs) {
        return {
          ...prevState,
          labs: prevState.labs?.map((lab) =>
            lab._id === labId ? { ...lab, checked: checked } : lab
          ),
        };
      }
      if (selectRedrawnLabs) {
        return {
          ...prevState,
          labs: prevState.labs?.map((lab) =>
            lab._id === labId ? { ...lab, redrawn: checked } : lab
          ),
        };
      }
    });
    requestAnimationFrame(() => {
      containerRef.current.scrollTop = scrollTop;
    });
  };

  const handleCopyLabsFromPreviousTreatment = async () => {
    setLabsModalLoader(true);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      // Fetch previous treatments
      const previousTreatmentsResponse = await api.get(
        `/api/patient/${patientId}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setLabsModalLoader(false);
        toastifyToast.error(
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setLabsModalLoader(false);
        toastifyToast.error(
          "There are no treatments of the required type to copy labs from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        toastifyToast.error(
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setLabsModalLoader(false);
        return;
      }
      const previousLabs = treatment.userLabs.filter(
        (previouslab) => previouslab.checked === true
      );
      if (previousLabs.length <= 0) {
        setLabsModalLoader(false);

        toastifyToast.error(
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      const updatePromises = previousLabs.map((previouslab) => {
        const payload = {
          category: "patientLabs",
          updates: {
            checked: true,
            value: previouslab.item_number && `${previouslab.item_number}`,
            type: previouslab.item_number && "item_number",
          },
        };

        return api.put(
          `/patient/order/update/${currentPatientLabs.orderId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
      });

      Promise.all(updatePromises)
        .then((responses) => {
          const updatedLabs = responses
            .flatMap((response) => response.data.userLabs)
            .filter((lab) => lab.checked === true);

          handleRefreshLabsAndTubes();

          toastifyToast.success("Labs Copied Successfully");
        })
        .catch((error) => {

          toastifyToast.error("Failed to copy labs from previous treatment");
        });
    } catch (error) {
      toastifyToast.error("Error fetching previous treatments:");


      toastifyToast.error("Failed to fetch previous treatments");
    }

    setLabsModalLoader(false);
  };

  const handleInputChangeNew = (id, value) => {
    const updatedItems = customItems.map((item) =>
      item.id === id ? { ...item, test_name: value } : item
    );
    setCustomItems(updatedItems);
  };

  const handleAddEmptyRowNew = () => {
    setCustomItems([
      ...customItems,
      { id: Date.now(), test_name: "", checked: false, isNew: true },
    ]);
  };

  const handleSearchChangeLabs = (event) => {
    setSearchTermLabs(event.target.value);
  };

  const handleCheckboxChangeNew = (id, checked) => {
    const updatedItems = customItems.map((item) =>
      item.id === id ? { ...item, checked: checked } : item
    );
    setCustomItems(updatedItems);
    const item = customItems.find((item) => item.id === id);
    // Ensure the item exists
    if (item) {
      // Get the test_name from the item
      const { test_name, isNew } = item;
      // Now you have access to test_name and checked here

      if (checked) {
        const payload = {
          category: "patientLabs",
          newData: {
            test_name,
            checked,
            isNew,
          },
        };
        axios
          .put(
            `/patient/order/update/${currentPatientLabs.orderId}`,

            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the successful update

          })
          .catch((error) => {
            // Handle any errors

            console.error("Update failed:", error);
          });
      }
    }
  };

  const handleOpenLabsModal = async (orderId, record) => {
    setRecordForNotes(record);
    setCurrentPatientIdForModal(orderId);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      // Separate checked and unchecked labs
      let checkedLabs = [];
      let uncheckedLabs = [];
      if (!selectRedrawnLabs) {
        checkedLabs = response.data.userLabs.filter((lab) => lab.checked);
        uncheckedLabs = response.data.userLabs.filter((lab) => !lab.checked);
      }
      if (selectRedrawnLabs) {
        checkedLabs = response.data.userLabs.filter((lab) => lab.redrawn);
        uncheckedLabs = response.data.userLabs.filter((lab) => !lab.redrawn);
      }

      const responseTubes = await api.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (responseTubes?.data?.TubesSummary) {
        setTubesSummary(responseTubes?.data?.TubesSummary);
      }

      // Combine them with checked labs first
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];
      setCurrentPatientLabs({
        labs: sortedLabs,
        orderId: orderId,
        patientName: response.data.patientName,
      });
      setLabsModalOpen(true);
      setClickedTreatmentData(response.data);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenProcedureModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userProcedures = response.data.userProcedures;
      userProcedures.patientName = response.data.patientName;
      proceduresSortingFunction(contextProcedures, userProcedures);
      setCurrentProcedures(userProcedures);
      setClickedTreatmentData(response.data);
      setProcedureModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenDispenseModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userDispenses = response.data.userdispensed;

      userDispenses.patientName = response.data.patientName;
      dispensesSortingFunction(contextDispensed, userDispenses);
      setCurrentDispensedItems(userDispenses);
      setDispenseModalOpen(true);
      setClickedTreatmentData(response.data);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleNextNotes = () => {
    if (currentTreatment.status === "pickup") {
      handleOpenSupplementsModal(currentPatientIdForModal, recordForNotes);
    } else {
      handleOpenVitalsModal(currentPatientIdForModal, recordForNotes);
    }

    setTreatmentNoteModalOpen(false);
  };

  const handlePreviousNotes = () => {
    handleOpenDispenseModal(currentPatientIdForModal, recordForNotes);

    setTreatmentNoteModalOpen(false);
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  const handleOpenTreatmentNoteModal = async (treatmentNote) => {
    setCurrentPatientIdForModal(treatmentNote.key);
    //treatmentNote is basically the treatment we want to send to treatment Note
    if (treatmentNote) {
      const selectedTreatment = treatments.find(
        (treatmentFind) => treatmentFind._id === treatmentNote.key
      );

      setCurrentTreatment({
        ...treatmentNote,
        custom: selectedTreatment.custom,
        date: selectedTreatment.date.split("T")[0],
        userLabs: selectedTreatment.userLabs,
        userProcedures: selectedTreatment.userProcedures,
        userdispensed: selectedTreatment.userdispensed,
        PatientId: selectedTreatment.PatientId,
        _id: selectedTreatment._id,
      });
      setTreatmentNoteModalOpen(true);
      isTreatmentNoteEditing.current = true;
    }
  };

  const handleOpenSupplementsModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const treatmentDataResponse = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      const userSupplementsResponse = await api.get(
        `/userSupplements/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const availableSupplementsResponse = await api.get(
        "/supplements-names",
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const PatientData = activePatients.find(
        (patient) => patient["Full Name"] === record?.patientName
      );

      setPatientData(PatientData);
      setClickedTreatmentData(treatmentDataResponse.data);
      try {
        const response = await api.get(
          "/getAllSupplements"
        );

        setAllSupplements(response.data);
        const updatedSupplements = userSupplementsResponse.data.map((supp) => {
          const matchedSupplement = response.data.find(
            (supplement) => supplement.supplement_name === supp.name
          );

          if (matchedSupplement) {
            return {
              ...supp,
              frequencyOptions:
                matchedSupplement.frequency[0] === "1 dosage twice a day"
                  ? [...matchedSupplement.frequency]
                  : ["1 dosage twice a day", ...matchedSupplement.frequency],
            };
          }
          return supp;
        });

        // setSupplements(updatedSupplements);
        // const sortedItems = sortItems([...updatedSupplements]);
        setCurrentSupplements(updatedSupplements);
      } catch (error) {
        console.error("Error fetching supplements:", error);
      }

      const userSupplementsData = userSupplementsResponse.data;
      const availableSupplementsData = availableSupplementsResponse.data;

      // setCurrentSupplements(userSupplementsData);
      setAvailableSupplements(availableSupplementsData);
      setCurrentPatientIdForModal(patientId);

      setSupplementsPopup(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenVitalsModal = async (patientId, record) => {
    setRecordForNotes(record);

    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);

      response.data.vitals.patientName = response.data.patientName;
      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleReviewChange = async (orderId, isReviewed) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.put(
        `/patient/order/update-review/${orderId}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Review status updated successfully");
        const newTreatments =
          treatments.map((treatment) => {
            if (treatment._id === response.data._id) {
              return { ...treatment, review: response.data.review };
            } else {
              return treatment;
            }
          })

        setTreatments(newTreatments);
        setFilteredTreatments(await formatDataForTable(newTreatments));

        // Refetch data or update state as needed
      }
    } catch (error) {
      toastifyToast.error("Error updating review status");
      console.error("Error:", error);
    }
  };

  const handleNoteUpdate = async (key, newNote) => {
    // Construct the payload
    const payload = {
      note: newNote,
    };

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.put(
        `/patient/order/update-note/${key}?fromTodayView=true`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      ;
      isNoteEditing.current = false;
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const handleNoteFocus = () => {
    setInputWidth("auto");
    setIsTyping(true);
    isNoteEditing.current = true;
  };

  const handleNoteBlur = (e, recordKey) => {
    setInputWidth("100px");
    setIsTyping(false);
    handleNoteUpdate(recordKey, e.target.value);
  };

  // Fetch Room Options
  const fetchRoomOptions = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }
    try {
      const response = await api.get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })

      setRoomOptions(response.data[0].name);
    }
    catch (error) {
      console.error("There was an error fetching the room options:", error);
    };
  };

  // Fetch Assignee Options
  const fetchAssigneeOptions = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      setAssigneeOptions(response.data[0].name);
    }
    catch (error) {
      console.error("There was an error fetching the room options:", error);
    }
  };

  useEffect(() => {
    fetchRoomOptions();
    fetchAssigneeOptions();
  }, []);

  // Execute Bulk Actions

  const handleBulkUpdateDate = async (treatmentIds, newDate) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.put('/patient/order/bulk-update-date', {
        treatmentIds,
        newDate,
      }, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        toastifyToast.success("Treatments date updated successfully");
        const date = new Date(newDate);
        const tzFormattedDate = date.toISOString();
        const restructuringTreatments = treatments.map((treatment) => {
          if (treatmentIds.includes(treatment._id)) {
            return { ...treatment, date: tzFormattedDate };
          }
          return treatment;
        });

        const filteredTreatments = restructuringTreatments.filter(
          (treatment) => treatment.date !== tzFormattedDate
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
      } else {
        toastifyToast.error("Failed to update treatment date");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment date");
      console.error("Error:", error);
    }
  };

  const handleBulkDuplicate = async (treatmentIds, newDate) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.post('/patient/order/bulk-duplicate', {
        treatmentIds,
        newDate,
      }, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        toastifyToast.success("Treatments duplicated successfully");
        const filteredTreatments = treatments.filter((treatment) =>
          treatmentIds.includes(treatment._id)
        );

        const patientIdToNameMap = filteredTreatments.reduce((map, treatment) => {
          map[treatment.PatientId] = treatment.patientName;
          return map;
        }, {});

        const updatedResponse = response.data.treatment.map((responseItem) => {
          const patientName = patientIdToNameMap[responseItem.PatientId];
          return {
            ...responseItem,
            patientName: patientName || null,
          };
        });

        setTreatments([...treatments, ...updatedResponse]);
        setFilteredTreatments(await formatDataForTable([...treatments, ...updatedResponse]));
      } else {
        toastifyToast.error("Failed to duplicate treatments");
      }
    } catch (error) {
      toastifyToast.error("Error duplicating treatments");
      console.error("Error:", error);
    }
  };


  const handleBulkUpdate = async (newStatus) => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Call the new backend API for bulk update
    try {
      const response = await api.put(
        "/patient/order/bulk-update-status",
        {
          treatmentIds: selectedRowKeys,
          newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success(`Treatments updated to ${newStatus}`);

        const filteredTreatments = treatments.map((treatment) => {
          if (selectedRowKeys.includes(treatment._id)) {
            return { ...treatment, status: newStatus };
          }
          return treatment;
        });
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to update treatments");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatments");
      console.error("Error updating treatments:", error);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Call the new backend API for bulk delete
    try {
      const response = await api.delete(
        "/patient/order/bulk-delete",
        {
          data: { treatmentIds: selectedRowKeys },
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatments deleted successfully");
        const filteredTreatments = treatments.filter(
          (treatment) => !selectedRowKeys.includes(treatment._id)
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to delete treatments");
      }
    } catch (error) {
      toastifyToast.error("Error deleting treatments");
      console.error("Error deleting treatments:", error);
    }
  };

  const executeBulkAction = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    if (bulkAction === "Date" && bulkActionDate) {

      handleBulkUpdateDate(selectedRowKeys, bulkActionDate);
      setSelectedRowKeys([]);

    } else if (bulkAction === "Duplicate" && bulkActionDate) {
      handleBulkDuplicate(selectedRowKeys, bulkActionDate);
      setSelectedRowKeys([]);

    } else if (bulkAction === "delete") {
      // Handle bulk delete action
      await handleBulkDelete();
    } else if (bulkAction === "review") {
      if (selectedRowKeys.length === 0) {
        toastifyToast.error("No treatments selected for review");
        return;
      }
      try {
        const response = await api.put(
          "/patient/order/bulk-review",
          {
            treatmentIds: selectedRowKeys,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );

        if (response.status === 200) {
          toastifyToast.success("Treatments set to review successfully");
          const updatedTreatments = treatments.map((treatment) => {
            if (selectedRowKeys.includes(treatment._id)) {
              return { ...treatment, review: true };
            }
            return treatment;
          });
          setTreatments(updatedTreatments);
          setFilteredTreatments(await formatDataForTable(updatedTreatments));
          setSelectedRowKeys([]);
        } else {
          toastifyToast.error("Failed to review treatments");
        }
      } catch (error) {
        toastifyToast.error("Error reviewing treatments");
        console.error("Error reviewing treatments:", error);
      }
    } else {
      handleBulkUpdate(bulkAction);
    }

    setBulkAction("");
  };


  const changeScheduledPatient = (selectedValue, treatmentId) => {
    const selectedPatient = [...activePatients, ...inActivePatients].find(
      (patient) => patient["Customer ID"] === selectedValue
    );

    // setSelectedPatientName(selectedPatientNameFound);
    if (!selectedPatient) {
      toastifyToast.error("Please select a patient");
    } else {

      handleCellChange(
        treatmentId,
        "PatientId",
        selectedPatient["Customer ID"],
        "patientInfo"
      );
      setIsSelectPatientModalOpen(false);
    }
  };

  const copyTreatmentToNewPatient = async (patientId, treatmentId) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }


    try {
      const response = await api.post(
        "/patient/copy",
        {
          patientId,
          treatmentId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");
        const selectedPatient = [...activePatients, ...inActivePatients].filter(
          (item) => item["Customer ID"] === patientId
        );
        const filteredTreatments = [
          ...treatments,
          {
            ...response.data.newTreatment,
            patientName: selectedPatient[0]["Full Name"],
          },
        ];
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setIsSelectPatientModalOpen(false);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  const copyTreatmentToScheduledPatient = async (patientId, treatmentId) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {

      const modifyTreatmentId = treatments.find(
        (treatment) => treatment.PatientId === patientId
      )?._id;

      if (!modifyTreatmentId) {
        return;
      }

      const response = await api.post(
        "/todaypatientcopy",
        {
          patientId,
          treatmentId: treatmentId,
          treatmentIdToModify: modifyTreatmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");
        const selectedPatient = [...activePatients, ...inActivePatients].filter(
          (item) => item["Customer ID"] === patientId
        );
        const filteredTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data.newTreatmentData._id) {
            return {
              ...response.data.newTreatmentData,
              patientName: selectedPatient[0]["Full Name"],
            };
          }
          return treatment;
        });
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setIsSelectPatientModalOpen(false);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  // Table columns
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={
            selectedRowKeys.length > 0 &&
            selectedRowKeys.length < treatments.length
          }
          onChange={onSelectAllChange}
          checked={
            selectedRowKeys.length === treatments.length &&
            treatments.length > 0
          }
        />
      ),
      dataIndex: "key",
      key: "select",
      render: (text, record) => (
        <Checkbox

          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedRowKeys((prev) =>
              checked
                ? [...prev, record.key]
                : prev.filter((key) => key !== record.key)
            );
          }}
        />
      ),
      onCell: (record) => ({
        style: {
          backgroundColor: selectedRowKeys.includes(record.key)
            ? "#f7f7f7"
            : "inherit",
        },
      }),
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      render: (text, record) => (

        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{text}</span>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${record.patientId}`}
            aria-haspopup="true"
            sx={{
              color: blue[700],
              ":hover": {
                color: blue[400],
              },
            }}
            onClick={(e) => setMenuAnchor({ id: record.patientId, element: e.currentTarget })}
          >
            <East sx={{ fontSize: 18 }} />
          </IconButton>
          <Menu
            id={`menu-${record.patientId}`}
            anchorEl={menuAnchor && menuAnchor.id === record.patientId ? menuAnchor.element : null}
            keepMounted
            open={menuAnchor && menuAnchor.id === record.patientId}
            onClose={() => setMenuAnchor(null)}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              },
            }}
          >
            <MenuItem
              component="a"
              onClick={() => {
                setMenuAnchor(null);
                if (record.patientId) {
                  navigate(`/treatmentviewdisplay/${record.patientId}`);
                }
              }}
            >
              Treatment View
            </MenuItem>
            <MenuItem
              component="a"
              onClick={() => {
                setMenuAnchor(null);
                if (record.patientId) {
                  navigate(`/auditview/${record.patientId}/${record.key}`);
                }
              }}
            >
              Prep View
            </MenuItem>
          </Menu>
        </div>
      ),
    },
    {
      title: "LPDV",
      key: "lpdv",
      render: (record) => {
        return (

          <div className="flex justify-center items-center" style={{ columnGap: '2px' }}>

            <Badge className="cursor-pointer" key={'L'} count={'L'} style={{ backgroundColor: record.labs && record.labs.length > 0 ? "#b9f6ca" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenLabsModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'P'} count={'P'} style={{ backgroundColor: record.procedures && record.procedures.length > 0 ? "#b9f6ca" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenProcedureModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'D'} count={'D'} style={{ backgroundColor: record.dispensed && record.dispensed.filter((item) => !item.endsWith("SUP ")).length > 0 ? "#b9f6ca" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenDispenseModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'N'} count={'N'} style={{ backgroundColor: record.treatmentNotes && record.treatmentNotes.includes("Notes by:") ? "#81e984" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenTreatmentNoteModal(record)} />
            {record.status === 'pickup' ?
              <Badge className="cursor-pointer" key={'S'} count={'S'} style={{ backgroundColor: record.dispensed && record?.dispensed?.length > 0 && record?.dispensed?.some((item) => item.endsWith("SUP ")) ? "#00cc00" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenSupplementsModal(record.key, record)} />
              :
              <Badge className="cursor-pointer" key={'V'} count={'V'} style={{ backgroundColor: record.vitals && record?.vitals?.length > 0 ? "#81e984" : '#d9d9d9', color: '#000' }} onClick={() => handleOpenVitalsModal(record.key, record)} />
            }
          </div>

        );
      },
    },
    {
      title: "Final",
      dataIndex: "review",
      key: "review",
      render: (review, record) => (

        <Checkbox
          className="w-full"
          checked={record.review}
          onChange={(e) => {
            handleReviewChange(record.key, e.target.checked);
          }}
        />
      ),
    },
    {
      title: "RM/AS",
      dataIndex: "RM/AS",
      width: 200,
      key: "RM/AS",
      render: (room, record) => (

        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center flex-col">
            <p className="text-[17px]">{record.room}</p>
            <p>{record.assignees.length > 0 && record.assignees.join(", ")}</p>
          </div>
          <EditRoomModal record={record} assigneeOptions={assigneeOptions} roomOptions={roomOptions} onCellChange={handleCellChange} />
        </div>
      ),
    },
    {
      title: "Procedures",
      dataIndex: "procedures",
      key: "procedures",
      render: (procedures, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };


        const procedureElements = procedures ? procedures.map((procedure, index) => {
          const isComped = ivPrepData.procedures.some(
            (p) =>
              procedure.includes(p.name) &&
              p.comped &&
              !["Red Light Bed", "Hydrogen In"].includes(procedure)
          );
          const isCustom = procedure.includes("(Custom)");
          return (
            <span
              key={index}
              style={{
                color: isComped ? "orange" : isCustom ? "blue" : "inherit",
              }}
            >
              {index > 0 ? ", " : ""}
              {procedure.replace(" (Custom)", "")}
            </span>
          );
        }) : [];

        return (
          <Dropdown
            overlay={ContextMenu({
              onEdit: () => handleOpenProcedureModal(record.key, record),
              label: 'Edit Procedures'
            })}
            trigger={['contextMenu']}
          >
            <div className="flex justify-start items-center w-full">
              {procedureElements && procedureElements.length > 0 ?
                <div>{procedureElements}</div>
                :
                <div className="flex justify-start items-center w-full">
                  No Procedures
                </div>
              }
            </div>
          </Dropdown>
        );
      },
    },
    {
      title: "Dispensed",
      dataIndex: "dispensed",
      key: "dispensed",
      render: (dispensed, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };

        const extractName = (str) => {
          const match = str.match(/^[^(]+/);
          return match ? match[0].trim() : str.trim();
        };

        // Generate dispensed items with conditional styling
        const dispensedElements = dispensed ? dispensed.map((item, index) => {
          const isComped = ivPrepData.dispensed.some(
            (d) => extractName(d.name) === extractName(item) && d.comped
          );
          item = item.replace("SUP", "");
          const isCustom = item.includes("(Custom)");
          return (
            <span
              key={index}
              style={{
                color: isComped ? "orange" : isCustom ? "blue" : "inherit",
              }}
            >
              {index > 0 ? ", " : ""}
              {item.replace(" (Custom)", "")}
            </span>
          );
        }) : [];
        return (
          <Dropdown
            overlay={ContextMenu({
              onEdit: () => handleOpenDispenseModal(record.key, record),
              label: 'Edit Dispensed'
            })}
            trigger={['contextMenu']}
          >
            <div className="flex justify-start items-center w-full">
              {dispensedElements && dispensedElements.length > 0 ?
                <div>{dispensedElements}</div>
                :
                <div className="flex justify-start items-center w-full">
                  No Dispensed
                </div>
              }
            </div>
          </Dropdown>
        );
      },
    },
    {
      title: "Labs",
      className: "labs-column",
      onCell: () => ({
        className: "labs-column-cell",
      }),
      dataIndex: "labs",
      key: "labs",
      render: (labs, record) => {
        // Generate lab tests elements with conditional styling
        const labTestsElements = labs ? labs.map((lab, index) => {
          const isCustom = lab.includes("(Custom)");
          return (
            <span key={index} style={{ color: isCustom ? "blue" : "inherit" }}>
              {lab.replace(" (Custom)", "")}
              {index < labs.length - 1 ? ", " : ""}
            </span>
          );
        }) : [];

        return (
          <Dropdown
            overlay={ContextMenu({
              onEdit: () => handleOpenLabsModal(record.key, record),
              label: 'Edit Labs'
            })}
            trigger={['contextMenu']}
          >
            <div className="flex justify-start items-center w-full">

              {labTestsElements && labTestsElements.length > 0 ?
                <div>{labTestsElements}</div>
                :
                <div className="flex justify-start items-center w-full">
                  No Labs
                </div>
              }


            </div>
          </Dropdown>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (note, record) => (

        <div className="flex justify-center items-center w-full">
          <Input
            key={record.key}
            defaultValue={note}
            style={{ minWidth: "100px", width: inputWidth }}
            onFocus={handleNoteFocus}
            onBlur={(e) => handleNoteBlur(e, record.key)}
          />
        </div>
      ),
    },

  ];

  return (
    <>
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="flex-1 overflow-auto">
          <header className="bg-white shadow p-4 flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-4 mb-4 md:mb-0">
              <h1 className="text-2xl font-bold mb-0">Today View</h1>
              <div className="flex items-center space-x-2">
                <ChevronLeft
                  className="h-4 w-4 cursor-pointer hover:text-gray-600 transition-colors"
                  onClick={() => navigateDate(-1)}
                />
                <span>{displayDate}</span>
                <ChevronRight
                  className="h-4 w-4 cursor-pointer hover:text-gray-600 transition-colors"
                  onClick={() => navigateDate(1)}
                />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Button
                onClick={resetToToday}
                className="bg-black text-white hover:bg-gray-800 transition-colors"
                disabled={isLoading}
              >
                Today
              </Button>
              <Input
                type="date"
                value={formattedDateForInput}
                onChange={handleDateChange}
                disabled={isLoading}
                className="cursor-pointer"
              />
            </div>
          </header>
          <div className="p-6 space-y-6">
            <StyledSelect
              className="w-full md:w-[200px]"
              placeholder="Select patient"
              onChange={(value) => setSelectedPatient(value)}
              showSearch
              loading={patientLoader}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={selectedPatient}
            >
              {activePatients?.map((patient) => (
                <Option key={patient._id} value={patient["Customer ID"]}>
                  {patient["Full Name"]}
                </Option>
              ))}
              {showInactivePatients &&
                inActivePatients?.map((patient) => (
                  <Option
                    key={patient._id}
                    value={patient["Customer ID"]}
                  >
                    {patient["Full Name"]}
                  </Option>
                ))}
            </StyledSelect>

            <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
              <div className="space-x-2">
                <Button className="bg-black text-white" onClick={() => setIsScheduleModalVisible(true)} loading={false}>Quick Schedule</Button>
                {selectedPatient && <Button className="bg-black text-white" onClick={createSchedule} loading={isCreateScheduleLoading}>Schedule</Button>}
                {selectedPatient && <Button className="bg-black text-white" onClick={createPickup} loading={isCreatePickupLoading}>Pick Up</Button>}
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" id="showInactive" checked={showInactivePatients}
                    onChange={(e) => setShowInactivePatients(e.target.checked)} />
                  <label htmlFor="showInactive">Show inactive patients</label>

                </div>

                <ConversationsModal patientIDs={patientIDs} unreadMessagesCount={unreadMessagesCount} />
                <Button className="bg-white text-black" onClick={() => fetchEventsNew(scheduledCalendar, "scheduled")} loading={isFetchingEvents}>Show Events</Button>
                <Button className={`${byRoom ? 'bg-black text-white' : 'bg-white text-black'}`} onClick={() => setByRoom((prev) => !prev)}>By Room</Button>
              </div>
            </div>


            {!byRoom ? <DndProvider backend={HTML5Backend}>
              <Row gutter={[16, 16]}>
                {statuses.map((status) => (
                  <StatusColumn
                    key={status}
                    status={status}
                    treatments={treatments
                      .filter((t) => t.status === status)
                    }
                    calendarEvents={calendarEvents}
                    moveCardStatus={moveCardStatus}
                    calendar={getCalendarByType(status)}
                    ignoredEvents={ignoredEvents}
                    fetchEventsNew={fetchEventsNew}
                    isLoading={isLoading}
                    ivPrepByTreatment={ivPrepByTreatment}
                    eventDetailsList={eventDetailsList}
                    deleteTreatment={deleteTreatment}
                    handleEventTimeChange={handleEventTimeChange}
                  />

                ))}
              </Row>
            </DndProvider>
              :
              <DndProvider backend={HTML5Backend}>
                <div className="w-auto space-y-6">
                  <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                    <RoomColumnUnassigned
                      key={'scheduled'}
                      room={'scheduled'}
                      treatments={treatments.filter((t) => t.status === 'scheduled' && t.room === 'unassigned')}
                      moveCardRoom={moveCardRoom}
                      ivPrepByTreatment={ivPrepByTreatment}
                      isLoading={isLoading}
                      eventDetailsList={eventDetailsList}
                      deleteTreatment={deleteTreatment}
                      handleEventTimeChange={handleEventTimeChange}
                    />
                    <RoomColumnUnassigned
                      key={'arrived'}
                      room={'arrived'}
                      treatments={treatments.filter((t) => t.status === 'arrived' && t.room === 'unassigned')}
                      moveCardRoom={moveCardRoom}
                      ivPrepByTreatment={ivPrepByTreatment}
                      isLoading={isLoading}
                      eventDetailsList={eventDetailsList}
                      deleteTreatment={deleteTreatment}
                      handleEventTimeChange={handleEventTimeChange}
                    />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 xl:grid-cols-9">
                      <RoomColumnUnassigned
                        key={'complete'}
                        room={'complete'}
                        treatments={treatments.filter((t) => t.status === 'complete' && t.room === 'unassigned')}
                        moveCardRoom={moveCardRoom}
                        ivPrepByTreatment={ivPrepByTreatment}
                        isLoading={isLoading}
                        eventDetailsList={eventDetailsList}
                        deleteTreatment={deleteTreatment}
                        handleEventTimeChange={handleEventTimeChange}
                        fullHeight={true}
                      />
                      {rooms.map((room) => (
                        <RoomColumn
                          key={room}
                          room={room}
                          ivPrepByTreatment={ivPrepByTreatment}
                          treatments={room !== 'Pickup' ? treatments.filter((t) => t.room === room && t.status !== 'pickup') : treatments.filter((t) => t.status === 'pickup')}
                          moveCardRoom={moveCardRoom}
                          isLoading={isLoading}
                          eventDetailsList={eventDetailsList}
                          deleteTreatment={deleteTreatment}
                          handleEventTimeChange={handleEventTimeChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </DndProvider>
            }

            <Card
              title="Patients"
              extra={
                <Space>
                  <Input placeholder="Search..." style={{ width: 200 }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  <Button icon={<SearchOutlined />} />
                </Space>
              }
            >

              <div className="flex flex-col md:flex-row md:justify-between gap-4 items-center mb-4">
                <div className="flex items-center gap-2">
                  <p>Actions</p>
                  <Select
                    placeholder="Bulk Actions"
                    style={{ width: 200 }}
                    onChange={(value) => setBulkAction(value)}
                    value={bulkAction}
                  >
                    <Option value="scheduled">Set to Scheduled</Option>
                    <Option value="arrived">Set to Arrived</Option>
                    <Option value="complete">Set to Complete</Option>
                    <Option value="review">Final review complete</Option>
                    <Option value="Date">Change Date</Option>
                    <Option value="Duplicate">Duplicate to another Date</Option>
                    <Option value="delete">Delete Selected</Option>
                  </Select>
                  {(bulkAction === "Date" || bulkAction === "Duplicate") &&

                    <DatePicker
                      style={{ width: 200 }}
                      onChange={(date, datestring) => setBulkActionDate(datestring)}
                    />

                  }
                  <Button
                    onClick={executeBulkAction}
                    disabled={selectedRowKeys.length === 0}
                  >
                    Apply
                  </Button>
                </div>

                <div className="flex items-center gap-2">
                  {statuses.map((status) => (
                    <Badge key={status} count={status.charAt(0).toUpperCase() + status.slice(1)} className="cursor-pointer" style={{ backgroundColor: selectedStatuses.includes(status) ? 'rgb(156 163 175)' : 'rgb(229 231 235)', color: '#000', cursor: 'pointer' }} onClick={() => handleStatusFilter(status)} />
                  ))}
                </div>
              </div>
              <Dropdown
                overlay={ContextMenuTable()}
                trigger={['contextMenu']}
              >
                <div>
                  <Table
                    className="overflow-auto"
                    columns={columns}
                    dataSource={filteredTreatments}
                    size="small"
                    pagination={{ pageSize: 8 }}
                    rowKey="name"
                    loading={{ indicator: <ClipLoader color="#000" size={25} />, spinning: isLoading }}
                    responsive
                    onRow={(record) => ({
                      onContextMenu: (event) => {
                        event.preventDefault();
                        event.data = record;
                        setTableContextMenuCurrentRecord(record);
                      },
                    })}
                  />
                </div>
              </Dropdown>
            </Card>

          </div>
        </div>

      </div>

      <EventsModal
        open={isEventModalVisible}
        treatments={treatments}
        events={eventsNew}
        ignoredEvents={ignoredEvents}
        patients={activePatients}
        currentCalendar={currentCalendar}
        onClose={() => setIsEventModalVisible(false)}
        handleCellChange={handleCellChange}
        status={status}
        toast={toast}
        fetchTreatments={getTreatments}
        formattedDate={formatDateForAPI}
        setStatus={setStatus}
        fetchEventsNew={fetchEventsNew}
        scheduledCalendar={scheduledCalendar}
        arrivedCalendar={arrivedCalendar}
        pickupCalendar={pickupCalendar}
        completedCalendar={completedCalendar}
        allEvents={allEvents}
      />
      <ToastContainer />
      <ScheduleTreatmentModal
        isOpen={isScheduleModalVisible}
        closeModal={() => setIsScheduleModalVisible(false)}
        patientProfiles={[...activePatients, ...(showInactivePatients ? inActivePatients : [])]}
        createWatch={createWatch}
        getCalendarCidByType={getCalendarCIDByType}
        getCalendarIdByType={getCalendarIDByType}
        treatments={treatments}
        setTreatments={setTreatments}
      />
      <Dialog
        open={labsModalOpen}
        onClose={() => setLabsModalOpen(false)}
        aria-labelledby="labs-modal-title"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            padding: "24px",
          }}
        >
          {toast && (
            <CustomToast
              message={toastifyToast.message}
              variant={toastifyToast.variant}
            />
          )}
          <div>
            <div className="flex justify-between mb-[16px]">
              {" "}
              <span className="labstitleStyling">
                <IconButton disabled={true}>
                  <ChevronLeft />
                </IconButton>
                Patient Labs
                <IconButton onClick={handleNextLabs}>
                  <ChevronRight />
                </IconButton>
                <MuiTooltip
                  title={
                    <h1 className="text-sm">
                      Copy Labs From Previous Treatment
                    </h1>
                  }
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      if (clickedTreatmentData.review) {
                        return message.warning(
                          "This treatment is finalized so can't make any changes in it"
                        );
                      } else {
                        handleCopyLabsFromPreviousTreatment();
                      }
                    }}
                    sx={{ ml: "10px" }}
                  >
                    <ContentCopyRounded sx={{ fontSize: 30 }} />
                  </IconButton>
                </MuiTooltip>
              </span>{" "}
              <IconButton onClick={handleRefreshLabsAndTubes}>
                <RefreshIcon
                  style={{
                    fontSize: 30,
                    animation: isLabsRefreshLoading
                      ? "spin 0.5s linear infinite"
                      : "none",
                  }}
                />
              </IconButton>
            </div>
          </div>
          {labsModalLoader && (
            <div className="loader-backdrop">
              <BounceLoader color="#000" />
            </div>
          )}

          <div>
            <div
              className={`${styles.activeStylingD} mb-[16px] flex items-center justify-between`}
            >
              {currentPatientLabs?.patientName}
              <div>
                <AntdCheckbox
                  name="redrawnLabs"
                  checked={selectRedrawnLabs}
                  onChange={(e) => setSelectRedrawnLabs(e.target.checked)}
                >
                  Select Redrawn Labs
                </AntdCheckbox>
              </div>
            </div>
            <div className="flex">
              <div className="flex">
                {Object.entries(tubesSummary).map(
                  ([tubeType, quantity]) => (
                    <div key={tubeType} className="p-1 rounded">
                      <span
                        className="text-white rounded p-1"
                        style={{
                          backgroundColor: tubeColors[tubeType] || "black",
                        }}
                      >
                        {tubeType} ({quantity})
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search labs..."
              value={searchTermLabs}
              onChange={handleSearchChangeLabs}
              sx={{ marginBottom: "16px" }}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }} // optional, for emphasis
            >
              Active Labs: {getCheckedLabsSummary()}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }} // optional, for emphasis
            >
              Redrawn: {getCheckedRedrawnLabsSummary()}
            </Typography>

            <div className="outer-labs-container">
              <div className="inner-labs-container py-3" ref={containerRef}>
                <div className="w-1/2 space-y-3">
                  {firstColumnItems.map((lab) => (
                    <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} - (${lab.item_number})`}
                      />
                      <Checkbox
                        checked={
                          (!selectRedrawnLabs && lab.checked) ||
                          (selectRedrawnLabs && lab.redrawn)
                        }
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleLabsCheckboxChange(
                              lab._id,
                              e.target.checked
                            );
                          }
                        }}
                      />
                      {lab.custom && (
                        <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </div>
                <div className="w-1/2 space-y-3">
                  {secondColumnItems.map((lab) => (
                    <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} - (${lab.item_number})`}
                      />
                      <Checkbox
                        checked={
                          (!selectRedrawnLabs && lab.checked) ||
                          (selectRedrawnLabs && lab.redrawn)
                        }
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleLabsCheckboxChange(
                              lab._id,
                              e.target.checked
                            );
                          }
                        }}
                      />
                      {lab.custom && (
                        <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </div>
              </div>
            </div>
            {customItems.map((item) => (
              <div key={item.id} className="w-[50%]">
                <ListItem>
                  <input
                    className="dispensedTextInputHere"
                    value={item.test_name}
                    onChange={(e) =>
                      handleInputChangeNew(item.id, e.target.value)
                    }
                    placeholder="Custom item"
                  />
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckboxChangeNew(item.id, e.target.checked)
                    }
                  />
                </ListItem>
              </div>
            ))}
            <button
              className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={() => {
                if (clickedTreatmentData.review) {
                  return message.warning(
                    "This treatment is finalized so can't make any changes in it"
                  );
                } else {
                  handleAddEmptyRowNew();
                }
              }}
            >
              Add Custom Item
            </button>
          </div>
        </Box>
      </Dialog>
      <ProcedureModal
        open={procedureModalOpen}
        procedures={currentProcedures}
        onClose={() => setProcedureModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        handleOpenLabsModal={handleOpenLabsModal}
        handleOpenDispenseModal={handleOpenDispenseModal}
        recordForNotes={recordForNotes}
        selectedDate={formattedDateForAPI}
        clickedTreatmentData={clickedTreatmentData}
      />

      <SupplementsModalPopup
        open={supplementsPopup}
        onClose={() => setSupplementsPopup(false)}
        supplements={currentSupplements}
        activePatients={activePatients}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        patientData={patientData}
        handleCellChange={handleCellChange}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        recordForNotes={recordForNotes}
        selectedDate={formattedDateForAPI}
        allSupplements={allSupplements}
        toastifyToast={toastifyToast}
        clickedTreatmentData={clickedTreatmentData}
      />

      <DispenseModal
        open={dispenseModalOpen}
        dispensedItems={currentDispensedItems}
        onClose={() => setDispenseModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenProcedureModal={handleOpenProcedureModal}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={formattedDateForAPI}
        clickedTreatmentData={clickedTreatmentData}
      />

      <VitalsModal
        open={vitalsModalOpen}
        VitalsItems={currentVitalsItems}
        onClose={() => setVitalsModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={formattedDateForInput}
      />
      <AntdModal
        visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
        onCancel={() => setTreatmentNoteModalOpen(false)} // Changed to the corresponding close handler function for treatment notes
        footer={null}
        width="80vw" // Set the width to 80% of the viewport width
        styles={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <span className="vitalstitleStyling">
          {" "}
          <IconButton onClick={handlePreviousNotes}>
            <ChevronLeft />
          </IconButton>
          Treatment Notes
          <IconButton onClick={handleNextNotes}>
            <ChevronRight />
          </IconButton>
        </span>

        <TreatmentNotes
          treatment={currentTreatment}
          isTreatmentNoteEditing={isTreatmentNoteEditing.current}
          setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
        />
      </AntdModal>
      <MergeTemplatesModal
        orderId={tableContextMenuCurrentRecord ? tableContextMenuCurrentRecord.key : ""}
        visible={tableContextMenuCurrentRecord && isMergeTemplatesModalVisible}
        onClose={() => setIsMergeTemplatesModalVisible(false)}
      />
      {/* IF key === copy_to_scheduled_patient, only pass the patients that are in the treatments so map the patients Customer ID with treatment.patientId */}
      <SelectPatientModal
        visible={isSelectPatientModalOpen}
        patients={selectPatientAction === 'copy_to_scheduled_patient' ? [...activePatients, ...inActivePatients].filter((patient) => treatments.map((treatment) => treatment.PatientId).includes(patient["Customer ID"])) : [...activePatients, ...(showInactivePatients ? inActivePatients : [])]}
        onClose={() => setIsSelectPatientModalOpen(false)}
        treatment={tableContextMenuCurrentRecord}
        onSelect={selectPatientAction === 'change_patient' ? changeScheduledPatient : selectPatientAction === 'copy_to_add_patient' ? copyTreatmentToNewPatient : selectPatientAction === 'copy_to_scheduled_patient' ? copyTreatmentToScheduledPatient : null}

      />
      <SelectDateModal
        visible={selectDataAction && isSelectDateModalOpen}
        onClose={() => setIsSelectDateModalOpen(false)}
        treatment={tableContextMenuCurrentRecord}
        onSelect={selectDataAction === 'date' ? handleBulkUpdateDate : selectDataAction === 'duplicate' ? handleBulkDuplicate : null}
      />

    </>

  )
};

export default TodayView;
