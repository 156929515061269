import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import {
  ArrowBack,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { colors, IconButton,Checkbox } from "@mui/material";
import { Button, Modal, Form, Input, Select, message } from "antd";
import axios from "axios";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FormControlLabel from '@mui/material/FormControlLabel';

import React, { useState, useEffect } from "react";
const dayjs = require('dayjs');

const TasksTab = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [frequencyFilter, setFrequencyFilter] = useState(null);
  const [description, setDescription] = useState("");
  const [allTasks, setAllTasks] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [assigneesOptions, setAssigneesOptions] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [completeAll, setCompleteAll] = useState(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDescription("");
    setSelectedFrequency("")
    form.resetFields();
    setIsUpdating(false)
    setTaskId("")
  };

  const toggleViewAll = () => {
    setViewAll(!viewAll);
    setFrequencyFilter(null);
  };

  const frequencyOptions = [
    { key: "everyday", value: "everyday", label: "Every Day" },
    { key: "monday", value: 1, label: "Monday" },
    { key: "tuesday", value: 2, label: "Tuesday" },
    { key: "wednesday", value: 3, label: "Wednesday" },
    { key: "thursday", value: 4, label: "Thursday" },
    { key: "friday", value: 5, label: "Friday" },
    { key: "saturday", value: 6, label: "Saturday" },
    { key: "sunday", value: 0, label: "Sunday" },
  ];

  const handleSelectFrequency = (value) => {
    if (value.includes('everyday')) {
      setSelectedFrequency(['everyday']);
      form.setFieldsValue({ frequency: 'everyday' });
    }
    else { 
      setSelectedFrequency(value);
      form.setFieldsValue({ frequency: value });
    }

  };
  const handleSelectAssignee = (value) => {
    setSelectedAssignee(value);
  };
  const handleFrequencyFilter = (value) => {
    setFrequencyFilter(value);
  };
  const handleChangeCompleteAll = (event) => {
    if (event.target.checked) {
      updateAllStatus(event.target.checked)
    }
    else {
      setCompleteAll(false)
    }
  }
 
  const fetchAllTasks = async () => {
    const token = localStorage.getItem("sessionToken");

    try {
      const response = await axios.get(
        "/all-reminder-tasks",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setAllTasks(response.data);
      } 
    } catch (err) {
      message.error(err.message); 
    }
  };

  useEffect(() => {
    fetchAllTasks();
    fetchAssignees()
    const interval = setInterval(() => {
      fetchAllTasks(); 
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const addTask = async () => {
    if (!description || !selectedFrequency) {
      return message.error("Please enter data for all fields")
    }
    const payload = {
      description,
      frequency: selectedFrequency,
    };
    const token = localStorage.getItem("sessionToken");

    const response = await axios.post(
      "/create-reminder-task",
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status === 200 || response.status === 201) {
      message.success("Task Added Successfully");
      setAllTasks([...allTasks,response.data.tasks]);
      handleCancel();
      setDescription("");
    }
  };

  const deleteTask = async (taskId) => {
    
    const token = localStorage.getItem("sessionToken");

    const response = await axios.delete(
      `/delete-task/${taskId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status === 200 || response.status === 201) {
      message.success("Task Deleted Successfully");
      setAllTasks((prevState) => {
        const filteredTasks = prevState.filter(task => task._id !== taskId)
        return filteredTasks
      });
    }
  };

  const updateTask = async (taskId) => {
  
     const payload = {
      description,
      frequency: selectedFrequency,
      };
    
    const token = localStorage.getItem("sessionToken");

    const response = await axios.put(
      `/update-task/${taskId}`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status === 200 || response.status === 201) {
      message.success("Task Updated Successfully");
      const updatedTasks = allTasks.map((task) => {
        if (task._id === response.data.updatedTaskNew._id) {
          return {...response.data.updatedTaskNew}
        }
        else return task
      })
      setAllTasks(updatedTasks);
      handleCancel();
    }
  };

  
  const updateStatus = async (taskId, done) => {
    let newErrors = {};
    if (!selectedAssignee && done) {
      newErrors.assigneeError = 'Assignee is required!';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {

      const token = localStorage.getItem("sessionToken");
      const response = await axios.put(
        `/update-task-status/${taskId}`, {
        done,
        signedOffBy: selectedAssignee
      },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Task Updated Successfully");
        const updatedDoneTasks = allTasks.map((task) => {
          if (task._id === taskId) {
            return { ...task, done: done,signedOffBy:selectedAssignee,due:response.data.updatedTask.due }
          }
          else return task
        })
        setAllTasks(updatedDoneTasks);
      }
    }
  };

  const updateAllStatus = async (done) => {
    let newErrors = {};
    if (!selectedAssignee && done) {
      newErrors.assigneeError = 'Assignee is required!';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCompleteAll(true)
      const token = localStorage.getItem("sessionToken");
      const response = await axios.put(
        `/update-alltasks-status/${taskId}`, {
        done,
        signedOffBy: selectedAssignee
      },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Task Updated Successfully");
        setCompleteAll(false)
        
        setAllTasks(response.data.allTasks);
      }
    }
  };

  const filteredTasks = allTasks.length>0 && allTasks.filter(
    (task) => !frequencyFilter || task.frequency.includes(frequencyFilter)
  );

  const filteredDueTasks = allTasks.length>0 && allTasks.filter(
    (task) =>  task.due === true
  );

  const currentDay = dayjs().day()
  
  const fetchAssignees = async () => {
    const assigneesResponse = await axios.get('/get-Assignee-options', {
      headers: {
        'Authorization':`Bearer ${localStorage.getItem('sessionToken')}`
      }
    })
    const assignees = assigneesResponse.data[0];
    const structuredAssigneesOption=assignees?.name.map((assignee,index) => {
        return {key:index, value:assignee,label:assignee}
    })
    setAssigneesOptions(structuredAssigneesOption);
  } 

  return (
    <>
      <div
        className={`fixed z-50 bottom-0 right-14 ${
          isOpen ? "mx-10" : "mx-10 my-12"
        } w-1/5`}
      >
        <div
          className={`bg-gray-900 text-white rounded-lg shadow-lg p-3 cursor-pointer transition-transform transform ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center justify-between">
          <p>Tasks</p>{" "}
            <div>
              {filteredDueTasks.length > 0 &&
                <div className="flex items-center bg-red-400 px-4 py-1 rounded-xl">
                  <p className="text-xl font-semibold">{filteredDueTasks.length}</p>
                  <PriorityHighIcon sx={{fontSize:18}} />
              </div>
              }              
            </div>
            {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </div>
        </div>

        {isOpen && (
          <div className="bg-white shadow-lg rounded-lg p-4 w-full">
            {viewAll ? (
              <div className="flex items-center mb-2 w-full justify-between">
                <div className="flex items-center justify-between gap-2 w-full">
                  <Button
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    icon={<ArrowBack />}
                    iconPosition="start"
                    onClick={toggleViewAll}
                  >
                    Back
                  </Button>
                  <Select
                    placeholder="Frequency Filter"
                    value={frequencyFilter}
                    onChange={handleFrequencyFilter}
                    allowClear={true}
                    className="w-4/12"
                  >
                    {frequencyOptions.map((option) => (
                      <Select.Option key={option.key} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : (
              <div className="flex items-center mb-2 w-full justify-between">
                
                  <div className="flex items-center gap-2 flex-wrap">
                    <FormControlLabel control={<Checkbox checked={completeAll} onChange={handleChangeCompleteAll} />} label="Complete All" />

                  <Button
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    icon={<PlusOutlined />}
                    iconPosition="start"
                    onClick={showModal}
                  >
                    Add Task
                  </Button>
                  <Button
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    onClick={toggleViewAll}
                  >
                    View All
                    </Button>
                    <div className="flex flex-col items-center">

                    <Select
                      placeholder="Signed Off By"
                      value={selectedAssignee}
                      onChange={handleSelectAssignee}
                        allowClear={true}
                        onClear={()=>setErrors({})}
                      style={{
                        minWidth:150
                      }}
                      label="signed off by"
                      >
                        {assigneesOptions.map((option) => (
                          <Select.Option key={option.key} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                    </Select>
                    {errors.assigneeError && !selectedAssignee&& <p className="text-red-500 text-sm font-semibold">{errors.assigneeError}</p>}
                        </div>
                </div>
              </div>
            )}
            {!viewAll && (
              <ul className="flex flex-col gap-3 h-80 overflow-y-auto">
                {allTasks.length>0 && allTasks.filter((task) =>{return ((task.frequency.includes("everyday") || task.frequency.includes(currentDay))&&(task.appear===true))}).length >
                0 ? (
                  allTasks
                    .filter((task) => {return ((task.frequency.includes("everyday") || task.frequency.includes(currentDay))&&(task.appear===true))})
                    .sort(
                      (a, b) =>
                        (b.due === true ? 1 : 0) - (a.due === true ? 1 : 0)
                    )
                    .map((task, index) => (
                      <li
                        key={index}
                        className={`border p-2 rounded shadow-sm  flex items-center gap-4 ${
                          task.due && "bg-red-200"
                        }`}
                      >
                        
                        <div>
                        {task.due&&<PriorityHighIcon/>}
                          <Checkbox className="flex-grow" checked={task.done} onChange={(event) => {
                            if (event.target.checked) {
                              updateStatus(task._id,true)
                            }
                            else {
                              updateStatus(task._id,false)
                            }
                          }} />
                        </div>
                        <div className="flex-grow relative">
                          <div
                           
                            style={{ transform: "translateY(-50%)" }} 
                          />
                          <p className="text-gray-600 relative z-10">{task.description}</p>
                        </div>
                        {task.done&&<p className="text-gray-600 ">{task.signedOffBy}</p>}
                      </li>
                    ))
                ) : (
                  <div className="h-16 w-full flex items-center justify-center">
                    No Daily Tasks To Show
                  </div>
                )}
              </ul>
            )}
            {viewAll && (
              <ul className="space-y-2 h-80 overflow-y-auto">
                {filteredTasks.length > 0 ? (
                  filteredTasks
                    .sort(
                      (a, b) =>
                        (b.due === true ? 1 : 0) - (a.due === true ? 1 : 0)
                    )
                    .map((task, index) => (
                      <li
                        key={index}
                        className={` border p-2 rounded shadow-sm flex items-center gap-4 ${
                          task.due && "bg-red-200 w-full"
                        }`}
                      >
                        <div>
                          {task.due&&<PriorityHighIcon/>}
                        </div>
                        <p className="text-gray-600 flex-1">{task.description}</p>
                        <div className="flex items-center gap-1">
                          <IconButton onClick={() => {
                            setDescription(task.description)
                            setSelectedFrequency(task.frequency)
                            form.setFieldsValue({ frequency: [...task.frequency] }); 
                            form.setFieldsValue({ date: task.reminderDate });  
                            form.setFieldsValue({ day: task.reminderDay });  
                            setIsUpdating(true)
                            setTaskId(task._id)
                            showModal()
                          }}>
                            <Edit />
                          </IconButton>
                          <IconButton onClick={()=>deleteTask(task._id)}>
                            <DeleteFilled style={{color:colors.red["400"]}}/>
                          </IconButton>
                        </div>
                      </li>
                    ))
                ) : (
                  <div className="h-16 w-full flex items-center justify-center">
                    No Tasks To Show
                  </div>
                )}
              </ul>
            )}
          </div>
        )}
      </div>
      <Modal
        title="Add Task"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            className="custom-modal-button"
          >
            Cancel
          </Button>,
            isUpdating ?
              (
                <Button
                    key="submit"
                    type="primary"
                    className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                    onClick={()=>updateTask(taskId)}
                  >
                    Update Task
              </Button>
            )
              : (
                <Button
                  key="submit"
                  type="primary"
                  icon={<PlusOutlined />}
                  iconPosition="start"
                  className="bg-gray-900 text-white hover:!bg-gray-700 hover:!text-white border-none"
                  onClick={addTask}
                >
                  Add Task
                </Button>
            ),
        ]}
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input.TextArea
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />{" "}
          </Form.Item>
          <Form.Item label="Frequency" name="frequency"
          rules={[{ required: true, message: "Please select frequency" }]}>
            <Select
              placeholder="Choose a frequency"
              value={selectedFrequency}
              onChange={handleSelectFrequency}
              allowClear={true}
              mode="multiple"
            >
              {frequencyOptions.map((option) => (
                <Select.Option key={option.key} value={option.value} disabled={selectedFrequency.includes('everyday') && option.value !== "everyday"}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
         
        </Form>
      </Modal>
    </>
  );
};

export default TasksTab;
