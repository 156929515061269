import { Box, Paper, Typography } from '@mui/material'
import { Button, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styles from './RoomSection.module.css'
import axios from 'axios'
import { hasG6PDLab, g6pdLabResult, g6pdLabInRange } from '../../utils'
const RoomSection = ({ room, patients, showNameInitials, fetchPatient, handleClickPatient, inactive, selectedRooms, roomsData, setRoomsData, toastifyToast, menuRoom, setMenuRoom }) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false);
    const [tooltipId, setTooltipId] = useState(null);
    const [ivPrepByTreatment, setIvPrepByTreatment] = useState({});
    const [assignees, setAssignees] = useState([]);
    const [sortedPatients, setSortedPatients] = useState([]);



    const handleClose = () => {
        setShowTooltip(false);
        setTooltipId(null)
        setMenuRoom(null)
    }
    const handleOutsideClick = (event) => {
        if (!selectOpen) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [selectOpen]);
    const roomsOrder = [
        'unassigned',
        'Pickup',
        'Community 1',
        'Community 2',
        'Room 1',
        'Room 2',
        'Room 3',
        'Room 4',
        'Education Room',
        'Set To Completed',
        'Set To Arrived',
    ];
    const updateRoom = (id, room, patientId) => {
        const payload = {
            category: 'patientInfo',
            identifier: {
                key: "name",
                value: 'r',
            },
            updates: {
                room: room,
            },
        };
        if (room !== 'Pickup' && (room !== 'Set To Completed' && room !== 'Set To Arrived')) {
            try {
                axios
                    .put(
                        `/patient/order/update/${id}?fromPrepView=true`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        console.log("Success:", response.data);
                        setRoomsData(prevState =>
                            prevState.map(roomData =>
                                roomData.patients.some(patient => patient._id === id)
                                    ? {
                                        ...roomData,
                                        patients: roomData.patients.map(patient =>
                                            patient._id === id ? { ...patient, room: room } : patient
                                        )
                                    }
                                    : roomData
                            )
                        );
                        toastifyToast.success("Room Updated Successfully")

                        setRoomsData((prevState) => {
                            let patientToMove;
                            let roomExists = false;

                            const updatedRooms = prevState.map((roomGroup) => {
                                const updatedPatients = roomGroup.patients.filter((patient) => {
                                    if (patient.PatientId === patientId) {
                                        patientToMove = { ...patient, status: 'pickup' };
                                        return false;
                                    }
                                    return true;
                                });

                                if (roomGroup.room === room) {
                                    roomExists = true;
                                }

                                return { ...roomGroup, patients: updatedPatients };
                            });

                            const newRooms = roomExists
                                ? updatedRooms.map((roomGroup) => {
                                    if (roomGroup.room === room && patientToMove) {
                                        return {
                                            ...roomGroup,
                                            patients: [...roomGroup.patients, patientToMove],
                                        };
                                    }
                                    return roomGroup;
                                })
                                : [...updatedRooms, { room: room, patients: [patientToMove] }];

                            const sortedRooms = newRooms.sort((a, b) => {
                                return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
                            });

                            return sortedRooms;
                        });

                        fetchPatient(patientId);
                        handleClickPatient(id, patientId);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        toastifyToast.error("Error Updating Room")
                    });
            }
            catch (error) {
                console.errorlog(error)
            }
        }
        if (room === 'Pickup') {
            axios
                .put(
                    `/patient/order/bulk-update-status`,
                    {
                        treatmentIds: [`${id}`],
                        newStatus: 'pickup'

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("Success:", response.data);
                    setRoomsData((prevState) => {
                        let patientToMove;
                        let roomExists = false;

                        const updatedRooms = prevState.map((roomGroup) => {
                            const updatedPatients = roomGroup.patients.filter((patient) => {
                                if (patient.PatientId === patientId) {
                                    patientToMove = { ...patient, status: 'pickup' };
                                    return false;
                                }
                                return true;
                            });

                            if (roomGroup.room === room) {
                                roomExists = true;
                            }

                            return { ...roomGroup, patients: updatedPatients };
                        });

                        const newRooms = roomExists
                            ? updatedRooms.map((roomGroup) => {
                                if (roomGroup.room === room && patientToMove) {
                                    return {
                                        ...roomGroup,
                                        patients: [...roomGroup.patients, patientToMove],
                                    };
                                }
                                return roomGroup;
                            })
                            : [...updatedRooms, { room: room, patients: [patientToMove] }];

                        const sortedRooms = newRooms.sort((a, b) => {
                            return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
                        });

                        return sortedRooms;
                    });

                    toastifyToast.success("Status Updated Successfully")
                    fetchPatient(patientId);
                    handleClickPatient(id, patientId);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toastifyToast.error("Error Updating Status")
                });
        }
        if (room === 'Set To Completed') {
            axios
                .put(
                    `/patient/order/bulk-update-status`,
                    {
                        treatmentIds: [`${id}`],
                        newStatus: 'complete'

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("Success:", response.data);
                    setRoomsData((prevState) => {
                        let patientToMove = null;
                        let roomExists = false;

                        const updatedRooms = prevState.map((roomGroup) => {
                            const updatedPatients = roomGroup.patients.filter((patient) => {
                                if (patient.PatientId === patientId) {
                                    if (patient.status === 'pickup') {
                                        patientToMove = { ...patient, room: patient.room, status: 'complete' };
                                        return false;
                                    } else {
                                        patientToMove = { ...patient, status: 'complete' };
                                        return false;
                                    }
                                }
                                return true;
                            });
                            if (roomGroup.room === room || roomGroup.room === patientToMove?.room) {
                                roomExists = true;
                            }
                            else {
                                const checkRoomExists = prevState.some((r) => r.room === patientToMove?.room);
                                if (checkRoomExists) {
                                    roomExists = true;
                                }
                            }

                            return { ...roomGroup, patients: updatedPatients };

                        });

                        const newRooms = roomExists && patientToMove
                            ? updatedRooms.map((roomGroup) => {
                                if (roomGroup.room === room || roomGroup.room === patientToMove.room) {
                                    return {
                                        ...roomGroup,
                                        patients: [...roomGroup.patients, patientToMove],
                                    };
                                } else if (roomGroup.room === 'unassigned' && patientToMove?.room === 'unassigned') {
                                    return {
                                        ...roomGroup,
                                        patients: [...roomGroup.patients, patientToMove],
                                    };
                                }
                                return roomGroup;
                            })
                            : patientToMove
                                ? [...updatedRooms, { room: patientToMove.room, patients: [patientToMove] }]
                                : updatedRooms;

                        const sortedRooms = newRooms.sort((a, b) => {
                            return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
                        });

                        return sortedRooms;
                    })


                    toastifyToast.success("Status Updated Successfully")
                    fetchPatient(patientId);
                    handleClickPatient(id, patientId);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toastifyToast.error("Error Updating Status")
                });

        }
        if (room === 'Set To Arrived') {
            axios
                .put(
                    `/patient/order/bulk-update-status`,
                    {
                        treatmentIds: [`${id}`],
                        newStatus: 'arrived'

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("Success:", response.data);
                    setRoomsData((prevState) => {
                        let patientToMove = null;
                        let roomExists = false;

                        const updatedRooms = prevState.map((roomGroup) => {
                            const updatedPatients = roomGroup.patients.filter((patient) => {
                                if (patient.PatientId === patientId) {
                                    if (patient.status === 'pickup') {
                                        patientToMove = { ...patient, room: patient.room, status: 'arrived' };
                                        return false;
                                    } else {
                                        patientToMove = { ...patient, status: 'arrived' };
                                        return false;
                                    }
                                }
                                return true;
                            });
                            if (roomGroup.room === room || roomGroup.room === patientToMove?.room) {
                                roomExists = true;
                            }
                            else {
                                const checkRoomExists = prevState.some((r) => r.room === patientToMove?.room);
                                if (checkRoomExists) {
                                    roomExists = true;
                                }
                            }

                            return { ...roomGroup, patients: updatedPatients };

                        });

                        const newRooms = roomExists && patientToMove
                            ? updatedRooms.map((roomGroup) => {
                                if (roomGroup.room === room || roomGroup.room === patientToMove.room) {
                                    return {
                                        ...roomGroup,
                                        patients: [...roomGroup.patients, patientToMove],
                                    };
                                } else if (roomGroup.room === 'unassigned' && patientToMove?.room === 'unassigned') {
                                    return {
                                        ...roomGroup,
                                        patients: [...roomGroup.patients, patientToMove],
                                    };
                                }
                                return roomGroup;
                            })
                            : patientToMove
                                ? [...updatedRooms, { room: patientToMove.room, patients: [patientToMove] }]
                                : updatedRooms;

                        const sortedRooms = newRooms.sort((a, b) => {
                            return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
                        });

                        return sortedRooms;
                    })


                    toastifyToast.success("Status Updated Successfully")
                    fetchPatient(patientId);
                    handleClickPatient(id, patientId);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toastifyToast.error("Error Updating Status")
                });

        }
    }


    const fetchIV_Prep = async (treatmentID) => {
        try {
            const response = await axios.get(
                `/iv_prep/treatment/${treatmentID}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching IV preparation data:", error);
        }
    };
    useEffect(() => {
        patients.forEach(async (treatment) => {
            const ivPrepData = await fetchIV_Prep(treatment._id);
            setIvPrepByTreatment((prev) => ({
                ...prev,
                [treatment._id]: ivPrepData,
            }));
        });
    }, [patients])

    const getCheckedLabs = (userLabs) => {
        return userLabs.filter((lab) => lab.checked);
    };
    const getDispensedItemsWithSelectedField = (userdispensed) => {
        return userdispensed.filter((item) => item.selectedField);
    };
    const getRelevantProcedures = (userProcedures) => {
        return userProcedures.filter(
            (procedure) => procedure.checked || procedure.selectedField
        );
    };
    const TooltipContent = ({ userLabs, userProcedures, userdispensed, treatment_id }) => {
        const ivPrepData = ivPrepByTreatment[treatment_id] || {
            procedures: [],
            dispensed: [],
        };


        const getProcedureStyle = (name) => {
            const found = ivPrepData.procedures.find(
                (p) => p.name === name && p.comped
            );
            return found ? { color: "orange" } : {};
        };
        const getDispensedStyle = (name) => {
            const found = ivPrepData.dispensed.find(
                (p) => p.name === name && p.comped
            );
            return found ? { color: "orange" } : {};
        };

        const checkedLabs = getCheckedLabs(userLabs);
        const dispensedItems = getDispensedItemsWithSelectedField(
            userdispensed
        );
        const relevantProcedures = getRelevantProcedures(userProcedures);

        return (
            <div>
                {(checkedLabs.length === 0 && (dispensedItems.length === 0 && relevantProcedures.length === 0)) &&
                    <div className=''>
                        No Data Exists
                    </div>
                }
                {checkedLabs.length > 0 && (
                    <div className="tooltipSection">
                        <strong>Labs:</strong>
                        {checkedLabs?.map((lab) => (
                            <div key={lab._id} className="tooltipItem">
                                {lab.test_name}
                            </div>
                        ))}
                    </div>
                )}

                {dispensedItems.length > 0 && (
                    <>
                        <br />
                        <div className="tooltipSection">
                            <strong>Dispensed:</strong>
                            {dispensedItems?.map((item) => (
                                <div
                                    key={item._id}
                                    className="tooltipItem"
                                    style={getDispensedStyle(item.name)}
                                >
                                    {item.name} - Quantity: {item.quantity} - Dosage:{" "}
                                    {item.selectedField}
                                </div>
                            ))}
                        </div>
                    </>

                )}

                {relevantProcedures.length > 0 && (
                    <>
                        <br />
                        <div className="tooltipSection">
                            <strong>Procedures:</strong>
                            {relevantProcedures?.map((procedure) => (
                                <div
                                    key={procedure._id}
                                    className="tooltipItem"
                                    style={getProcedureStyle(procedure.procedure_name)}
                                >
                                    {procedure.procedure_name}
                                    {procedure.selectedField &&
                                        ` - Selected: ${procedure.selectedField}`}
                                    {procedure.note && ` - Note: ${procedure.note}`}
                                </div>
                            ))}
                        </div>
                    </>

                )}
            </div>
        );
    };

    useEffect(() => {
        const distinctAssignees = Array.from(new Set(patients.flatMap(patient => {
            // If assignees is an array, return it, otherwise wrap the single value in an array
            return Array.isArray(patient.assignees) ? patient.assignees : [patient.assignees];
        })));
        setAssignees(distinctAssignees)
    }, [patients])
    useEffect(() => {
        const sortedPatientsTabs = patients.length > 0
            ? patients.sort((a, b) => {
                if (a.status === 'complete' && b.status !== 'complete') {
                    return 1;
                } else if (a.status !== 'complete' && b.status === 'complete') {
                    return -1;
                } else {
                    return 0;
                }
            })
            : [];
        setSortedPatients(sortedPatientsTabs);
    }, [patients])


    return (
        <>
            <Paper sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '8px'
            }}>
                {((room === 'unassigned' && patients.length === 0) || room !== 'unassigned') && <Typography variant={'h6'} sx={{ mb: 1, textTransform: 'capitalize' }}>{room}</Typography>}
                {patients.length > 0 ?
                    <>
                        {sortedPatients?.filter(item => item.status !== 'complete').length > 0 && <Box className="flex flex-wrap gap-2 justify-center w-full h-auto overflow-y-auto overflow-x-hidden py-2">
                            {
                                // For sorting completed Patient tabs to bottom 

                                sortedPatients?.filter(item => item.status !== 'complete').map((item, index) => (
                                    <Draggable key={item._id} draggableId={item._id} index={index} isDragDisabled={room === 'Pickup'}>
                                        {(provided) => (
                                            <Tooltip
                                                title={<TooltipContent userLabs={item.userLabs} userProcedures={item.userProcedures} userdispensed={item.userdispensed} treatment_id={item._id} />}
                                                placement="top"
                                            >
                                                <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tooltip visible={tooltipId === item._id && (menuRoom === room)}
                                                        onClick={() => setSelectOpen(true)}
                                                        onClickOutside={() => setSelectOpen(false)}
                                                        color="white"
                                                        title={
                                                            <div className='flex flex-col items-start p-2 text-black '>
                                                                <p className='text-base font-bold'>
                                                                    Move To
                                                                </p>

                                                                <div className='flex flex-col items-center gap-1 mt-2'>
                                                                    {room === 'Pickup' ? roomsOrder.filter(item => item === 'Set To Completed' || item === 'Set To Arrived').map(roomItem => <div className='w-full capitalize text-base px-2 py-0.5 rounded-md cursor-pointer transition delay-300 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={() => { updateRoom(item._id, roomItem, item.PatientId) }}>{roomItem}</div>) :
                                                                        roomsOrder.map(roomItem => <div className='w-full capitalize text-base px-2 py-0.5 rounded-md cursor-pointer transition delay-300 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={() => { updateRoom(item._id, roomItem, item.PatientId) }}>{roomItem === 'unassigned' ? 'Unassign' : roomItem}</div>)}
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <div
                                                            className={`${styles.patientButton} mb-2 ${inactive ? "blur-sm" : ""}`}
                                                            onClick={() => {
                                                                fetchPatient(item.PatientId);
                                                                handleClickPatient(item._id, item.PatientId);
                                                            }}
                                                            style={{
                                                                backgroundColor:
                                                                    item.status === "complete" ? "#00a36c" :
                                                                        item.iv_prep === "complete" ? "#B7E127" :
                                                                            item.status === "pickup" ? "#f6c4f8" : "#ffd6a5",

                                                                color: item.status === "complete" ? "white" : "#333333",
                                                                textAlign: 'left'
                                                            }}
                                                            onContextMenu={(event) => {
                                                                console.warn("Context Menu being called")
                                                                handleClose()
                                                                event.stopPropagation();
                                                                console.warn("Right Click Called")
                                                                setShowTooltip(true)
                                                                event.preventDefault();
                                                                setTooltipId(item._id);
                                                                setMenuRoom(room)
                                                            }}
                                                        >
                                                            {/* {showNameInitials ? item.patientName.split(' ').map(word => word[0].toUpperCase()).join('') : item.patientName} {((selectedRooms[0] === "All Rooms" || selectedRooms.length === 0) ? (item.room ? `- ${item.room}` : "unassigned") : "")}-{" "}
{item.custom} */}
                                                            <div className='flex items-center w-full justify-evenly'>
                                                                {showNameInitials
                                                                    ? item.patientName.split(' ').map(word => word[0].toUpperCase()).join('')
                                                                    : item.patientName
                                                                }
                                                                {(hasG6PDLab(item) && (g6pdLabResult(item) && g6pdLabInRange(item))) && <div className="bg-[#29ff45] p-1 mx-1 rounded-xl"></div>}
                                                                {(hasG6PDLab(item) && !g6pdLabResult(item)) && <div className="bg-orange-500 p-1 mx-1 rounded-xl"></div>}
                                                                {(hasG6PDLab(item) && (g6pdLabResult(item) && !g6pdLabInRange(item))) && <div className="bg-red-700 p-1 mx-1 rounded-xl"></div>}
                                                            </div>
                                                            {room === 'unassigned' && ' - unassigned'}
                                                            {item.custom && ` - ${item.custom}`}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </Draggable>
                                ))
                            }
                        </Box>}
                        {sortedPatients?.filter(item => item.status === 'complete').length > 0 && <>

                            {sortedPatients?.filter(item => item.status !== 'complete').length > 0 && <Box sx={{
                                width: '100%',
                                borderBottom: 'solid lightgray',
                                borderWidth: 1,
                                mb: 1

                            }} ></Box>}
                            <Box className='flex flex-wrap gap-2 justify-center w-full h-auto overflow-y-auto overflow-x-hidden py-2'>
                                {
                                    sortedPatients?.filter(item => item.status === 'complete').map((item, index) => (
                                        <Draggable key={item._id} draggableId={item._id} index={index + sortedPatients?.filter(item => item.status !== 'complete').length} isDragDisabled={room === 'Pickup'}>
                                            {(provided) => (
                                                <Tooltip
                                                    title={<TooltipContent userLabs={item.userLabs} userProcedures={item.userProcedures} userdispensed={item.userdispensed} treatment_id={item._id} />}
                                                    placement="top"
                                                >
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Tooltip visible={tooltipId === item._id && (menuRoom === room)}
                                                            onClick={() => setSelectOpen(true)}
                                                            onClickOutside={() => setSelectOpen(false)}
                                                            color="white"
                                                            title={
                                                                <div className='flex flex-col items-start p-2 text-black '>
                                                                    <p className='text-base font-bold'>
                                                                        Move To
                                                                    </p>

                                                                    <div className='flex flex-col items-center gap-1 mt-2'>
                                                                        {room === 'Pickup' ? roomsOrder.filter(item => item === 'Set To Completed').map(roomItem => <div className='w-full capitalize text-base px-2 py-0.5 rounded-md cursor-pointer transition delay-300 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={() => { updateRoom(item._id, roomItem, item.PatientId) }}>{roomItem}</div>) :
                                                                            roomsOrder.map(roomItem => <div className='w-full capitalize text-base px-2 py-0.5 rounded-md cursor-pointer transition delay-300 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={() => { updateRoom(item._id, roomItem, item.PatientId) }}>{roomItem === 'unassigned' ? 'Unassign' : roomItem}</div>)}
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <div
                                                                className={`${styles.patientButton} mb-2 ${inactive ? "blur-sm" : ""}`}
                                                                onClick={() => {
                                                                    fetchPatient(item.PatientId);
                                                                    handleClickPatient(item._id, item.PatientId);
                                                                }}
                                                                style={{
                                                                    backgroundColor:
                                                                        item.status === "complete" ? "#00a36c" :
                                                                            item.iv_prep === "complete" ? "#B7E127" :
                                                                                item.status === "pickup" ? "#f6c4f8" : "#ffd6a5",

                                                                    color: item.status === "complete" ? "white" : "#333333"
                                                                }}
                                                                onContextMenu={(event) => {
                                                                    console.warn("Context Menu being called")
                                                                    handleClose()
                                                                    event.stopPropagation();
                                                                    console.warn("Right Click Called")
                                                                    setShowTooltip(true)
                                                                    event.preventDefault();
                                                                    setTooltipId(item._id);
                                                                    setMenuRoom(room)
                                                                }}
                                                            >
                                                                <div className='flex items-center w-full justify-evenly'>
                                                                    {showNameInitials
                                                                        ? item.patientName.split(' ').map(word => word[0].toUpperCase()).join('')
                                                                        : item.patientName
                                                                    }
                                                                    {(hasG6PDLab(item) && (g6pdLabResult(item) && g6pdLabInRange(item))) && <div className="bg-[#29ff45] p-1 mx-1 rounded-xl"></div>}
                                                                    {(hasG6PDLab(item) && !g6pdLabResult(item)) && <div className="bg-orange-500 p-1 mx-1 rounded-xl"></div>}
                                                                    {(hasG6PDLab(item) && (g6pdLabResult(item) && !g6pdLabInRange(item))) && <div className="bg-red-700 p-1 mx-1 rounded-xl"></div>}
                                                                </div>
                                                                {room === 'unassigned' && ' - unassigned'}
                                                                {item.custom && ` - ${item.custom}`}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </Draggable>
                                    ))
                                }
                            </Box>
                        </>
                        }

                        {assignees.length > 0 && <>

                            <Box sx={{
                                width: '100%',
                                borderBottom: 'solid lightgray',
                                borderWidth: 1,
                                mb: 1

                            }} ></Box>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1.5,
                                justifyContent: 'center'
                            }}>
                                {assignees.map((assignee, index) => (
                                    index === 0 ? <Typography variant='body2'>{assignee}</Typography> : <Typography variant='body2'>{assignee}</Typography>
                                ))}
                            </Box>
                        </>
                        }
                    </> :
                    <p>No Treatments</p>
                }
            </Paper >
        </>
    )
}

export default RoomSection