import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  TextField,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Paper,
  FormControlLabel,
  MenuItem,
  Grid,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  format,
  addDays,
  subDays,
  addWeeks,
  subWeeks,
  startOfDay,
  endOfDay,
} from "date-fns";
import { LabTableRow } from "./LabTableRow";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const LabTracker = () => {
  const [selectedPatient, setSelectedPatient] = useState("");
  const [reviewedWithPatient, setReviewedWithPatient] = useState(false);
  const [labTrackers, setLabTrackers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [treatmentType, setTreatmentType] = useState("Treatment");
  const [fromDate, setFromDate] = useState(startOfDay(subWeeks(new Date(), 2)));
  const [toDate, setToDate] = useState(endOfDay(new Date()));
  const [patients, setPatients] = useState([]);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [patientSearchTerm, setPatientSearchTerm] = useState("");
  const [isApiRequestPending, setIsApiRequestPending] = useState(false);
  const [labStatus, setLabStatus] = useState("none");
  const [isPatientSelected, setIsPatientSelected] = useState(false);

  const fetchLabTrackers = useCallback(async () => {
    setLoading(true);
    setIsApiRequestPending(true);
    try {
      const params = new URLSearchParams({
        labStatus: labStatus,
        reviewedWithPatient: reviewedWithPatient,
        selectedPatient: selectedPatient,
        treatmentType: treatmentType,
      });

      // Only include date parameters if no patient is selected
      if (!selectedPatient) {
        params.append("fromDate", format(fromDate, "yyyy-MM-dd"));
        params.append("toDate", format(toDate, "yyyy-MM-dd"));
      }

      const response = await axios.get(
        `/labtrackerrange?${params}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setLabTrackers(response.data.labTrackers);
      toast.success("Lab trackers fetched successfully");
      setError(null);
    } catch (err) {
      console.error("Error fetching lab trackers:", err);
      setError("Failed to fetch lab trackers. Please try again.");
      toast.error("Failed to fetch lab trackers");
    } finally {
      setLoading(false);
      setIsApiRequestPending(false);
    }
  }, [
    labStatus,
    reviewedWithPatient,
    selectedPatient,
    treatmentType,
    fromDate,
    toDate,
  ]);

  // Update the Autocomplete onChange handler
  const handlePatientChange = (event, newValue) => {
    if (!isApiRequestPending) {
      setSelectedPatient(newValue ? newValue["Customer ID"] : "");
      setIsPatientSelected(!!newValue);
    } else {
      toast.warning("Please wait for the current request to complete");
    }
  };

  const handleDateChange = (direction) => {
    if (isApiRequestPending) {
      toast.warning("Please wait for the current request to complete");
      return;
    }
    if (direction === "forward") {
      setFromDate(addWeeks(fromDate, 2));
      setToDate(addWeeks(toDate, 2));
    } else {
      setFromDate(subWeeks(fromDate, 2));
      setToDate(subWeeks(toDate, 2));
    }
  };

  const debouncedFetchLabTrackers = useCallback(
    debounce(fetchLabTrackers, 300),
    [fetchLabTrackers]
  );

  useEffect(() => {
    debouncedFetchLabTrackers();
  }, [debouncedFetchLabTrackers]);

  const handleClearFilters = () => {
    if (isApiRequestPending) {
      toast.warning("Please wait for the current request to complete");
      return;
    }
    setSelectedPatient(""); // Change this to null instead of an empty string
    setIsPatientSelected(false);
    setLabStatus("none");
    setReviewedWithPatient(false);
    setPatientSearchTerm(""); // Add this line to clear the search term
    toast.success("Filters cleared");
  };

  const handleLabTrackerUpdate = (updatedTracker) => {
    setLabTrackers((prevTrackers) =>
      prevTrackers.map((tracker) =>
        tracker._id === updatedTracker._id
          ? {
              ...updatedTracker,
              treatment: tracker.treatment,
              patientProfile: tracker.patientProfile,
            }
          : tracker
      )
    );
    toast.success("Lab tracker updated successfully");
  };

  const handleG6PDCheckAll = (patientId, checkValue) => {
    setLabTrackers(prevState => 
      prevState.map((tracker) => {
        if (tracker.patientProfile && tracker.patientProfile['Customer ID'] === patientId) {
          return {
            ...tracker,
            patientProfile: {
              ...tracker.patientProfile,
              G6PD: checkValue,
            },
          };
        }
        return tracker;
      })
    );
    

    const action = checkValue ? 'checked' : 'unchecked';
    toast.success(`G6PD value ${action} successfully for the selected patient`);
  };

  const handleChangeRangeValue = (treatmentId, rangeValue) => {
    const updatedLabTrackers = labTrackers.map((tracker) => {
      if (
        tracker.treatment &&
        tracker.treatment._id === treatmentId
      ) {
        const updatedUserLabs = tracker.treatment.userLabs.map((lab) => {
          if (lab.test_name === 'G6PD') {
            return {
              ...lab,
              range: rangeValue,
            }
          }  
            return lab;
        });
  
        return {
          ...tracker,
          treatment: {
            ...tracker.treatment,
            userLabs: updatedUserLabs, 
          },
        };
      }
      return tracker; 
    });
  
    setLabTrackers(updatedLabTrackers)
}
  
  
  
  const searchPatients = useCallback(
    debounce(async (searchTerm) => {
      if (searchTerm.length < 2) {
        setPatients([]);
        return;
      }

      setLoadingPatients(true);
      setIsApiRequestPending(true);
      try {
        const response = await axios.get(
          `/patient-profiles/search?term=${encodeURIComponent(
            searchTerm
          )}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        setPatients(response.data);
        if (response.data.length > 0) {
          toast.success(`Found ${response.data.length} patient(s)`);
        } else {
          toast.info("No patients found");
        }
      } catch (err) {
        console.error("Error searching patients:", err);
        setError("Failed to search patients. Please try again.");
        toast.error("Failed to search patients");
      } finally {
        setLoadingPatients(false);
        setIsApiRequestPending(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    searchPatients(patientSearchTerm);
  }, [patientSearchTerm, searchPatients]);

  const handleCheckboxChange = (setter) => (event) => {
    if (isApiRequestPending) {
      toast.warning("Please wait for the current request to complete");
      return;
    }
    setter(event.target.checked);
  };

  const handleTreatmentTypeChange = (newType) => {
    if (isApiRequestPending) {
      toast.warning("Please wait for the current request to complete");
      return;
    }
    setTreatmentType(newType);
  };
  const handleLabStatusChange = (status) => {
    if (isApiRequestPending) {
      toast.warning("Please wait for the current request to complete");
      return;
    }
    setLabStatus(labStatus === status ? "none" : status);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastContainer position="top-right" autoClose={3000} />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: {
            xs: "100%",
            sm: "100%",
            md: "1200px",
            lg: "1600px",
            xl: "2400px",
          },
          px: { xs: 2, sm: 3, md: 4 },
          py: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Typography variant="h4" gutterBottom>
          Lab Tracker
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              options={patients}
              getOptionLabel={(option) => option["Full Name"]}
              loading={loadingPatients}
              value={
                selectedPatient
                  ? patients.find((p) => p["Customer ID"] === selectedPatient)
                  : null
              }
              onInputChange={(event, newInputValue) => {
                if (!isApiRequestPending) {
                  setPatientSearchTerm(newInputValue);
                } else {
                  toast.warning(
                    "Please wait for the current request to complete"
                  );
                }
              }}
              onChange={handlePatientChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Patient"
                  variant="outlined"
                  fullWidth
                  disabled={isApiRequestPending}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingPatients ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={labStatus === "remaining"}
                  onChange={() => handleLabStatusChange("remaining")}
                  disabled={isApiRequestPending}
                />
              }
              label="Lab Results Remaining"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={labStatus === "ready"}
                  onChange={() => handleLabStatusChange("ready")}
                  disabled={isApiRequestPending}
                />
              }
              label="Ready for Scheduling"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={reviewedWithPatient}
                  onChange={handleCheckboxChange(setReviewedWithPatient)}
                  disabled={isApiRequestPending}
                />
              }
              label="Reviewed with Patient"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleClearFilters}
              disabled={isApiRequestPending}
            >
              Clear Filters
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant={treatmentType === "All" ? "contained" : "outlined"}
                onClick={() => handleTreatmentTypeChange("All")}
                disabled={isApiRequestPending}
              >
                All
              </Button>
              <Button
                variant={
                  treatmentType === "Treatment" ? "contained" : "outlined"
                }
                onClick={() => handleTreatmentTypeChange("Treatment")}
                disabled={isApiRequestPending}
              >
                Treatments
              </Button>
              <Button
                variant={treatmentType === "Order" ? "contained" : "outlined"}
                onClick={() => handleTreatmentTypeChange("Order")}
                disabled={isApiRequestPending}
              >
                Orders
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  isApiRequestPending || isPatientSelected
                    ? "Date selection is disabled"
                    : ""
                }
              >
                <span>
                  <IconButton
                    onClick={() => handleDateChange("back")}
                    disabled={isApiRequestPending || isPatientSelected}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue) => {
                  if (!isApiRequestPending && !isPatientSelected) {
                    setFromDate(startOfDay(newValue));
                  } else {
                    toast.warning("Date selection is disabled");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isApiRequestPending || isPatientSelected}
                  />
                )}
              />
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue) => {
                  if (!isApiRequestPending && !isPatientSelected) {
                    setToDate(endOfDay(newValue));
                  } else {
                    toast.warning("Date selection is disabled");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isApiRequestPending || isPatientSelected}
                  />
                )}
              />
              <Tooltip
                title={
                  isApiRequestPending || isPatientSelected
                    ? "Date selection is disabled"
                    : ""
                }
              >
                <span>
                  <IconButton
                    onClick={() => handleDateChange("forward")}
                    disabled={isApiRequestPending || isPatientSelected}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow style={{ alignItems: "center" }}>
                      <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                        Patient Name
                      </TableCell>
                      <TableCell sx={{ width: "5%", fontWeight: "bold" }}>
                        Date Drawn
                      </TableCell>
                      <TableCell sx={{ width: "20%", fontWeight: "bold" }}>
                        Labs Drawn
                      </TableCell>
                      <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                        Range
                      </TableCell>
                      <TableCell sx={{ width: "5%", fontWeight: "bold" }}>
                        G6PD Cleared
                      </TableCell>
                      <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                        Labs Not Run
                      </TableCell>
                      <TableCell sx={{ width: "15%", fontWeight: "bold" }}>
                        Comments/Notes
                      </TableCell>
                      <TableCell sx={{ width: "15%", fontWeight: "bold" }}>
                        Lab Review Scheduled
                      </TableCell>
                      <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                        Reviewed with Patient
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {labTrackers.map((tracker) => (
                      <LabTableRow
                        key={tracker._id}
                        row={tracker}
                        onUpdate={handleLabTrackerUpdate}
                        onG6PDClearedupdate={handleG6PDCheckAll}
                        handleChangeRangeValue={handleChangeRangeValue}
                        disabled={isApiRequestPending}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};
