import { ArrowBack } from '@mui/icons-material';
import { Button, Tabs,Tooltip } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import '../../../../App.css'; // Import the global CSS
import styles from '../../EMR.module.css';
import InnerTabsSection from '../InnerTabsSection';
import SOAPNotesSection from '../SOAPNotesSection';
import SummarySection from '../SummarySection';
import MultipleTxNotes from '../../../../Components/AuditView/Components/MultipleTxNotes'
import {
  PushpinFilled,
  PushpinOutlined
} from '@ant-design/icons';


const { TabPane } = Tabs;

const PatientProfileSection = ({ selectedPatient, setSelectedPatient }) => {
  const targetDivRef = useRef(null);
  const [isPinnedTooltipVisible, setIsPinnedTooltipVisible] = useState(false); 
  const [selectedDate,setSelectedDate]=useState(moment().format("YYYY-MM-DD"))
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(selectedPatient);

  const scrollToDiv = () => {
    targetDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const initialTabs = [
    { key: '1', tab: 'Summary', closable: false },
    { key: '2', tab: 'Timeline', closable: false },
    { key: '3', tab: 'Documents', closable: false },
    { key: '4', tab: 'Profile', closable: false },
    { key: '5', tab: 'Payment Collection', closable: false },
    { key: '6', tab: 'Patient Ledger', closable: false }
  ];

  const addTab = (newTabName, notesData = '', chiefConcern = '') => {
    if (newTabName.trim() === '') return;

    const newKey = `${Date.now()}`;
    const newTab = { key: newKey, tab: moment(newTabName).format('MM-DD-YYYY'), closable: true, type: 'notes' };

    setTabs((prevTabs) => [...prevTabs, newTab]);
    setActiveKey(newKey);
    setNewTabName('');

    setTabContent((prevTabContent) => ({
      ...prevTabContent,
      [newKey]: createTabContent(newKey, notesData, chiefConcern)
    }));
  };

  const createTabContent = (key, notesData = '', chiefConcern = '') => (
    <div className='flex gap-4 h-[800px] justify-stretch' key={key}>
      <div className='w-[27%] h-full'>
        <InnerTabsSection addTab={addTab} ref={targetDivRef} selectedPatient={selectedPatient}  />
      </div>
      <div className='w-[73%] h-full'>
        <SOAPNotesSection
          scrollToDiagnosisDiv={scrollToDiv}
          patientProfile={selectedPatient}
          notesData={notesData}
          chiefConcern={chiefConcern}
        />
      </div>
    </div>
  );

  // State for tabs
  const [tabs, setTabs] = useState(initialTabs);
  const [activeKey, setActiveKey] = useState(initialTabs[0].key);
  const [newTabName, setNewTabName] = useState('');
  const [tabContent, setTabContent] = useState({
    '1': <SummarySection addTab={addTab} selectedPatient={selectedPatient}  />,
    '2': <p>Content for Timeline tab</p>,
    '3': <p>Content for Documents tab</p>,
    '4': <p>Content for Profile tab</p>,
    '5': <p>Content for Payment Collection tab</p>,
    '6': <p>Content for Patient Ledger tab</p>,
  });

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const removeTab = (targetKey) => {
    const newTabs = tabs.filter(tab => tab.key !== targetKey);
    const newActiveKey = activeKey === targetKey ? newTabs[0]?.key : activeKey;
    const { [targetKey]: removedContent, ...newTabContent } = tabContent;
    setTabs(newTabs);
    setTabContent(newTabContent);
    setActiveKey(newActiveKey);
  };

  return (
    <div className='relative tabs-container w-[95%]'>
      <div className='flex flex-col mb-2 gap-2'>
        <p className='text-4xl font-semibold'>{selectedPatient['Full Name']}</p>
        <div className='w-full flex gap-5'>
          
          {
            selectedPatient?.phoneNumbers[0]?.number && <>
              <p className='text-md font-medium'>Contact Numer: {selectedPatient.phoneNumbers[0]?.number}</p>
            </>
          }
         
        </div>
      </div>
      <Button className={`my-4 ${styles.button}`} icon={<ArrowBack />} onClick={() => setSelectedPatient(null)}>Back</Button>
      <Tabs
        type="editable-card"
        activeKey={activeKey}
        onChange={onTabChange}
        onEdit={(targetKey, action) => action === 'remove' && removeTab(targetKey)}
        hideAdd
        tabBarExtraContent={
          <Tooltip
          open={isPinnedTooltipVisible}
          color="white"
          overlayInnerStyle={{
            color: 'black',
            width: '100%', 
            maxWidth: 'none', 
            whiteSpace: 'normal',
            overflowY: 'auto', 
            overflowX: 'auto',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
          overlayClassName="!w-[500px] max-w-none"  
          title={
            isPinnedTooltipVisible && (
              <div style={{ width: '100%' }}>
                <MultipleTxNotes
                  selectedPatientDetails={selectedPatientDetails}
                  setSelectedPatientDetails={setSelectedPatientDetails}
                  selectedDateFromView={selectedDate}
                  from="emr"
                />
              </div>
            ) 
          }
          placement="left"
          autoAdjustOverflow={true}
        >
          {isPinnedTooltipVisible?<PushpinFilled
            onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
            style={{ fontSize: '18px', cursor: 'pointer', marginLeft: 8 }}
          />:<PushpinOutlined
            onClick={() => setIsPinnedTooltipVisible((prev) => !prev)}
            style={{ fontSize: '18px', cursor: 'pointer', marginLeft: 8 }}
          />}
        </Tooltip>
        
        
        }
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} closable={tab.closable}>
            <div>
              {tabContent[tab.key] || <p>No content available for this tab.</p>}
            </div>
          </TabPane>
        ))}
      
      </Tabs>
      

    </div>
  );
};

export default PatientProfileSection;
