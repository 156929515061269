import React, {  useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Modal,
  Form,
  Select,
  message,
  Button,
  Input,
  InputNumber,
} from "antd";
import axios from "axios";
import { ColumnWidthOutlined } from "@ant-design/icons";

const { Option } = Select;


const CustomizeTable=({ showToast, from, contextMenu })=> {

  
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState(false);
    const inputRefs = useRef({});
    const [form] = Form.useForm(); // Create a form instance

  const [selectedView, setSelectedView] = useState('treatmentView');
  const [widths, setWidths] = useState({
    lab: 'large',
    procedure: 'large',
    dispensed: 'large',
  });

  // Unified handler for dropdown changes
  const handleChange = (key, value) => {
    setWidths({
      ...widths,
      [key]: value,
    });
    form.setFieldValue({key,value})
    
  };

  
 

  const showAddTemplateModal = () => {
    setIsAddTemplateOpen(true);
  };

  const handleCancel = () => {
    setIsAddTemplateOpen(false);
    };
    
    const viewOptions=[{id:0,value:'treatmentView',label:'Treatment View'}]

    const handleViewChange = (value) => {
        setSelectedView(value)
    }

    const widthOptions = [
        { id: 0, value: "small", label: 'Small' },
        { id: 1, value: "medium", label: 'Medium' },
        {id:2,value:"large",label:'Large'}
    ]

    const updateWidthSettings = () => {
        const payload = {
            view: selectedView,
            customizationType: 'width',
            columns: [
                {
                    columnName: "lab",
                    columnWidth: form.getFieldValue("lab")
                },
                {
                    columnName: "procedure",
                    columnWidth: form.getFieldValue("procedure")
                },
                {
                    columnName: "dispensed",
                    columnWidth: form.getFieldValue("dispensed")
                    },
            ]
        }
        axios
        .post("/change-table-settings", payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        })
        .then((response) => {
          
          message.success("Settings Updated");
        })
        .catch((error) => {
          
          message.error("An error occurred");
        });
    }

    const fetchTableSettings = () => {
        axios
        .get("/table-settings",{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        })
        .then((response) => {
          
            //   message.success("Settings Updated");
            if (response.data[0].customizationType === "width") {
                
                response.data[0].columns.map(item => form.setFieldValue(item.columnName, item.columnWidth));
                  
            }
        })
        .catch((error) => {
          
          message.error("An error occurred");
        });
    }
    useEffect(() => {
        // form.setFieldValue({lab:}) 
        fetchTableSettings()
    }, [])
    
    // useEffect(() => {
    //     form.setFieldsValue(widths);
    //      const values = form.getFieldsValue(); // Get all form values
    // console.log('Form Values:', values); // Log the values
    //   }, [widths, form]);
    
  return (
    <>
     
        <button
          className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
          onClick={showAddTemplateModal}
        >
          Customize Table
        </button>
      
      <Modal
        title="Manage Treatment Templates"
        visible={isAddTemplateOpen}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={[
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="py-1 px-10 w-full md:w-auto"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
              type="primary"
              onClick={updateWidthSettings}
          >
            Update
          </button>,
        ]}
        width={'600px'}
      >
              <Form form={form} layout="vertical">
              <Form.Item label="View">
                <Select
                value={selectedView}
                onChange={(value) => handleViewChange(value)}
                placeholder="Please select a view "
                >
                <Option value="treatmentView">Treatment View</Option>
                </Select>
      </Form.Item>
      {/* Lab Dropdown */}
                  {selectedView&&
                      <>
                      <div className="shadow-xl px-6 py-4">
                      <div className="text-base font-semibold mb-4">Customize Width For Columns</div>
                      <Form.Item label="Lab" name="lab">
              <Select
                value={widths.lab}
                onChange={(value) => handleChange('lab', value)}
                placeholder="Select Lab Column Width"
              >
                {widthOptions.map((option) => (
                  <Select.Option key={option.id} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

      {/* Procedure Dropdown */}
      <Form.Item label="Procedure" name="procedure">
        <Select
          value={widths.procedure}
          onChange={(value) => handleChange('procedure', value)}
          placeholder="Select Procedure Column Width"
        >
           {
                widthOptions.map((option) => <Option key={option.id} value={option.value}>{ option.label}</Option>                            )
            }
        </Select>
      </Form.Item>

      {/* Dispensed Dropdown */}
      <Form.Item label="Dispensed" name="dispensed">
        <Select
          value={widths.dispensed}
          onChange={(value) => handleChange('dispensed', value)}
          placeholder="Select Dispensed Column Width"
        >
            {
                widthOptions.map((option) => <Option key={option.id} value={option.value}>{ option.label}</Option>                            )
            }
        </Select>
                          </Form.Item>
                          </div>
                          </>
      }
    </Form>
      </Modal>
    </>
  );
}

export default CustomizeTable;
