import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  DatePicker,
  Row,
  Col,
  Select,
  message,
  Space,
  Flex,
  Checkbox,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Switch from "@mui/material/Switch";
import moment from "moment";
import "../Updations/Updations.css";
import styles from "./Messages.module.css";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import dayjs from "dayjs";
import axios from "axios";
import { Edit, Warning } from "@mui/icons-material";

const { TextArea } = Input;
const { Option } = Select;

function UpdatePatient({
  patient,
  patients,
  showIcon = false,
  from = "",
  onModalOpen,
  onModalClose,
}) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [patientData, setPatientData] = useState(patient || null);

  const showModal = async () => {
    setOpen(true);
    if (patientData) {
      fetchPatientShopifyNotes(patientData);
    }
    if (from === "floatButton" && onModalOpen) {
      onModalOpen();
    }
  };

  const fetchPatientShopifyNotes = async (patientData) => {
    try {
      const response = await axios.get(
        `/shopify-customer-note/${
          patientData && patientData["Customer ID"]
        }`
      );
      if (response.status === 200) {
        setPatientData({ ...patientData, Note: response.data.note });

        console.log("Api response", response);
      }
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  useEffect(() => {
    console.log("Patient Data", patientData);
    // if (from === "adv" && patientData) {
    //   fetchPatientShopifyNotes();
    // }
    setFormFields();
  }, [patientData]);

  const setFormFields = () => {
    form.setFieldsValue({
      firstName: patientData && patientData["First Name"],
      lastName: patientData && patientData["Last Name"],
      emails: patientData && patientData.Email,
      addresses: patientData && patientData.addresses,
      phoneNumbers: patientData && patientData.phoneNumbers,
      status: patientData && patientData.Status,
      DOB: patientData && patientData.DOB ? dayjs(patientData.DOB) : undefined,
      consentForm: patientData && patientData.ConsentForm,
      sex: patientData && patientData.sex,
      Note: (patientData && patientData?.Note) || "",
    });
  };

  const handleFormSubmit = async (values) => {
    console.log("Form Values", values);
    setConfirmLoading(true);
    try {
      const payload = {
        "First Name": values.firstName,
        "Last Name": values.lastName,
        "Full Name": `${values.firstName} ${values.lastName}`,
        Email: values.emails,
        addresses: values.addresses,
        phoneNumbers: values.phoneNumbers,
        Status: values.status,
        ConsentForm: values.consentForm,
        DOB: values.DOB ? values.DOB.toISOString() : null,
        sex: values.sex,
        Note: values.Note,
      };

      const response = await fetch(
        `/patient-profiles/${patientData._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 200) {
        const shopifyNotesUpdateResponse = axios.post(
          "/api/update-customer-notes",
          { customerId: patientData["Customer ID"], note: values.Note }
        );
        if (shopifyNotesUpdateResponse.status === 200) {
          message.success("Patient updated successfully");
          setOpenConfirmationDialog(false);
          setConfirmationLoading(false);
          setOpen(false);
        }
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      message.error("Failed to update patient");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    if (from === "adv" || from === "floatButton") {
      setPatientData(null);
    }
    setOpen(false);
    if (from === "floatButton" && onModalClose) {
      onModalClose();
    }
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      // Add value to array if checked
      // setCheckedValues((prev) => [...prev, value]);
    } else {
      // Remove value from array if unchecked
      // setCheckedValues((prev) => prev.filter((item) => item !== value));
    }
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const showConfirmationDialog = () => {
    // setOpenConfirmationDialog(true);
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to submit?",
      okText: "Confirm",
      cancelText: "Cancel", // Add the cancel button
      onOk: handleOk, // Trigger form submission on OK
      onCancel: handleCancelConfirmationDialog, // Handle cancel button click
      okButtonProps: {
        style: {
          backgroundColor: "#1976d2",
          borderColor: "#1976d2",
          color: "white",
          fontWeight: "bold",
        },
      },
    });
  };
  const handleOk = () => {
    setConfirmationLoading(true);
    formRef.current.submit();
  };
  const handleCancelConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const formRef = useRef(null);

  const handlePatientSelect = (patientId) => {
    const patient = patients.find((p) => p._id === patientId);
    setPatientData(patient);
    fetchPatientShopifyNotes(patient);
  };

  useEffect(() => {
    if (from === "floatButton") {
      showModal();
    }
    console.log("************** Patients", patients);
  }, [patients]);
  return (
    <>
      {from !== "floatButton" &&
        (showIcon ? (
          <IconButton
            onClick={() => {
              showModal();
            }}
          >
            <Edit />
          </IconButton>
        ) : (
          <Button
            className={`btn-color-BorderOnly ${
              from === "adv" || from === "floatButton" ? " w-auto h-fit" : ""
            }`}
            type="primary"
            onClick={() => {
              showModal();
            }}
          >
            Edit Patient
          </Button>
        ))}

      <Modal
        title="Update Patient"
        open={open}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
        width={1200}
      >
        {(from === "adv" || from === "floatButton") && (
          <Select
            showSearch
            placeholder="Select a patient"
            value={patientData && patientData["Full Name"]}
            style={{ width: 400 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handlePatientSelect}
          >
            {patients.map((patient) => (
              <Option key={patient._id} value={patient._id}>
                {patient["Full Name"]}
              </Option>
            ))}
          </Select>
        )}
        {(from === "adv" || from === "floatButton") && patientData === null && (
          <div className=" my-5 py-52 px-10 rounded-xl shadow-md flex items-center justify-center gap-3">
            <Warning sx={{ color: yellow[700], fontSize: "36px" }} />
            <Typography
              variant="h6"
              className="text-4xl font-bold"
              sx={{ fontWeight: 400 }}
            >
              Please select a patient to see the data
            </Typography>
          </div>
        )}
        {patientData ? (
          <Form
            form={form}
            name="patientForm"
            onFinish={handleFormSubmit}
            layout="vertical"
            ref={formRef}
          >
            <div className="flex items-start gap-4">
              {/* Column 1 */}
              <div className="w-[40%]">
                {/* Patient Information Section*/}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="firstName"
                        label={<p className={styles["label"]}>First Name</p>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter First Name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="lastName"
                        label={<p className={styles["label"]}>Last Name</p>}
                        rules={[
                          { required: true, message: "Please enter Last Name" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="status"
                        label={<p className={styles["label"]}>Status</p>}
                        rules={[
                          { required: true, message: "Please select Status" },
                        ]}
                      >
                        <Select>
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="DOB"
                        label={<p className={styles["label"]}>Date of Birth</p>}
                        rules={[
                          {
                            required: true,
                            message: "Please select Date of Birth",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="MM-DD-YYYY"
                          defaultValuevalue={dayjs(form.getFieldValue("DOB"))}
                          onChange={(date) =>
                            form.setFieldsValue({ DOB: date })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="consentForm"
                        label={<p className={styles["label"]}>Consent Form</p>}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="sex"
                        label={<p className={styles["label"]}>Sex</p>}
                      >
                        <Select>
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                          <Option value="Other">Other</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Box>
                {/* Marketing and Communications Checkboxes Section */}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Checkbox
                        value={"Marketing"}
                        onChange={handleCheckboxChange}
                      >
                        Marketing
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox
                        value={"Communications"}
                        onChange={handleCheckboxChange}
                      >
                        Communications
                      </Checkbox>
                    </Col>
                  </Row>
                </Box>
                {/* Note Section */}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="Note"
                        label={<p className={styles["label"]}>Notes</p>}
                      >
                        <Input.TextArea
                          style={{ height: "150px" }}
                          defaultValue={form.getFieldsValue("Note")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Box>
              </div>
              {/* Column 2 */}
              <div className="w-[60%]">
                {/* Emails Section */}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Form.List name="emails">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            {index > 0 && (
                              <div className={styles["horizontal-line"]}></div>
                            )}
                            <Flex
                              key={key}
                              // style={{ display: "flex", marginBottom: 8 }}
                              justify="space-between"
                              align="center"
                              style={{ marginBottom: 14 }}
                            >
                              <Flex align="center" flex={1} gap={"middle"}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "email"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing email",
                                    },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "tag"]}
                                  rules={[
                                    { required: true, message: "Missing tag" },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Tag" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "isDefault"]}
                                  valuePropName="checked"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    checkedChildren="Default"
                                    unCheckedChildren="Not Default"
                                  />
                                </Form.Item>
                              </Flex>
                              <MinusCircleOutlined
                                onClick={() =>
                                  (form
                                    .getFieldValue(["emails", name, "tag"])
                                    ?.toLowerCase() || "") ===
                                  "Shopify".toLowerCase()
                                    ? message.warning(
                                        "This email is linked with shopify account we can't delete this"
                                      )
                                    : remove(name)
                                }
                                style={{
                                  color:
                                    (form
                                      .getFieldValue(["emails", name, "tag"])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                      ? "gray"
                                      : red[500],
                                  fontSize: 18,
                                  cursor:
                                    (form
                                      .getFieldValue(["emails", name, "tag"])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                disabled={
                                  (form
                                    .getFieldValue(["emails", name, "tag"])
                                    ?.toLowerCase() || "") ===
                                  "Shopify".toLowerCase()
                                }
                              />
                            </Flex>
                          </>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Email
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Box>
                {/* Phone Numbers Section */}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Form.List name="phoneNumbers">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            {index > 0 && (
                              <div className={styles["horizontal-line"]}></div>
                            )}
                            <Flex
                              key={key}
                              // style={{ display: "flex", marginBottom: 8 }}
                              justify="space-between"
                              align="center"
                              style={{ marginBottom: 14 }}
                            >
                              <Flex align="center" flex={1} gap={"middle"}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "number"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing phone number",
                                    },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Phone Number" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "tag"]}
                                  rules={[
                                    { required: true, message: "Missing tag" },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Tag" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "isDefault"]}
                                  valuePropName="checked"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    checkedChildren="Default"
                                    unCheckedChildren="Not Default"
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() =>
                                    (form
                                      .getFieldValue([
                                        "phoneNumbers",
                                        name,
                                        "tag",
                                      ])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                      ? message.warning(
                                          "This phone number is linked with shopify account we can't delete this"
                                        )
                                      : remove(name)
                                  }
                                  style={{
                                    color:
                                      (form
                                        .getFieldValue([
                                          "phoneNumbers",
                                          name,
                                          "tag",
                                        ])
                                        ?.toLowerCase() || "") ===
                                      "Shopify".toLowerCase()
                                        ? "gray"
                                        : red[500],
                                    fontSize: 18,
                                    cursor:
                                      (form
                                        .getFieldValue([
                                          "phoneNumbers",
                                          name,
                                          "tag",
                                        ])
                                        ?.toLowerCase() || "") ===
                                      "Shopify".toLowerCase()
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  disabled={
                                    (form
                                      .getFieldValue([
                                        "phoneNumbers",
                                        name,
                                        "tag",
                                      ])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                  }
                                />
                              </Flex>
                            </Flex>
                          </>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Phone Number
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Box>
                {/* Addresses Section */}
                <Box
                  component={Paper}
                  sx={{ px: 3, py: 1, borderRadius: 2, my: 3 }}
                >
                  <Form.List name="addresses">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            {index > 0 && (
                              <div className={styles["horizontal-line"]}></div>
                            )}
                            <Flex
                              key={key}
                              vertical
                              gap={"middle"}
                              // justify="space-between"
                              justify="center"
                              style={{ marginBottom: 8 }}
                            >
                              <Flex align="center" flex={1} gap={"middle"}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "address1"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing address",
                                    },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Address Line 1" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "address2"]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Address Line 2" />
                                </Form.Item>
                              </Flex>
                              <Flex align="center" flex={1} gap={"middle"}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "city"]}
                                  rules={[
                                    { required: true, message: "Missing city" },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="City" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "province"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing province",
                                    },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Province" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "provinceCode"]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Province Code" />
                                </Form.Item>
                              </Flex>
                              <Flex align="center" flex={1} gap={"middle"}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "country"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing country",
                                    },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Country" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "countryCode"]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Country Code" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "zip"]}
                                  rules={[
                                    { required: true, message: "Missing zip" },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Zip" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "tag"]}
                                  rules={[
                                    { required: true, message: "Missing tag" },
                                  ]}
                                  style={{ width: "50%", marginBottom: 0 }}
                                >
                                  <Input placeholder="Tag" />
                                </Form.Item>
                              </Flex>
                              <Flex
                                align="center"
                                flex={1}
                                gap={"middle"}
                                justify="space-between"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "isDefault"]}
                                  valuePropName="checked"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    checkedChildren="Default"
                                    unCheckedChildren="Not Default"
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() =>
                                    (form
                                      .getFieldValue(["addresses", name, "tag"])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                      ? message.warning(
                                          "This address is linked with shopify account we can't delete this"
                                        )
                                      : remove(name)
                                  }
                                  style={{
                                    color:
                                      (form
                                        .getFieldValue([
                                          "addresses",
                                          name,
                                          "tag",
                                        ])
                                        ?.toLowerCase() || "") ===
                                      "Shopify".toLowerCase()
                                        ? "gray"
                                        : red[500],
                                    fontSize: 18,
                                    cursor:
                                      (form
                                        .getFieldValue([
                                          "addresses",
                                          name,
                                          "tag",
                                        ])
                                        ?.toLowerCase() || "") ===
                                      "Shopify".toLowerCase()
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  disabled={
                                    (form
                                      .getFieldValue(["addresses", name, "tag"])
                                      ?.toLowerCase() || "") ===
                                    "Shopify".toLowerCase()
                                  }
                                />
                              </Flex>
                            </Flex>
                          </>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Address
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Box>
              </div>
            </div>
            <Form.Item>
              <Button
                style={{
                  backgroundColor: "#1976d2",
                  borderColor: "#1976d2",
                  color: "white",
                  fontWeight: "bold",
                  width: "100%",
                }}
                className={styles["submit-button"]}
                type="primary"
                // htmlType="submit"
                onClick={showConfirmationDialog}
                loading={confirmLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default UpdatePatient;
