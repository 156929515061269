import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Print } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import { Button, Form, Input, message, Select, Space } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from '../../EMR.module.css';
import PrintModal from '../PrintModal';

const SummarySection = ({ addTab, selectedPatient, fromSoapNote = false }) => {

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [addCustomMedication, setAddCustomMedication] = useState()
  const [addCustomICDCode, setAddCustomICDCode] = useState(false)
  const [medications, setMedications] = useState([])
  const [icd10CMCodes, setICD10CMCodes] = useState([])
  const [encounters, setEncounters] = useState([])
  const [medicationInputValue, setMedicationInputValue] = useState('')
  const [icdName, setICDName] = useState('')
  const [icdCode, setICDCode] = useState('')
  const [frequencyInputValue, setFrequencyInputValue] = useState('')
  const [selectedChiefConcern, setSelectedChiefConcern] = useState(null);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [addFieldValue, setAddFieldValue] = useState({
    healthConcerns: false,
    diagnoses: false,
    familyHealthHistory: false,
    pastMedicalHistory: {
      majorEvents: false,
      ongoingMedicalProblems: false,
      preventiveCare: false,
      socialHistory: false,
      nutritionHistory: false,
      developmentHistory: false,
    },
    implantableDevices: false,
    goals: false,
  })

  const toggleSecondaryField = (key, parentKey = null) => {
    if (parentKey) {
      setAddFieldValue((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [key]: !prevState[parentKey][key],
        },
      }));
    } else {
      setAddFieldValue((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }
  };


  const handleSendValue = (parentKey, key) => {
    const mainValue = form.getFieldValue(parentKey ? [parentKey, key] : key) || '';

    const secondaryValue = form.getFieldValue(`${key}Secondary`) || '';

    if (secondaryValue.trim()) {
      if (parentKey) {
        form.setFieldsValue({
          [parentKey]: {
            ...form.getFieldValue(parentKey),
            [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
          },
          [`${key}Secondary`]: '',
        });
      } else {
        form.setFieldsValue({
          [key]: mainValue ? `${mainValue}\n${secondaryValue}` : secondaryValue,
          [`${key}Secondary`]: '',
        });
      }
      if (parentKey) {
        handleFieldBlur(`${parentKey}.${key}`);
      } else {
        handleFieldBlur(key);
      }
      setAddFieldValue((prevState) => ({
        ...prevState,
        [parentKey ? parentKey : key]: false,
      }));
    }
  };



  const fetchEMRData = async () => {
    try {
      const response = await axios.get(`/emr/${selectedPatient._id}`)
      if (response.data) {
        form.setFieldsValue(response.data)
      }
      else {
        form.setFieldsValue({})
      }
    } catch (error) {
      console.error('Error fetching EMR data', error);
    }
  }

  const getAllSoapNotes = async () => {
    try {

      const response = await axios.get(`/get-soap-notes/${selectedPatient._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      })
      if (response.data) {
        setEncounters(response.data.soapNotes)
      }
    }
    catch (error) {
      console.log("Error fetching all notes", error)
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      getAllSoapNotes();
      fetchEMRData();
      fetchAllMedications()
      fetchAllICD10CMCodes()
    }
  }, [selectedPatient])

  const handleSelectChiefConcern = (value) => {
    const chiefConcern = "Selected Concern";
    setSelectedChiefConcern(value);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const handleSOAPNoteClick = () => {
    closeDropdown();
    addTab(currentDate, '', selectedChiefConcern);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);


  const handleBlur = async (values) => {
    try {
      const response = await axios.post('/emr', { patientId: selectedPatient._id, ...values });
      console.log('response of emr api', response.data)
      message.success(response.data.message);
    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error("Failed to save EMR data");
    }
  };

  const handleFieldBlur = (field) => {
    form.validateFields([field]).then(() => {
      const values = form.getFieldsValue();
      handleBlur(values);
    });
  };

  const addMedication = async (medication) => {
    try {
      const response = await axios.post('/emr/add-medication', { patientId: selectedPatient._id, medication });
      console.log('response of emr api', response.data)
      if (response.status === 200) {
        setMedications([...medications, medication])
        message.success(response.data.message);
        setMedicationInputValue('')
        setFrequencyInputValue('')
        addCustomMedication(false)
      }

    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error(error.response.data.message);
    }
  }

  const handleAddMedication = () => {
    if (medicationInputValue.trim() && frequencyInputValue.trim()) {
      const medication = { supplementName: medicationInputValue.trim(), frequency: frequencyInputValue.trim() }
      addMedication(medication)
    }
  }

  const fetchAllMedications = async () => {
    try {
      const response = await axios.get(`/emr/get-medications/${selectedPatient._id}`);
      console.log('response of emr api', response.data)
      setMedications(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const addICD10CMCode = async (icdCode, icdName) => {
    try {
      const response = await axios.post('/add-code', { name: icdName, code: icdCode }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      console.log('response of emr api', response.data)
      if (response.status === 200 || response.status === 201) {
        setICD10CMCodes([...icd10CMCodes, { name: icdName, code: icdCode }])
        message.success(response.data.message);
        setICDName('');
        setICDCode('');
        setAddCustomICDCode(false);
      }

    } catch (error) {
      console.error("Error saving EMR data:", error);
      message.error(error.response.data.message);
    }
  }
  const handleAddICD10CMCode = () => {
    if (icdName.trim() && icdCode.trim()) {
      addICD10CMCode(icdCode, icdName)
    }
  }


  const closeModal = () => setPrintModalVisible(false);

  return (
    <Form
      form={form}
      layout="vertical"

    >
      <Tooltip title="Print">
        <Button icon={<Print />} iconPosition='start' style={{ margin: '15px 0', fontSize: 16, cursor: 'pointer', marginRight: 5 }} className="cursor-pointer"
          onClick={() => setPrintModalVisible(true)}>
          Print Chart
        </Button>
      </Tooltip>
      <div className={`grid ${fromSoapNote ? 'grid-cols-1' : 'grid-cols-3'} ${fromSoapNote ? 'h-[700px]' : ''} overflow-auto gap-4 bg-transparent`}>
        <div className={`flex  ${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}   justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
          <div className='p-3 w-full  '>
            <Form.Item
              label={
                <Space className='font-bold'>
                  Health Concerns
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => toggleSecondaryField('healthConcerns')}
                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
            >
              <Form.Item
                name="healthConcerns"
                className='mb-0'
              >
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur('healthConcerns')}
                />
              </Form.Item>
              {addFieldValue['healthConcerns'] && (
                <SecondaryInput
                  fieldName="healthConcerns"
                  handleSendValue={handleSendValue}
                  placeholder="Add more details for Health Concerns"
                />
              )}
            </Form.Item>


            <Form.Item
              label={
                <Space className='font-bold'>
                  Diagnoses
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => toggleSecondaryField('diagnoses')}
                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
            >
              <Form.Item
                name="diagnoses"
                className='mb-0'
              >
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur('diagnoses')}
                />
              </Form.Item>
              {addFieldValue['diagnoses'] && (
                <SecondaryInput
                  fieldName="diagnoses"
                  handleSendValue={handleSendValue}
                  placeholder="Add more details for Diagnoses"
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <Space className='font-bold'>
                  Family Health History
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => toggleSecondaryField('familyHealthHistory')}
                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
            >
              <Form.Item
                name="familyHealthHistory"
                className='mb-0'
              >
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur('familyHealthHistory')}
                />
              </Form.Item>
              {addFieldValue['familyHealthHistory'] && (
                <SecondaryInput
                  fieldName="familyHealthHistory"
                  handleSendValue={handleSendValue}
                  placeholder="Add more details for family health history"
                />
              )}
            </Form.Item>

            <div style={{ marginBottom: '16px' }}>
              <div style={{ fontWeight: 'bold' }}>Past Medical History</div>
              <Form.Item
                name={['pastMedicalHistory', 'majorEvents']}
                label={
                  <Space>
                    Major Events
                    <Fab
                      sx={{
                        fontSize: 12,
                        height: 16,
                        width: 16,
                        minHeight: 'unset',
                        minWidth: 'unset',
                        padding: 1
                      }}
                      onClick={() => toggleSecondaryField('majorEvents', 'pastMedicalHistory')}
                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }                >
                <Form.Item
                  name={['pastMedicalHistory', 'majorEvents']}
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.majorEvents')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.majorEvents && (
                  <SecondaryInput
                    fieldName="majorEvents"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for Major Events"
                  />)}
              </Form.Item>
              <Form.Item
                name={['pastMedicalHistory', 'ongoingMedicalProblems']}
                label={
                  <Space >
                    Ongoing Medical Problems
                    <Fab
                      sx={{
                        fontSize: 12,
                        height: 16,
                        width: 16,
                        minHeight: 'unset',
                        minWidth: 'unset',
                        padding: 1
                      }}
                      onClick={() => toggleSecondaryField('ongoingMedicalProblems', 'pastMedicalHistory')}

                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }>
                <Form.Item
                  name={['pastMedicalHistory', 'ongoingMedicalProblems']}
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.ongoingMedicalProblems')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.ongoingMedicalProblems && (
                  <SecondaryInput
                    fieldName="ongoingMedicalProblems"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for ongoing medical problems"
                  />)}
              </Form.Item>
              <Form.Item
                name={['pastMedicalHistory', 'preventiveCare']}
                label={
                  <Space>
                    Preventive Care
                    <Fab
                      sx={{
                        fontSize: 12,
                        height: 16,
                        width: 16,
                        minHeight: 'unset',
                        minWidth: 'unset',
                        padding: 1
                      }}
                      onClick={() => toggleSecondaryField('preventiveCare', 'pastMedicalHistory')}

                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }>
                <Form.Item
                  name={['pastMedicalHistory', 'preventiveCare']}
                  className='mb-0'

                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.preventiveCare')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.preventiveCare && (
                  <SecondaryInput
                    fieldName="preventiveCare"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for preventive care"
                  />)}
              </Form.Item>
              <Form.Item
                name={['pastMedicalHistory', 'socialHistory']}
                label={
                  <Space>
                    Social History
                    <Fab
                      sx={{
                        fontSize: 12,
                        height: 16,
                        width: 16,
                        minHeight: 'unset',
                        minWidth: 'unset',
                        padding: 1
                      }}
                      onClick={() => toggleSecondaryField('socialHistory', 'pastMedicalHistory')}

                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }>
                <Form.Item
                  name={['pastMedicalHistory', 'socialHistory']}
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.socialHistory')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.socialHistory && (
                  <SecondaryInput
                    fieldName="socialHistory"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for social history"
                  />)}
              </Form.Item>
              <Form.Item
                name={['pastMedicalHistory', 'nutritionHistory']}
                label={
                  <Space>
                    Nutrition History
                    <Fab
                      sx={{
                        fontSize: 12,
                        height: 16,
                        width: 16,
                        minHeight: 'unset',
                        minWidth: 'unset',
                        padding: 1
                      }}
                      onClick={() => toggleSecondaryField('nutritionHistory', 'pastMedicalHistory')}

                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }>
                <Form.Item
                  name={['pastMedicalHistory', 'nutritionHistory']}
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.nutritionHistory')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.nutritionHistory && (
                  <SecondaryInput
                    fieldName="nutritionHistory"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for nutrition history"
                  />)}
              </Form.Item>
              <Form.Item
                name={['pastMedicalHistory', 'developmentHistory']}
                label={
                  <Space>
                    Developmental History
                    <Fab
                      sx={{
                        fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                      }}
                      onClick={() => toggleSecondaryField('developmentHistory', 'pastMedicalHistory')}

                    >
                      <PlusOutlined />
                    </Fab>
                  </Space>
                }>
                <Form.Item
                  name={['pastMedicalHistory', 'developmentHistory']}
                  className='mb-0'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1 }}
                    onBlur={() => handleFieldBlur('pastMedicalHistory.developmentHistory')}
                  />
                </Form.Item>
                {addFieldValue['pastMedicalHistory']?.developmentHistory && (
                  <SecondaryInput
                    fieldName="developmentHistory"
                    parentKey="pastMedicalHistory"
                    handleSendValue={handleSendValue}
                    placeholder="Add more details for development history"
                  />)}
              </Form.Item>
            </div>

            {!fromSoapNote && <Form.Item
              name="chiefConcern"
              label={
                <Space>
                  Chief Concern
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => setAddCustomICDCode(!addCustomICDCode)}
                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
              className='font-bold'
            >
              {addCustomICDCode &&
                <div className='flex gap-2 fadeIn 1s ease-in-out duration-300 my-4'>

                  <Input.TextArea
                    style={{ width: '45%', flexGrow: 1 }}
                    autoSize={{ minRows: 1 }}
                    type='text' placeholder='Enter Code' value={icdCode} onChange={event => setICDCode(event.target.value)} />
                  <Input.TextArea
                    style={{ width: '45%' }}
                    autoSize={{ minRows: 1 }}
                    type='text' placeholder='Enter Name' value={icdName} onChange={event => setICDName(event.target.value)} />
                  <Fab sx={{
                    fontSize: 12,
                    height: 28,
                    width: 28,
                    minHeight: 'unset',
                    minWidth: 'unset',
                    padding: 0,

                  }}
                    onClick={handleAddICD10CMCode}
                  >
                    <SendOutlined />
                  </Fab>
                </div>
              }
              <Select
                placeholder="Select ICD-10 Code"
                showSearch
                optionFilterProp="children"
                onChange={handleSelectChiefConcern}
                filterOption={(input, option) =>
                  (option?.children || '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {icd10CMCodes.map(({ code, name }) => (
                  <Select.Option key={code} value={code}>
                    {`${code} - ${name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>}
          </div>
        </div>
        <div className={`${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
          <div className='p-3 w-full'>
            <div style={{ marginBottom: '16px' }}>
              <div className='font-bold'>Allergies</div>

              <Form.Item label="Drug" name={['allergies', 'drug']} className='mb-0'>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur(`allergies[drug]`)}
                />
              </Form.Item>
              <Form.Item label="Food" name={['allergies', 'food']} className='mb-0'>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur(`allergies[food]`)}
                />
              </Form.Item>

              <Form.Item label="Environmental" name={['allergies', 'environmental']} className='mb-0'>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur(`allergies[environmental]`)}
                />
              </Form.Item>
              <Form.Item label="Medications" name={['allergies', 'medications']} className='mb-0'>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur(`allergies[medications]`)}
                />
              </Form.Item>
            </div>
            <div className='flex flex-col'>
              <div className='flex justify-between'>
                <p className='font-bold'>Medications</p>
                <Fab sx={{
                   fontSize: 12,
                   height: 16,
                   width: 16,
                   minHeight: 'unset',
                   minWidth: 'unset',
                   padding: 1
                }}
                  onClick={() => setAddCustomMedication(!addCustomMedication)}
                >
                  <PlusOutlined />
                </Fab>
              </div>
              {addCustomMedication &&
                <div className='flex gap-2 fadeIn 1s ease-in-out duration-300 my-4'>
                  <Input.TextArea
                    style={{ width: '45%' }}
                    autoSize={{ minRows: 1 }}
                    type='text' placeholder='Enter Name' value={medicationInputValue} onChange={event => setMedicationInputValue(event.target.value)} />
                  <Input.TextArea
                    style={{ width: '45%', flexGrow: 1 }}
                    autoSize={{ minRows: 1 }}
                    type='text' placeholder='Enter Frequency' value={frequencyInputValue} onChange={event => setFrequencyInputValue(event.target.value)} />
                  <Fab sx={{
                    fontSize: 12,
                    height: 28,
                    width: 28,
                    minHeight: 'unset',
                    minWidth: 'unset',
                    padding: 0,

                  }}
                    onClick={handleAddMedication}
                  >
                    <SendOutlined />
                  </Fab>
                </div>
              }
              <ul className="list-disc ml-5">
                {medications.length > 0 ? medications?.map((medication) => (
                  <li key={medication.id} >{medication.supplementName} {`(${medication.frequency})`}</li>
                )) : <p className='text-center my-6'>No Medications</p>
                }
              </ul>
            </div>


            <Form.Item
              label={
                <Space className='font-bold'>
                  Implantable Devices
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => toggleSecondaryField('implantableDevices')}

                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
            >
              <Form.Item
                name="implantableDevices"
                className='mb-0'
              >
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur('implantableDevices')}
                />
              </Form.Item>
              {addFieldValue['implantableDevices'] && (
                <SecondaryInput
                  fieldName="implantableDevices"
                  handleSendValue={handleSendValue}
                  placeholder="Add more details for implantable devices"
                />
              )}
            </Form.Item>
          </div>
        </div>
        <div className={`${fromSoapNote ? 'h-full' : 'h-[600px] overflow-auto'}  flex  justify-center shadow-sm rounded-md border-gray-200 border-[1px]`}>
          <div className='p-3 w-full'>

            <Form.Item
              label={
                <Space>
                  Goals
                  <Fab
                    sx={{
                      fontSize: 12,
                      height: 16,
                      width: 16,
                      minHeight: 'unset',
                      minWidth: 'unset',
                      padding: 1
                    }}
                    onClick={() => toggleSecondaryField('goals')}
                  >
                    <PlusOutlined />
                  </Fab>
                </Space>
              }
            >
              <Form.Item
                name="goals"
                className='mb-0'
              >
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  onBlur={() => handleFieldBlur('goals')}
                />
              </Form.Item>
              {addFieldValue['goals'] && (
                <SecondaryInput
                  fieldName="goals"
                  handleSendValue={handleSendValue}
                  placeholder="Add more details for goals"
                />
              )}
            </Form.Item>
            {!fromSoapNote && <Form.Item label="Encounters">
              {
                encounters.length > 0 ?
                  <div className='max-h-64 overflow-auto pr-2 flex flex-col gap-2'>
                    {
                      encounters.map((note) => {
                        return (
                          <div className='shadow-sm border-[1px] border-gray-200 px-4 py-3 rounded-xl cursor-pointer hover:bg-gray-100 bg-opacity-50 transition-all duration-300 ' onClick={() => addTab(note.createdAt.split('T')[0], note)}  >
                            <p className="font-medium">{moment(note.createdAt.split('T')[0]).format('MM-DD-YYYY')}</p>
                          </div>
                        )
                      })

                    }

                  </div> :
                  <div className='h-auto overflow-auto pr-2 flex flex-col gap-2'>
                    <p className='my-0 mx-auto text-sm'>No Encounters</p>
                  </div>
              }
              <Select
                style={{
                  width: 300,
                  marginTop: 20,
                  cursor: 'pointer'
                }}

                open={dropdownOpen}
                onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                placeholder="New Encounter"
                dropdownRender={() => (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px' }}>
                    <Button
                      className={styles.button}
                      onClick={() => {
                        handleSOAPNoteClick();
                        setDropdownOpen(false);
                      }}
                    >
                      SOAP Note
                    </Button>
                    <Button
                      className={styles.button}
                      onClick={() => setDropdownOpen(false)}
                    >
                      Phone/SMS
                    </Button>
                  </div>
                )}
              />

            </Form.Item>}
          </div>
        </div>
      </div>
      <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={selectedPatient} />
    </Form>
  );
}

const SecondaryInput = ({ fieldName, parentKey, handleSendValue, placeholder }) => {
  return (
    <div className="flex w-full gap-2">
      <Form.Item name={`${fieldName}Secondary`} style={{ flexGrow: 10 }}>
        <Input
          name={`${fieldName}Secondary`}
          className='mb-0 mt-1'
          placeholder={placeholder}
        />
      </Form.Item>

      <Fab
        sx={{
          fontSize: 12,
          height: 28,
          width: 28,
          minHeight: 'unset',
          minWidth: 'unset',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleSendValue(parentKey, fieldName)}
      >
        <SendOutlined />
      </Fab>
    </div>

  );
};


export default SummarySection;
