import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const TreatmentTable = ({ rowData, columnDefs, onCellValueChanged, columnsWidth }) => {
  const gridRef = useRef(null);

  const containerStyle = useMemo(() => ({ width: "100%", height: "800px" }), []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  );

  const gridOptions = useMemo(() => ({
    pagination: true,
    paginationPageSize: 20,
    domLayout: "normal",
    headerHeight: Object.values(columnsWidth).some(value => value === 'small') ? 150 : null,
    rowHeight: null,
    getRowHeight: (params) => {
      if (params.node.group) {
        return 80;
      }
      return 40;
    },
    getHeaderHeight: (column) => {
      if (column.getColDef().children) {
        return 160; 
      }
      return 40; 
    },
  }), []);

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api;
    params.api.refreshCells();
    params.api.refreshHeader();
  }, []);

  const getRowStyle = useCallback((params) => {
    if (params.data.type === 'Order') {
      return { backgroundColor: '#ffd57f' };
    }
    return null;
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    params.api.sizeColumnsToFit();
    params.api.refreshCells();
    params.api.refreshHeader();
  }, []);

  return (
    <div className="ag-theme-quartz" style={containerStyle}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        getRowStyle={getRowStyle}
        onFirstDataRendered={onFirstDataRendered}
        {...gridOptions}
      />
    </div>
  );
};

export default TreatmentTable;
