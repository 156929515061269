import { Print } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Badge, Checkbox, Modal, Tooltip } from 'antd';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import smsIcon from '../../../../Assets/sms.png';
import Messages from '../../../../Components/Messages';
import PrintModal from '../PrintModal';

const SOAPNotesSection = ({ setShowSOAPNotesSection, scrollToDiagnosisDiv, chiefConcern, patientProfile, initialData = {}, notesData={} }) => {
  const [subjective, setSubjective] = useState(notesData?.subjective || '');
  const [objective, setObjective] = useState(notesData?.objective || '');
  const [assessment, setAssessment] = useState(notesData?.assessment || '');
  const [plan, setPlan] = useState(notesData?.plan || '');
  const [storedChiefConcern, setStoredChiefConcern] = useState(notesData?.chiefConcern || '');
  const [noteId, setNoteId] = useState(initialData?.noteId || null);
  const [date, setDate] = useState(initialData?.date || new Date());
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [icd10CMCodes, setICD10CMCodes] = useState([])


  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const chiefConcernData = icd10CMCodes.find(item => item.code === chiefConcern) || "";

  useEffect(() => {
    setPatientId(patientProfile._id)
    fetchAllICD10CMCodes()
  }, [patientProfile])

  const handleBlur = async () => {
    try {
      const requestBody = {
        patientId,
        chiefConcern:chiefConcernData,
        subjective,
        objective,
        assessment,
        plan,
        date,
        noteId,
      };

      const response = await axios.post('/soap-note', requestBody);
      console.log('SOAP Note response:', response.data);

      if (!noteId && response.data.soapNote._id) {
        setNoteId(response.data.soapNote._id);
      }
    } catch (error) {
      console.error('Error saving SOAP note:', error);
    }
  };


  const MessageIcon = ({ patient }) => {
    const unreadCount = patient?.messages?.filter(
      (msg) => msg.readStatus === false
    ).length;

    console.log(unreadCount);
    return (
      <Tooltip
        title={<Messages patient={patient} />}
        trigger="click"
        placement="right"
        overlayInnerStyle={{
          backgroundColor: "#ffffff",
          boxShadow:
            "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",
          padding: "12px",
        }}
        color="#ffffff"
      >
        <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
          <img
            src={smsIcon}
            alt="sms"
            style={{ width: "36px", height: "36px", background: "none" }}
          />
        </Badge>
      </Tooltip>
    );
  };

  const closeModal = () => setPrintModalVisible(false);

const soapNotes={
  subjective,
  objective,
  assessment,
  plan
}

  return (
    <div className=" w-full h-[80%]">
      <div className='flex flex-col'>
        <div className='flex w-full justify-between'>
          <h1 className="text-2xl font-bold">SOAP Note</h1>
          <MessageIcon patient={patientProfile} />
        </div>
        <div className='flex w-full items-center justify-between'>
          {chiefConcern ? <h1 className="text-lg font-semibold my-3">
            Chief Concern: {chiefConcernData.code} {'- ' + chiefConcernData.code}
          </h1>:<h1 className="text-lg font-semibold my-3">Chief Concern:</h1>}
          {storedChiefConcern && <h1 className="text-lg font-semibold my-3">
            Chief Concern: {storedChiefConcern.code} {'- ' + storedChiefConcern.name}
          </h1>}

          <Tooltip title="Print">
            <Print
              className="cursor-pointer"
              onClick={() => setPrintModalVisible(true)}
              style={{ fontSize: 28, cursor: 'pointer', marginRight: 5 }}
            />
          </Tooltip>
        </div>

        <div className='grid grid-cols-2 gap-4 '>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Subjective</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Subjective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={subjective}
              onChange={setSubjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Objective</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Objective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={objective}
              onChange={setObjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Assessment</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Assessment Note</h2>
              <div className='flex gap-2'>

                <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">View Template</p>
                <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">Import Past Encounter</p>
                <p className="font-medium whitespace-nowrap  mb-2 text-blue-400 cursor-pointer" onClick={() => scrollToDiagnosisDiv()}>View Diagnosis</p>
                <p className="font-medium whitespace-nowrap  mb-2 text-blue-400 cursor-pointer">View S/I/As</p>
              </div>
            </div>
            <ReactQuill
              theme="snow"
              value={assessment}
              onChange={setAssessment}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Plan</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Plan Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>

            </div>
            <ReactQuill
              theme="snow"
              value={plan}
              onChange={setPlan}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
        </div>
      </div>

      <div>
        <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={patientProfile} soapNotes={soapNotes} formSoapNote={true}/>
      </div>
    </div>
  );
};

export default SOAPNotesSection;
