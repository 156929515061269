import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Table,
} from "@mui/material";
import { Button, Typography } from "antd";

import axios from "axios";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const VitalsReadOnlyView = ({ patientId }) => {
  const [openVitalsModal, setOpenVitalsModal] = useState(false);
  const [vitalData, setVitalData] = useState(null);

  const handleVitalsModalOpen = () => setOpenVitalsModal(true);
  const handleVitalsModalClose = () => setOpenVitalsModal(false);

  const fetchVitalData = async (patientId) => {
    try {
      const response = await axios.get(
        `/patient/vitals/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      function sortByDateDescending(apiResponse) {
        return apiResponse.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      setVitalData(sortByDateDescending(response.data));
      handleVitalsModalOpen();
    } catch (error) {
      console.error("Error fetching vital data", error);
    }
  };
  return (
    <div>
      <Button
        onClick={() => fetchVitalData(patientId)}
        className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
      >
        All Vitals
      </Button>
      {vitalData && (
        <Modal open={openVitalsModal} onClose={handleVitalsModalClose}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Latest Vital Information
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="vital data table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell align="right">Time</TableCell>
                    <TableCell align="right">Blood Pressure</TableCell>
                    <TableCell align="right">Heart Rate</TableCell>
                    <TableCell align="right">SPO2</TableCell>
                    <TableCell align="right">Temperature</TableCell>
                    <TableCell align="right">Weight</TableCell>
                    {/* Add other vital headers here */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vitalData.map((order) =>
                    order.vitals && order.vitals.length > 0 ? (
                      order.vitals.map((vital, index) => (
                        <TableRow key={`${order._id}-${index}`}>
                          <TableCell component="th" scope="row">
                            {index === 0
                              ? new Date(order.date).toLocaleDateString()
                              : ""}
                          </TableCell>
                          <TableCell align="right">
                            {new Date(vital.time).toLocaleTimeString()}
                          </TableCell>
                          <TableCell align="right">{vital.BP}</TableCell>
                          <TableCell align="right">{vital.HR}</TableCell>
                          <TableCell align="right">{vital.SPO2}</TableCell>
                          <TableCell align="right">{vital.T}</TableCell>
                          <TableCell align="right">{vital.W}</TableCell>
                          {/* Add other vital cells here */}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={order._id}>
                        <TableCell component="th" scope="row" colSpan={7}>
                          No vital data available for this order.
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default VitalsReadOnlyView;
